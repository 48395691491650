import {
  CreditDetail,
  RedeemedEarnOffersResponse,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";
import * as actionTypes from "./constants";

export const fetchTravelWalletDetails = (): IFetchTravelOffersDetails => ({
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS,
});

export interface IFetchTravelOffersDetails {
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS;
}

export const fetchTravelWalletDetailsCallStateFailed =
  (): IFetchTravelWalletDetailsCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED,
  });

export interface IFetchTravelWalletDetailsCallStateFailed {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;
}

export const setTravelWalletOffers = (
  offers: TravelWalletOffer[]
): ISetTravelWalletOffers => ({
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS,
  payload: offers,
});

export interface ISetTravelWalletOffers {
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS;
  payload: TravelWalletOffer[];
}

export const setTravelWalletCredit = (
  creditBreakdown: CreditDetail[],
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  credit,
  creditBreakdown,
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
}

export interface ISetRedeemedEarnOffers {
  type: actionTypes.SET_REDEEMED_EARN_OFFERS;
  redeemedEarnOffers: RedeemedEarnOffersResponse["redeemedEarnOffers"];
}

export interface IFetchRedeemedEarnOffers {
  type: actionTypes.FETCH_REDEEMED_EARN_OFFERS;
}

export const fetchRedeemedEarnOffers = (): IFetchRedeemedEarnOffers => ({
  type: actionTypes.FETCH_REDEEMED_EARN_OFFERS,
});

export interface ISetFetchRedeemedEarnOffersStateFailed {
  type: actionTypes.SET_FETCH_REDEEMED_EARN_OFFERS_STATE_FAILED;
}

export const setFetchRedeemedEarnOffersStateFailed =
  (): ISetFetchRedeemedEarnOffersStateFailed => ({
    type: actionTypes.SET_FETCH_REDEEMED_EARN_OFFERS_STATE_FAILED,
  });

export const setRedeemedEarnOffers = (
  redeemedEarnOffers: RedeemedEarnOffersResponse["redeemedEarnOffers"]
): ISetRedeemedEarnOffers => ({
  type: actionTypes.SET_REDEEMED_EARN_OFFERS,
  redeemedEarnOffers,
});

export type MyTripsTravelWalletActions =
  | IFetchTravelOffersDetails
  | IFetchTravelWalletDetailsCallStateFailed
  | ISetTravelWalletOffers
  | ISetTravelWalletCredit
  | ISetRedeemedEarnOffers
  | ISetFetchRedeemedEarnOffersStateFailed
  | IFetchRedeemedEarnOffers;
