import { getPortalName } from "@capone/common";
import { Box, Divider, Typography, useMediaQuery } from "@material-ui/core";
import {
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import React, { useCallback, useMemo } from "react";
import { FLIGHT_CFAR_TERMS_BODY, GENERAL_SUPPORT_PHONE_NUMBER } from "redmond";
import { PATH_TERMS_CFAR } from "../../../../../../../../utils/paths";
import * as constants from "../CfarHotelModalContent/constants";
import "./styles.scss";

export const CfarHotelErrorModalContent = () => {
  const { matchesMobile } = useDeviceTypes();
  const usePartialScroll = useMediaQuery(
    constants.getPartialScrollThreshold(),
    {
      noSsr: true,
    }
  );
  const isPartialScrollActive = useMemo(
    () => !matchesMobile && usePartialScroll,
    [matchesMobile, usePartialScroll]
  );

  const getReadTermsLink = (
    className?: string
  ): GenericDetailsCardComponent => ({
    className,
    message: constants.READ_TERMS_AND_CONDITIONS,
    onClick: () => window.open(PATH_TERMS_CFAR, "_blank")?.focus(),
    position: "left",
    component: GenericDetailsCardComponentEnum.ClickableLink,
  });

  const getTermsAndConditions = useCallback(
    (className?: string): GenericDetailsCardComponent =>
      (matchesMobile
        ? {
            className,
            content: [
              {
                title: constants.READ_TERMS_AND_CONDITIONS,
                body: FLIGHT_CFAR_TERMS_BODY(getPortalName()),
              },
            ],
            component: GenericDetailsCardComponentEnum.AccordionGroup,
          }
        : getReadTermsLink(className)) as GenericDetailsCardComponent,
    [matchesMobile]
  );

  const cancellationInfo = useMemo(
    () =>
      constants
        .getCfarConfirmInformation()
        .filter(
          (info) => info.indexOf(GENERAL_SUPPORT_PHONE_NUMBER.text) === -1
        ),
    []
  );

  return (
    <Box className="cfar-hotel-error-modal-content-root">
      <Box className="cfar-hotel-error-modal-content-header">
        <Icon name={IconName.FoundationHelp} />
        <Typography variant="h2">Please contact us for help.</Typography>
        <Typography variant="h4" className="cfar-hotel-error-modal-content-subheader">
          Please contact support at{" "}
          <span style={{ fontWeight: "bold" }}>
            {GENERAL_SUPPORT_PHONE_NUMBER.text}
          </span>{" "}
          for further assistance in canceling your booking.
        </Typography>
        <Divider orientation="horizontal" className="cfar-hotel-error-modal-content-header-divider" />
      </Box>
      <GenericDetailsCard
        contentClassName="cfar-hotel-error-modal-content-content"
        mainContent={[
          {
            className: "cfar-hotel-error-cancellation-information-header",
            type: "secondary",
            icon: IconName.CheckShieldBlue,
            copy: "Cancel for any reason is applied to this booking",
            component: GenericDetailsCardComponentEnum.GenericCopy,
          },
          {
            className: "cfar-hotel-error-cancellation-information",
            title: "Cancellation rules:",
            items: cancellationInfo,
            component: GenericDetailsCardComponentEnum.PointsGroup,
          },
          getTermsAndConditions("content"),
        ]}
        openModal
        isMobile={matchesMobile}
        contentOnly
        scrollOption={
          isPartialScrollActive ? "partial-scroll" : "default-scroll"
        }
      />
    </Box>
  );
};
