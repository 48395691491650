import { RedeemedEarnOffersResponse } from "redmond";
import axios from "axios";
import { travelWalletApiPrefix } from "../paths";
import { config } from "../../config";

const ENDPOINT: string = `${travelWalletApiPrefix}/offers/earn/previouslyRedeemed`;

export const fetchRedeemedEarnOffers =
  async (): Promise<RedeemedEarnOffersResponse> => {
    const res = await axios.get(ENDPOINT, {
      baseURL: config.baseURL,
    });

    return res.data;
  };
