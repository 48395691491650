// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eOX3e9QGDECnMh-3t29xzg\\=\\={padding:40px 20px 20px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/TripsList/components/ItineraryList/components/ItinerariesModal/components/ExperienceCancellationPolicyModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,sBAAA","sourcesContent":[".experience-cancellation-modal-content-container {\n  padding: 40px 20px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experience-cancellation-modal-content-container": "eOX3e9QGDECnMh-3t29xzg=="
};
export default ___CSS_LOADER_EXPORT___;
