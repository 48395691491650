import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  B2BSpinner,
  B2BTextField,
  Icon,
  IconName,
  emailRegex,
} from "halifax";
import { useDispatch } from "react-redux";
import {
  CONFIRM_RESEND_EMAIL,
  VIEWED_RESEND_EMAIL,
  BookingType,
  CallState,
  Car,
  EmailType,
  EmailTypeTag,
  Flight,
  Hotel,
  ItineraryEnum,
  ItineraryWithType,
  ResendEmailProperties,
  Home,
  ListingCollectionEnum,
  RESEND_EMAIL_SUBMIT,
  ResendEmailSubmitProperties,
} from "redmond";

import resendConfirmationEmail from "../../../../../../../../api/v1/itinerary/resendConfirmationEmail";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { setOpenModal } from "../../../../../../actions/actions";
import * as constants from "./constants";
import "./styles.scss";

interface IResendConfirmationModalContentProps {
  itinerary: ItineraryWithType;
}

const ResendConfirmationModalContent = (
  props: IResendConfirmationModalContentProps
) => {
  const { itinerary } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [reqState, setReqState] = useState(CallState.NotCalled);

  /**
   * @description Updates the redux store to close this modal
   */
  const closeModal = () => {
    const closedModal = { type: null, selectedItinerary: null };

    dispatch(setOpenModal(closedModal));
  };

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_RESEND_EMAIL,
      properties: {
        screen: itinerary.type,
      } as ResendEmailProperties,
    });
  }, []);

  const getEmailType = () => {
    const { type } = itinerary;

    switch (type) {
      case ItineraryEnum.Flight:
        const flightRes = itinerary as Flight;
        return {
          bookingType: BookingType.Air.toLowerCase(),
          emailType: {
            locator:
              flightRes.bookedItinerary.travelItinerary.locators?.agent
                .unscopedValue,
            itineraryId: flightRes.bookedItinerary.id,
            EmailType: EmailTypeTag.FlightConfirmation,
          } as EmailType,
        };
      case ItineraryEnum.Hotel:
        const hotelRes = itinerary as Hotel;
        const {
          additionalInfo,
          isLuxuryCollection: isLuxury,
          reservationBookingId,
          lodgingCollection: collectionName,
        } = hotelRes.reservation;

        let emailType = EmailTypeTag.HotelConfirmation;
        let bookingTypeStr = BookingType.Hotels.toLowerCase();

        if (isLuxury) {
          emailType = EmailTypeTag.LuxuryHotelConfirmation;
          bookingTypeStr = "hotelsLuxury";
        } else if (collectionName.includes("Lifestyle")) {
          emailType = EmailTypeTag.LifestyleHotelConfirmation;
          bookingTypeStr = "hotelsLifestyle";
        }

        return {
          bookingType: bookingTypeStr,
          emailType: {
            sessionId: additionalInfo?.sessionId,
            reservationId: reservationBookingId,
            EmailType: emailType,
          } as EmailType,
        };
      case ItineraryEnum.Car:
        const carRes = itinerary as Car;
        return {
          bookingType: BookingType.Ground.toLowerCase(),
          emailType: {
            sessionId: carRes.bookResult.additionalInfo?.sessionId,
            groundBookingId: carRes.bookResult.groundBookingId,
            EmailType: EmailTypeTag.GroundConfirmation,
          } as EmailType,
        };
      case ItineraryEnum.Watch:
        return {
          bookingType: null,
          emailType: null,
        };
      case ItineraryEnum.Home:
        const homeRes = itinerary as Home;
        const isLifestyle =
          homeRes.reservation.listingCollection ===
          ListingCollectionEnum.Lifestyle;

        return {
          bookingType: `${BookingType.Home.toLowerCase()}${
            isLifestyle ? "Lifestyle" : "Premier" // bookingType is either homesLifestyle or homesPremier (used for the resend endpoint)
          }`,
          emailType: {
            homesBookingId: homeRes.reservation.cartOrderedProductId,
            EmailType: isLifestyle
              ? EmailTypeTag.HomesLifestyleBookingConfirmation
              : EmailTypeTag.HomesPremierBookingConfirmation,
          } as EmailType,
        };
      // TODO implement this part, awaiting endpoint;
      case ItineraryEnum.Experience:
        return {};
      default:
        return {};
    }
  };

  /**
   * @description Forward request to BE to handle sending the email
   */
  const resendConfirmation = () => {
    const { emailType, bookingType } = getEmailType();

    if (emailType && bookingType) {
      setReqState(CallState.InProcess);
      trackEvent({
        eventName: CONFIRM_RESEND_EMAIL,
        properties: {
          screen: itinerary.type,
        } as ResendEmailProperties,
      });

      resendConfirmationEmail(bookingType, {
        sendTo: email,
        emailType,
      })
        .then(() => {
          setReqState(CallState.Success);
          trackEvent({
            eventName: RESEND_EMAIL_SUBMIT,
            properties: {
              screen: itinerary.type,
              success: true,
            } as ResendEmailSubmitProperties,
          });
        })
        .catch(() => {
          setReqState(CallState.Failed);
          trackEvent({
            eventName: RESEND_EMAIL_SUBMIT,
            properties: {
              screen: itinerary.type,
              success: false,
            } as ResendEmailSubmitProperties,
          });
        });
    }
  };

  /**
   * @description Gets the popup actions based on the state of the resend request
   * @return {JSX.Element}
   */
  const getResendActions = () => {
    const CANCEL_BTN = (
      <ActionButton
        defaultStyle="h4r-secondary"
        message={constants.CANCEL}
        onClick={closeModal}
      />
    );

    switch (reqState) {
      case CallState.Failed:
        return (
          <>
            <ActionButton
              className="try-again-btn"
              defaultStyle="h4r-primary"
              message={constants.TRY_AGAIN}
              onClick={() => setReqState(CallState.NotCalled)}
            />
            {CANCEL_BTN}
          </>
        );
      case CallState.InProcess:
        return <B2BSpinner className="loading-spinner" />;
      case CallState.NotCalled:
        return (
          <>
            <ActionButton
              className="resend-confirmation-btn"
              defaultStyle="h4r-primary"
              disabled={!emailRegex.test(email)}
              message={constants.RESEND}
              onClick={resendConfirmation}
            />
            {CANCEL_BTN}
          </>
        );
      case CallState.Success:
        return (
          <ActionButton
            className="done-btn"
            defaultStyle="h4r-primary"
            message={constants.DONE}
            onClick={closeModal}
          />
        );
      default:
        return null;
    }
  };

  /**
   * @description Gets the secondary text in the popup
   * @return {String}
   */
  const getSubtitleText = () => {
    switch (reqState) {
      case CallState.Failed:
        return constants.FAILED;
      case CallState.Success:
        return constants.SUCCEEDED;
      default:
        return constants.INSTRUCTIONS;
    }
  };

  return (
    <Box className="resend-confirmation-modal-content">
      <Box className="icon-container">
        {reqState === CallState.Failed && (
          <Icon className="error-icon" name={IconName.ErrorState} />
        )}
        {reqState === CallState.Success && (
          <Icon className="success-icon" name={IconName.Checked} />
        )}
      </Box>
      <Typography className="title" variant="h2">
        {constants.TITLE}
      </Typography>
      <Typography className="subtitle">{getSubtitleText()}</Typography>
      {(reqState === CallState.InProcess ||
        reqState === CallState.NotCalled) && (
        <Box className="resend-confirmation-form">
          <B2BTextField
            className="email-input"
            label={constants.EMAIL_LABEL}
            onChange={setEmail}
            type="email"
            value={email}
          />
        </Box>
      )}
      <Box className="actions-container">{getResendActions()}</Box>
    </Box>
  );
};

export default ResendConfirmationModalContent;
