import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  FiatPrice,
  ExperienceReservation,
  PriceBreakdownLineItem,
} from "redmond";
import {
  getPriceString,
  getTotalPriceText,
  Icon,
  IconName,
  quickPluralize,
  twoDecimalFormatter,
} from "halifax";
import { formatPaymentBreakdown, ILineItem } from "../../../FlightCard/helpers";
import "./styles.scss";
import { getCurrencyString } from "../../../../../../../../utils/helpers";
import {
  CHECKOUT_BREAKDOWN,
  EXPERIENCE_TOTAL,
  TAXES_AND_FEES,
} from "./constants";

interface IExperiencePaymentModalContentProps {
  experienceReservation: ExperienceReservation;
}

export const ExperiencePaymentModalContent = ({
  experienceReservation,
}: IExperiencePaymentModalContentProps) => {
  const { reservation, paymentBreakdown } = experienceReservation;

  const lineItems = formatPaymentBreakdown(paymentBreakdown);

  const renderTravelerPriceBreakdown = () => {
    if (!reservation?.bookingSession) return null;

    const { priceBreakdown, bookingFee } = reservation.bookingSession;

    const priceItems = priceBreakdown.map(
      ({ specificAgeBand, totalPriceForAgeBand }: PriceBreakdownLineItem) => ({
        label: `${getPriceString({
          price: specificAgeBand.pricePerTraveler.fiat.value,
          currencySymbol: specificAgeBand.pricePerTraveler.fiat.currencySymbol,
        })} x ${specificAgeBand.numberOfTravelers} ${quickPluralize(
          specificAgeBand.numberOfTravelers,
          specificAgeBand.ageBand.toLowerCase()
        )}`,
        amount: totalPriceForAgeBand.fiat,
      })
    );

    priceItems.push({
      label: TAXES_AND_FEES,
      amount: bookingFee?.fiat as FiatPrice,
    });

    return (
      <Box className="experience-payment-container">
        <Box className="experience-payment-details-container">
          {priceItems.map((priceItem) => (
            <Box className="experience-pricing-row">
              <Typography variant="body2">{priceItem.label}</Typography>
              <Typography variant="body2">
                {getTotalPriceText({
                  price: priceItem.amount,
                  priceFormatter: twoDecimalFormatter,
                })}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const renderTripTotal = () => {
    if (!reservation?.bookingSession?.totalSellAtPriceWithFees) return null;

    const { totalSellAtPriceWithFees } = reservation.bookingSession;

    return (
      <Box className="trip-total-row">
        <Typography variant="body1" className="trip-total-text">
          {EXPERIENCE_TOTAL}
        </Typography>
        <Typography variant="body1" className="trip-total-text">
          {getCurrencyString({
            currencyCode: totalSellAtPriceWithFees.fiat.currencyCode,
            currencySymbol: totalSellAtPriceWithFees.fiat.currencySymbol,
            value: totalSellAtPriceWithFees.fiat.value,
          })}
        </Typography>
      </Box>
    );
  };

  const renderRewards = () => {
    const { rewards } = lineItems;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="rewards-container">
        <Typography variant="body2">{rewards.label}</Typography>
        <Typography variant="body1" className="rewards-points">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const { userCard } = lineItems;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography variant="body2" className="payment-card-details">
            {userCard.label}
          </Typography>
        </Box>
        <Typography variant="body1" className="payment-card-amount">
          {userCard.amount}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (
    travelWallet: ILineItem | null,
    icon: IconName
  ) => {
    if (!travelWallet) return null;
    return (
      <Box className="offers-container">
        <Box className="offers-icon-label">
          <Icon name={icon} />
          <Typography variant="body2">{travelWallet.label}</Typography>
        </Box>
        <Typography variant="body1" className="offer-amount">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    renderTravelWallet(lineItems.travelWalletOffer, IconName.OfferTag);

  const renderCredits = () =>
    lineItems.travelWalletCredit?.map((credit) =>
      renderTravelWallet(credit, IconName.PiggyBank)
    );

  return (
    <Box className="experience-payment-modal-content">
      <Box className="experience-payment-title-container">
        <Typography variant="body1">{CHECKOUT_BREAKDOWN}</Typography>
      </Box>
      {renderTravelerPriceBreakdown()}
      {renderTripTotal()}
      {renderOffers()}
      {renderCredits()}
      {renderRewards()}
      {renderPayment()}
    </Box>
  );
};
