import dayjs from "dayjs";
import type { ChfarContract } from "@b2bportal/air-booking-api";
import type { CfarContract } from "redmond";

export const isFlexibilityPolicyInactive = (
  contract: CfarContract | ChfarContract | undefined
) => {
  if (!contract) return true;

  if ("expiration" in contract) {
    return dayjs().isAfter(dayjs(contract.expiration));
  }
  return dayjs().isAfter(dayjs(contract.expired));
};
