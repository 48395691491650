import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  airShoppingApi,
  type LocationResponse,
  LocationQueryEnum,
} from "@b2bportal/air-shopping-api";

export const fetchLocationAutocomplete = createAsyncThunk<
  LocationResponse,
  string
>("locationAutocomplete/fetchLocationAutocomplete", async (query: string) => {
  const body = {
    LocationQuery: LocationQueryEnum.Label,
    l: query,
  };

  const res = await airShoppingApi(axios).apiV0AutocompleteLocationPost(body);
  return res.data;
});
