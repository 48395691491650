import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ExperienceReservation } from "redmond";
import { ExperienceItineraryDetails } from "halifax";

import styles from "./styles.module.scss";

export interface IExperienceTravelersModalContentProps {
  experienceReservation: ExperienceReservation;
}

export const ExperienceItineraryModalContent = ({
  experienceReservation,
}: IExperienceTravelersModalContentProps): JSX.Element | null => {
  const { experienceDetails } = experienceReservation.reservation;

  if (!experienceDetails) return null;

  return (
    <Box className={styles["modal-content-container"]}>
      <Typography variant="h4">What to expect</Typography>
      <ExperienceItineraryDetails itinerary={experienceDetails.itinerary} />
    </Box>
  );
};
