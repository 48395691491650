export const HEADING = "Cancel your experience";
export const LOADING_POLICY =
  "Loading experience details and cancellation policy...";
export const PROCESSING_CANCEL = "Processing cancellation...";
export const CANCEL_INFO_TITLE = "Cancellation information";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const DEFAULT_CUSTOMER_SERVICE_CANCEL_BODY =
  "Unfortunately, this booking cannot be canceled via self-service. To cancel, please call customer support at 844-422-6922.";
export const BACK_TO_TRIPS = "Back to trips";
export const TOTAL_AMOUNT_REFUNDED = "Total amount being refunded";
