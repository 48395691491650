import { combineReducers, Middleware } from "@reduxjs/toolkit";
import experimentsReducer from "./experiments/slice";
import {
  chfarItinerariesApiReducer,
  chfarItinerariesApiReducerPath,
  chfarItinerariesApiMiddleware,
} from "./itineraries/api";
import locationAutocompleteReducer from "./locationAutocomplete/slice";

export const reducer = combineReducers({
  experiments: experimentsReducer,
  locationAutocomplete: locationAutocompleteReducer,
});

export const chfarReducers = {
  changeForAnyReason: reducer,
  [chfarItinerariesApiReducerPath]: chfarItinerariesApiReducer,
};

export const chfarMiddleWare: Middleware[] = [chfarItinerariesApiMiddleware];

export type ChangeForAnyReasonState = ReturnType<typeof reducer>;
