import axios from "axios";
import {
  ScheduleCancelFulfillRequest,
  ScheduleCancelFulfillResponse,
} from "redmond";
import { config } from "../../config";
import { getExperiencesCancelFulfillSchedulePath } from "../paths";

const getExperiencesCancelFulfillSchedule = (
  req: ScheduleCancelFulfillRequest
): Promise<ScheduleCancelFulfillResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        getExperiencesCancelFulfillSchedulePath,
        req,
        {
          baseURL: config.baseURL,
        }
      );

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getExperiencesCancelFulfillSchedule;
