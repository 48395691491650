import { getPortalName } from "@capone/common";
import dayjs from "dayjs";
import React from "react";
import {
  LodgingAvailabilityCheckInTime,
  LodgingAvailabilityCheckInTimeEnum,
} from "redmond";

export const ROOM_TYPE = "Room Type";

export const PREMIER_COLLECTION_BENEFITS_TITLE = "Premier Collection Benefits";
export const PREMIER_COLLECTION_BENEFITS_LIST = (
  <ul>
    <li>
      <span>
        Enjoy a <strong>$100 experience credit</strong>
      </span>
    </li>
    <li>
      <span>
        Daily <strong>breakfast for 2</strong> and complimentary{" "}
        <strong>WiFi</strong>
      </span>
    </li>
  </ul>
);

export const CFAR_CANCELLATION_PRIMARY_TEXT = (percentage: number) =>
  `${percentage.toFixed(0)}% refundable by ${getPortalName()}`;
export const CFAR_CANCELLATION_PRIMARY_TEXT_PARTIAL = `Partially refundable by ${getPortalName()}`;
export const CFAR_CANCELLATION_PRIMARY_TEXT_FULLY_REFUNDABLE = `Fully refundable by ${getPortalName()}`;
export const CFAR_CANCELLATION_SECONDARY_TEXT = (percentage: number) =>
  `Your room is made refundable by ${getPortalName()}. Cancel for any reason up until your scheduled check-in time and get a refund for ${percentage.toFixed(
    0
  )}% of your hotel base rate and taxes and fees. Cancellations made after this period are subject to a penalty according to the hotel's policy.`;
export const CFAR_CANCELLATION_SECONDARY_TEXT_REFUND_AMOUNT = (
  price: string,
  shouldRefundCfarPremium: boolean
) =>
  `Your room is made refundable by ${getPortalName()}. Cancel for any reason up until your scheduled check-in time and get <strong>a refund of your hotel base rate plus taxes ($${price})</strong>. Cancellations made after this period are subject to a penalty according to the hotel’s policy. Cancel for any reason fee will ${
    !shouldRefundCfarPremium ? "not " : ""
  }be included in the refund.`;

export const CHECK_IN_INSTRUCTIONS_TITLE =
  "Scheduled check-in and check-out time";

export const formattedCheckIn = (
  date: string,
  time?: LodgingAvailabilityCheckInTime
) => {
  return `<strong>Check-in:</strong> ${dayjs(date).format(
    "dddd, MMMM D, YYYY"
  )}${
    time && time.CheckInTime == LodgingAvailabilityCheckInTimeEnum.Restricted
      ? " at " + dayjs(time.checkInTimeFrom, "HH:mm").format("h:mm A")
      : ""
  }`;
};

export const formattedCheckOut = (date: string, time?: string) =>
  `<strong>Checkout:</strong> ${dayjs(date).format("dddd, MMMM D, YYYY")}${
    time ? " at " + dayjs(time, "HH:mm").format("h:mm A") : ""
  }`;
