import { takeLeading } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { fetchTravelWalletDetailsSaga } from "./travel-wallet-details";
import { fetchRedeemedEarnOffersSaga } from "./fetchRedeemedEarnOffersSaga";

export function* watchFetchTravelWalletDetails() {
  yield takeLeading(
    actionTypes.FETCH_TRAVEL_WALLET_DETAILS,
    fetchTravelWalletDetailsSaga
  );
}

export function* watchFetchRedeemedEarnOffers() {
  yield takeLeading(
    actionTypes.FETCH_REDEEMED_EARN_OFFERS,
    fetchRedeemedEarnOffersSaga
  );
}
