import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  GenericDetailsCard,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardComponent,
  GenericDetailsCardTopSectionComponentEnum,
  GenericDetailsCardTopSectionComponent,
  GenericModalContent,
  ActionButton,
  Icon,
  IconName,
  useDeviceTypes,
  removeTimezone,
} from "halifax";
import { RouteComponentProps, StaticContext } from "react-router";
import {
  BookedFlightItineraryWithDepartureTime,
  CallState,
  CfarCancellationOutcome,
  CfarEnum,
  SelfServeCancelScenario,
  SelfServeEvents,
  getDepartureSlice,
  getReturnSlice,
  VIEWED_CFAR_OFFER_OPTIONS,
  TAPPED_CFAR_OFFER_OPTIONS,
  VIEWED_CFAR_OFFER,
  SELF_SERVE_CLICK_SUPPORT,
  SELF_SERVE_CONFIRM_CANCELLATION,
  SELF_SERVE_CANCEL_RESPONSE_FAILURE,
  SELF_SERVE_CANCEL_RESPONSE_SUCCESS,
  FLIGHT_CFAR_TERMS_BODY,
} from "redmond";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import {
  LoadingScreen,
  LoadingType,
  CompleteScreen,
  ErrorScreen,
  ErrorType,
  CfarItinerarySummary,
} from "./components";
import * as constants from "./constants";
import { ICfarFlightModalContentConnectorProps } from "./container";
import { usePrevious } from "../../../../../../../../hooks/usePrevious";
import { CfarFlightModalStep } from "./types";
import { CfarOption } from "../../../../../../utils/cfarExerciseModels";
import {
  getSelfServeCancelResponseFailureProperties,
  getSelfServeCancelResponseSuccessProperties,
  getSelfServeConfirmCancellationProperties,
  getTappedCfarOfferOptionsProperties,
  getViewedCfarOfferOptionsProperties,
  getViewedCfarOfferProperties,
} from "../../../../../../utils/eventHelpers";
import { getTrackingFlightProvider } from "../../../../../../../../utils/events";
import {
  PATH_TERMS_CFAR,
  CONTACT_SUPPORT_URL,
} from "../../../../../../../../utils/paths";
import * as utils from "../../../../../../utils/index";
import "./styles.scss";
import { getFirstFlightCarrier } from "../../../../../../../../utils/helpers";
import { cfarEnumToEventOutcomeEnum } from "../../../../../../utils/index";
import { getPortalName } from "@capone/common";

export interface ICfarFlightModalContentProps
  extends ICfarFlightModalContentConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  flight: BookedFlightItineraryWithDepartureTime;
  itineraryId: string;
  onClose: () => void;
  setDisableOnClose: (disable: boolean) => void;
  setUsePartialScroll: (usePartialScroll: boolean) => void;
  setHideXButton: (hide: boolean) => void;
}

export const CfarFlightModalContent = (props: ICfarFlightModalContentProps) => {
  const {
    airlineMap,
    airportMap,
    confirmFlightCfarCancellation,
    confirmFlightCfarCancellationCallState,
    fetchFlightCfarCancellationInfoV3,
    fetchFlightCfarInfoCallState,
    flight,
    itineraryId,
    onClose,
    selectedCfar,
    isMultiCityCfar,
    selectedCfarItineraryId,
    setDisableOnClose,
    setUsePartialScroll,
    setFetchFlightCfarCancellationInfoV3CallStateNotCalled,
    setHideXButton,
    selectedAccountReferenceId,
    isFintechCsatEnabled,
  } = props;
  const [currentStep, setCurrentStep] = useState<CfarFlightModalStep>(
    CfarFlightModalStep.LoadingCancellationPolicy
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const [isCancellationPending, setIsCancellationPending] =
    useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<CfarOption | undefined>(
    undefined
  );
  const [topContent, setTopContent] =
    useState<GenericDetailsCardTopSectionComponent>();
  const [mainContent, setMainContent] =
    useState<GenericDetailsCardComponent[]>();
  const [bottomContent, setBottomContent] =
    useState<GenericDetailsCardComponent[]>();
  const { matchesMobile } = useDeviceTypes();
  const usePartialScroll = useMediaQuery(
    constants.getPartialScrollThreshold(selectedCfar?.Cfar, currentStep),
    {
      noSsr: true,
    }
  );
  const isPartialScrollActive = useMemo(() => {
    return !matchesMobile && usePartialScroll;
  }, [matchesMobile, usePartialScroll]);
  const isOnCfarWorkflow =
    CfarFlightModalStep.Choice <= currentStep &&
    currentStep <= CfarFlightModalStep.Confirmation;
  const isLoadingPolicy =
    currentStep === CfarFlightModalStep.LoadingCancellationPolicy;
  const isCancellationInProgress =
    currentStep === CfarFlightModalStep.CancellationInProgress;
  const isComplete = currentStep === CfarFlightModalStep.Complete;

  const onReset = () => {
    setCurrentStep(0);
    setHasError(false);
    setIsCancellationPending(false);
    setSelectedValue(undefined);
    setTopContent(undefined);
    setMainContent(undefined);
    setBottomContent(undefined);
  };

  // note: when the user has opened this modal through a different itinerary, it should reset everything.
  useEffect(() => {
    if (itineraryId !== selectedCfarItineraryId) {
      onReset();
      setFetchFlightCfarCancellationInfoV3CallStateNotCalled();
    }
  }, [itineraryId, selectedCfarItineraryId]);

  useEffect(() => {
    if (isLoadingPolicy) {
      switch (fetchFlightCfarInfoCallState) {
        case CallState.Success:
          if (selectedCfar?.Cfar === CfarEnum.CancellationPending) {
            setIsCancellationPending(true);
          } else {
            setCurrentStep((step) => step + 1);
          }
          break;
        case CallState.Failed:
          setHasError(true);
          break;
        case CallState.NotCalled:
          fetchFlightCfarCancellationInfoV3({ itineraryId });
          break;
        default:
          break;
      }
    }
  }, [isLoadingPolicy, fetchFlightCfarInfoCallState, itineraryId]);

  useEffect(() => {
    let outcome;

    if (fetchFlightCfarInfoCallState === CallState.Success) {
      outcome = cfarEnumToEventOutcomeEnum(selectedCfar?.Cfar);
    } else if (fetchFlightCfarInfoCallState === CallState.Failed) {
      outcome = CfarCancellationOutcome.Error;
    }

    if (outcome) {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: {
          cancel_scenario: SelfServeCancelScenario.CfarCancel,
          carrier: getFirstFlightCarrier(flight),
          cfar_outcome: outcome,
          provider_name: getTrackingFlightProvider(flight),
          is_multi_ticket: isMultiTicket,
        },
      });
    }
  }, [fetchFlightCfarInfoCallState]);

  useEffect(() => {
    if (isCancellationInProgress) {
      switch (confirmFlightCfarCancellationCallState) {
        case CallState.Success:
          setCurrentStep(CfarFlightModalStep.Complete);
          break;
        case CallState.Failed:
          setHasError(true);
          break;
        default:
          break;
      }
    }
  }, [isCancellationInProgress, confirmFlightCfarCancellationCallState]);

  // Note: This useEffect is for handling the setHideXButtonRef and setDisableOnClose logic
  useEffect(() => {
    switch (currentStep) {
      case CfarFlightModalStep.LoadingCancellationPolicy:
      case CfarFlightModalStep.CancellationInProgress:
        setHideXButton(!hasError ? true : false);
        setDisableOnClose(!hasError ? true : false);
        break;
      case CfarFlightModalStep.Complete:
        setHideXButton(true);
        setDisableOnClose(false);
        break;
      default:
        setHideXButton(false);
        setDisableOnClose(false);
        break;
    }
  }, [currentStep, hasError, setHideXButton]);

  // Note: Handle the tracking for final cancellation success and failure response
  useEffect(() => {
    if (
      currentStep === CfarFlightModalStep.CancellationInProgress &&
      hasError
    ) {
      trackEvent({
        eventName: SELF_SERVE_CANCEL_RESPONSE_FAILURE,
        properties: getSelfServeCancelResponseFailureProperties(
          flight,
          selectedValue,
          selectedCfar,
          isMultiTicket
        ),
      });
    } else if (currentStep === CfarFlightModalStep.Complete) {
      trackEvent({
        eventName: SELF_SERVE_CANCEL_RESPONSE_SUCCESS,
        properties: getSelfServeCancelResponseSuccessProperties(
          flight,
          selectedValue,
          selectedCfar,
          isMultiTicket
        ),
      });
    }
  }, [currentStep, hasError, selectedValue]);

  const ItinerarySummaryRow = useMemo(() => {
    if (isMultiCityCfar) {
      return (
        <div
          className={clsx("itinerary-data-row", "multi-city", {
            mobile: matchesMobile,
          })}
          key={`ItinerarySummary_${flight?.bookedItinerary?.id}`}
        >
          {flight.bookedItinerary.travelItinerary.slices.map((slice) => {
            return (
              <CfarItinerarySummary
                airlineMap={airlineMap ? airlineMap : undefined}
                airportMap={airportMap ? airportMap : undefined}
                className="multi-city"
                flightSlice={slice}
                isMultiCity
              />
            );
          })}
        </div>
      );
    }

    return (
      <div
        className={clsx("itinerary-data-row", { mobile: matchesMobile })}
        key={`ItinerarySummary_${flight?.bookedItinerary?.id}`}
      >
        <CfarItinerarySummary
          isOutgoing
          airlineMap={airlineMap ? airlineMap : undefined}
          airportMap={airportMap ? airportMap : undefined}
          className="outgoing-itinerary"
          flightSlice={
            flight ? getDepartureSlice(flight.bookedItinerary) : undefined
          }
        />
        <CfarItinerarySummary
          airlineMap={airlineMap ? airlineMap : undefined}
          airportMap={airportMap ? airportMap : undefined}
          className="return-itinerary"
          flightSlice={
            flight ? getReturnSlice(flight.bookedItinerary) : undefined
          }
        />
      </div>
    );
  }, [airlineMap, airportMap, flight, isMultiCityCfar]);

  const departureSlice = getDepartureSlice(flight.bookedItinerary);
  const returnSlice = getReturnSlice(flight.bookedItinerary);
  const departureAirline = departureSlice
    ? departureSlice.segments[0]?.marketingAirline
    : undefined;
  const returnAirline = returnSlice
    ? returnSlice.segments[0]?.marketingAirline
    : undefined;
  const departureAirlineDisplayName = departureAirline?.code
    ? airlineMap[departureAirline.code]?.displayName ?? ""
    : "";
  const returnAirlineDisplayName = returnAirline?.code
    ? airlineMap[returnAirline.code]?.displayName ?? ""
    : "";
  const isMultiTicket = utils.isMultiTicket(
    flight.bookedItinerary.multiTicketType
  );
  const hasDifferentMarketingAirlines =
    departureAirlineDisplayName !== "" &&
    returnAirlineDisplayName !== "" &&
    departureAirlineDisplayName !== returnAirlineDisplayName;
  const airlines = hasDifferentMarketingAirlines
    ? [departureAirlineDisplayName, returnAirlineDisplayName]
    : [departureAirlineDisplayName];

  const timestamp = dayjs(
    removeTimezone(flight.ancillaries.cfar?.expired ?? "")
  ).format("MMM DD");

  const previouslyChosenStep = usePrevious(currentStep);

  const getReadTermsLink = (
    className?: string
  ): GenericDetailsCardComponent => ({
    className,
    message: constants.READ_TERMS_AND_CONDITIONS,
    onClick: () => window.open(PATH_TERMS_CFAR, "_blank")?.focus(),
    position: "left",
    component: GenericDetailsCardComponentEnum.ClickableLink,
  });
  const getTermsAndConditions = useCallback(
    (className?: string): GenericDetailsCardComponent => {
      return (
        matchesMobile
          ? {
              className,
              content: [
                {
                  title: constants.READ_TERMS_AND_CONDITIONS,
                  body: FLIGHT_CFAR_TERMS_BODY(getPortalName()),
                },
              ],
              component: GenericDetailsCardComponentEnum.AccordionGroup,
            }
          : getReadTermsLink(className)
      ) as GenericDetailsCardComponent;
    },
    [matchesMobile]
  );

  useEffect(() => {
    switch (currentStep) {
      case CfarFlightModalStep.Choice:
        switch (selectedCfar?.Cfar) {
          case CfarEnum.CfarCash: {
            const termsAndConditionsClassName = clsx(
              "cfar-cash-choice-terms-and-conditions",
              { mobile: matchesMobile }
            );
            const cta: GenericDetailsCardComponent = {
              message: constants.CONTINUE_BUTTON_COPY,
              onClick: () => {
                trackEvent({
                  eventName: SelfServeEvents.ClickCancel,
                  properties: {
                    cancel_scenario: SelfServeCancelScenario.CfarCancel,
                    carrier: getFirstFlightCarrier(flight),
                    cfar_outcome: cfarEnumToEventOutcomeEnum(selectedCfar.Cfar),
                    provider_name: getTrackingFlightProvider(flight),
                    is_multi_ticket: isMultiTicket,
                  },
                });
                setCurrentStep(CfarFlightModalStep.Details);
              },
              ariaLabel: constants.CONTINUE_BUTTON_COPY,
              fill: "blue",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            };
            const content: GenericDetailsCardComponent[] = [
              {
                className: "cfar-exercise-modal-subtitle",
                type: "subtitle",
                copy: constants.getCfarCashSubtitle(
                  selectedCfar.coveragePercent
                ),
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              !isPartialScrollActive
                ? getTermsAndConditions(termsAndConditionsClassName)
                : undefined,
              {
                content: ItinerarySummaryRow,
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                className: "cfar-modal-content-points-group",
                title: constants.CANCELLATION_INFORMATION_HEADER,
                items: constants.getCfarCashTitleConfirmInformation(timestamp),
                component: GenericDetailsCardComponentEnum.PointsGroup,
              },
              !isPartialScrollActive ? cta : undefined,
            ].filter(
              (content) => content !== undefined
            ) as GenericDetailsCardComponent[];

            setTopContent({
              header: constants.CANCEL_YOUR_FLIGHT_FOR_ANY_REASON_TITLE,
              icon: IconName.CheckShieldBlue,
              component:
                GenericDetailsCardTopSectionComponentEnum.GenericHeader,
            });
            setMainContent(content);
            if (isPartialScrollActive) {
              setBottomContent([getReadTermsLink(), cta]);
            }
            break;
          }
          case CfarEnum.CfarCashOrCfarFtc:
          case CfarEnum.CfarCashOrCfarFtcWithPenalty: {
            const subtitle: GenericDetailsCardComponent = {
              className: "cfar-exercise-modal-subtitle",
              type: "subtitle",
              copy: constants.getChooseBetweenTravelCreditOrPaymentSubtitle(
                selectedCfar.coveragePercent,
                hasDifferentMarketingAirlines
              ),
              component: GenericDetailsCardComponentEnum.GenericCopy,
            };
            const radioButtons: GenericDetailsCardComponent = {
              radioButtons: [
                {
                  value: CfarOption.PARTIAL_CASH_REFUND,
                  title: constants.getPartialCashRefundTitle(
                    selectedCfar.coveragePercent
                  ),
                  copy: constants.getPartialCashRefundSubtitle(
                    selectedCfar.coveragePercent,
                    selectedCfar.refundPrices.fiat,
                    selectedCfar.refundPrices.rewards[
                      selectedAccountReferenceId ?? ""
                    ],
                    selectedCfar.refundBreakdown
                  ),
                },
                {
                  value: CfarOption.AIRLINE_CREDIT,
                  title: constants.getAirlineCreditTitle(
                    airlines,
                    hasDifferentMarketingAirlines
                  ),
                  copy: constants.getAirlineCreditSubtitle(
                    selectedCfar.ftcRefundPrices.fiat,
                    selectedCfar.ftcRefundPrices.rewards[
                      selectedAccountReferenceId ?? ""
                    ],
                    airlines,
                    hasDifferentMarketingAirlines
                  ),
                },
              ],
              selectedValue,
              setSelectedValue,
              component: GenericDetailsCardComponentEnum.BoxedRadioGroup,
            };
            const cta: GenericDetailsCardComponent = {
              message: constants.CONTINUE_BUTTON_COPY,
              onClick: () => {
                trackEvent({
                  eventName: TAPPED_CFAR_OFFER_OPTIONS,
                  properties: getTappedCfarOfferOptionsProperties(
                    flight,
                    selectedValue,
                    selectedCfar.Cfar
                  ),
                });
                setCurrentStep(CfarFlightModalStep.Details);
              },
              ariaLabel: constants.CONTINUE_BUTTON_COPY,
              disabled: selectedValue === undefined,
              fill: "blue",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            };

            const content: GenericDetailsCardComponent[] = [subtitle];
            // note: isPartialScrollActive is false when it's on mobile
            if (matchesMobile) {
              content.push(radioButtons, getTermsAndConditions(), cta);
            } else if (isPartialScrollActive) {
              content.push(radioButtons);
            } else {
              content.push(getTermsAndConditions(), radioButtons, cta);
            }

            setTopContent({
              header: constants.CANCEL_YOUR_FLIGHT_FOR_ANY_REASON_TITLE,
              icon: IconName.CheckShieldBlue,
              component:
                GenericDetailsCardTopSectionComponentEnum.GenericHeader,
            });
            setMainContent(content);
            if (isPartialScrollActive) {
              setBottomContent([getReadTermsLink(), cta]);
            }

            if (previouslyChosenStep !== currentStep) {
              // Only send a tracking event when the step changes. This guards against repeatedly sending
              // VIEWED_CFAR_OFFER_OPTIONS event simply when the radio button is chosen.
              trackEvent({
                eventName: VIEWED_CFAR_OFFER_OPTIONS,
                properties: getViewedCfarOfferOptionsProperties(flight),
              });
            }
            break;
          }
          case CfarEnum.AirlineFullRefund: {
            const termsAndConditionsClassName = clsx(
              "cfar-airline-full-refund-terms-and-conditions",
              { mobile: matchesMobile }
            );
            const cta: GenericDetailsCardComponent = {
              message: constants.CONTINUE_BUTTON_COPY,
              onClick: () => {
                trackEvent({
                  eventName: SelfServeEvents.ClickCancel,
                  properties: {
                    cancel_scenario: SelfServeCancelScenario.CfarCancel,
                    carrier: getFirstFlightCarrier(flight),
                    cfar_outcome: cfarEnumToEventOutcomeEnum(selectedCfar.Cfar),
                    provider_name: getTrackingFlightProvider(flight),
                    is_multi_ticket: isMultiTicket,
                  },
                });
                setCurrentStep(CfarFlightModalStep.Details);
              },
              ariaLabel: constants.CONTINUE_BUTTON_COPY,
              fill: "blue",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            };
            const content: GenericDetailsCardComponent[] = [
              {
                className: "cfar-exercise-modal-subtitle",
                type: "subtitle",
                copy: constants.FULL_REFUND_SUBTITLE,
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              !isPartialScrollActive
                ? getTermsAndConditions(termsAndConditionsClassName)
                : undefined,
              {
                content: ItinerarySummaryRow,
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                className: "cfar-modal-content-points-group",
                title: constants.CANCELLATION_INFORMATION_HEADER,
                items:
                  constants.getFullRefundCancellationInformation(timestamp),
                component: GenericDetailsCardComponentEnum.PointsGroup,
              },
              !isPartialScrollActive ? cta : undefined,
            ].filter(
              (content) => content !== undefined
            ) as GenericDetailsCardComponent[];

            setTopContent({
              header: constants.CANCEL_YOUR_FLIGHT_FOR_ANY_REASON_TITLE,
              icon: IconName.CheckShieldBlue,
              component:
                GenericDetailsCardTopSectionComponentEnum.GenericHeader,
            });
            setMainContent(content);
            if (isPartialScrollActive) {
              setBottomContent([getReadTermsLink(), cta]);
            }
            break;
          }
          case CfarEnum.TicketedVoid: {
            const termsAndConditionsClassName = clsx(
              "cfar-ticketed-void-terms-and-conditions",
              { mobile: matchesMobile }
            );
            const cta: GenericDetailsCardComponent = {
              message: constants.CONTINUE_BUTTON_COPY,
              onClick: () => {
                trackEvent({
                  eventName: SelfServeEvents.ClickCancel,
                  properties: {
                    cancel_scenario: SelfServeCancelScenario.CfarCancel,
                    carrier: getFirstFlightCarrier(flight),
                    cfar_outcome: cfarEnumToEventOutcomeEnum(selectedCfar.Cfar),
                    provider_name: getTrackingFlightProvider(flight),
                    is_multi_ticket: isMultiTicket,
                  },
                });
                setCurrentStep(CfarFlightModalStep.Details);
              },
              ariaLabel: constants.CONTINUE_BUTTON_COPY,
              fill: "blue",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            };
            const content: GenericDetailsCardComponent[] = [
              {
                className: "cfar-exercise-modal-subtitle",
                type: "subtitle",
                copy: constants.getTicketedVoidSubtitle(
                  dayjs(removeTimezone(selectedCfar.windowEnd ?? "")).format(
                    "MMM DD"
                  )
                ),
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              !isPartialScrollActive
                ? getTermsAndConditions(termsAndConditionsClassName)
                : undefined,
              {
                content: ItinerarySummaryRow,
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                className: "cfar-modal-content-points-group",
                title: constants.CANCELLATION_INFORMATION_HEADER,
                items: constants.VOID_WINDOW_CANCELLATION_INFORMATION,
                component: GenericDetailsCardComponentEnum.PointsGroup,
              },
              !isPartialScrollActive ? cta : undefined,
            ].filter(
              (content) => content !== undefined
            ) as GenericDetailsCardComponent[];

            setTopContent({
              header: constants.CANCEL_YOUR_FLIGHT_FOR_ANY_REASON_TITLE,
              icon: IconName.CheckShieldBlue,
              component:
                GenericDetailsCardTopSectionComponentEnum.GenericHeader,
            });
            setMainContent(content);
            if (isPartialScrollActive) {
              setBottomContent([getReadTermsLink(), cta]);
            }
            break;
          }
          case CfarEnum.AirlinePartialRefundOrCfarCash:
          case CfarEnum.TooCloseToDeparture:
          default: {
            const termsAndConditionsClassName = clsx(
              "cfar-contact-support-terms-and-conditions",
              { mobile: matchesMobile }
            );
            const cta: GenericDetailsCardComponent = {
              message: constants.CONTACT_SUPPORT_COPY,
              onClick: () => {
                trackEvent({
                  eventName: SELF_SERVE_CLICK_SUPPORT,
                  properties: {
                    cancel_scenario: SelfServeCancelScenario.CfarCancel,
                  },
                });
                window.open(CONTACT_SUPPORT_URL, "_blank", "noopener")?.focus();
              },
              ariaLabel: constants.CONTACT_SUPPORT_COPY,
              fill: "blue",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            };
            const content: GenericDetailsCardComponent[] = [
              {
                className: "cfar-exercise-modal-subtitle",
                type: "subtitle",
                copy: constants.UNSUPPORTED_TICKET_SUBTITLE,
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              !isPartialScrollActive
                ? getTermsAndConditions(termsAndConditionsClassName)
                : undefined,
              {
                content: ItinerarySummaryRow,
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                className: "cfar-modal-content-points-group",
                title: constants.CANCELLATION_INFORMATION_HEADER,
                items:
                  constants.getUnsupportedTicketCancellationInformation(
                    timestamp
                  ),
                component: GenericDetailsCardComponentEnum.PointsGroup,
              },
              !isPartialScrollActive ? cta : undefined,
            ].filter(
              (content) => content !== undefined
            ) as GenericDetailsCardComponent[];

            setTopContent({
              header: constants.CANCEL_YOUR_FLIGHT_FOR_ANY_REASON_TITLE,
              icon: IconName.CheckShieldBlue,
              component:
                GenericDetailsCardTopSectionComponentEnum.GenericHeader,
            });
            setMainContent(content);
            if (isPartialScrollActive) {
              setBottomContent([getReadTermsLink(), cta]);
            }
            break;
          }
        }
        break;

      case CfarFlightModalStep.Details: {
        const handleConfirm = (useCash?: boolean) => {
          trackEvent({
            eventName: SELF_SERVE_CONFIRM_CANCELLATION,
            properties: {
              ...getSelfServeConfirmCancellationProperties(
                flight,
                selectedValue,
                selectedCfar,
                isMultiTicket
              ),
            },
          });
          confirmFlightCfarCancellation(useCash);
          setCurrentStep(CfarFlightModalStep.CancellationInProgress);
        };

        switch (selectedCfar?.Cfar) {
          case CfarEnum.AirlineFullRefund:
          case CfarEnum.TicketedVoid:
          case CfarEnum.CfarCash: {
            const cta: GenericDetailsCardComponent = {
              message: constants.CONFIRM_CANCELLATION_BUTTON_COPY,
              onClick: () => {
                handleConfirm();
              },
              ariaLabel: constants.CONFIRM_CANCELLATION_BUTTON_COPY,
              fill: "red",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            };
            const goBack: GenericDetailsCardComponent = {
              message: constants.GO_BACK,
              onClick: () => {
                setCurrentStep(CfarFlightModalStep.Choice);
              },
              position: "center",
              component: GenericDetailsCardComponentEnum.ClickableLink,
            };
            const content: GenericDetailsCardComponent[] = [
              {
                className: "cfar-exercise-modal-subtitle",
                type: "subtitle",
                copy: constants.getCfarCashConfirmSubtitle(
                  selectedCfar.refundPrices.fiat,
                  selectedCfar.refundPrices.rewards[
                    selectedAccountReferenceId ?? ""
                  ],
                  selectedCfar.refundBreakdown
                ),
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              {
                content: ItinerarySummaryRow,
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                content: (
                  <Typography
                    variant="body2"
                    className="cfar-cash-choice-confirm-cancel-text"
                  >
                    {constants.CONFIRM_CANCELLATION_TEXT}
                  </Typography>
                ),
                component: GenericDetailsCardComponentEnum.Custom,
              },
              !isPartialScrollActive ? cta : undefined,
              !isPartialScrollActive ? goBack : undefined,
            ].filter(
              (content) => content !== undefined
            ) as GenericDetailsCardComponent[];

            setTopContent({
              header: constants.CONFIRM_CANCELLATION_TITLE,
              component:
                GenericDetailsCardTopSectionComponentEnum.GenericHeader,
            });
            setMainContent(content);
            if (isPartialScrollActive) {
              setBottomContent([goBack, cta]);
            }
            break;
          }
          case CfarEnum.CfarCashOrCfarFtc:
          case CfarEnum.CfarCashOrCfarFtcWithPenalty: {
            const getCta = (useCash: boolean): GenericDetailsCardComponent => ({
              message: constants.CONFIRM_CANCELLATION_BUTTON_COPY,
              onClick: () => {
                handleConfirm(useCash);
              },
              ariaLabel: constants.CONFIRM_CANCELLATION_BUTTON_COPY,
              fill: "red",
              size: "small",
              component: GenericDetailsCardComponentEnum.GenericCta,
            });
            const getGoBack = (
              position: "left" | "center" | "right"
            ): GenericDetailsCardComponent => ({
              message: constants.GO_BACK,
              onClick: () => {
                setCurrentStep(CfarFlightModalStep.Choice);
              },
              position,
              component: GenericDetailsCardComponentEnum.ClickableLink,
            });
            let fiats = [];
            if (departureAirline?.code) {
              fiats.push(
                selectedCfar.refundPricesByAirline[departureAirline?.code].ftc
                  .fiat
              );
            }
            if (returnAirline?.code && hasDifferentMarketingAirlines) {
              fiats.push(
                selectedCfar.refundPricesByAirline[returnAirline?.code].ftc.fiat
              );
            }

            switch (selectedValue) {
              case CfarOption.PARTIAL_CASH_REFUND: {
                const cta = getCta(true);
                const content: GenericDetailsCardComponent[] = [
                  {
                    className: "cfar-exercise-modal-subtitle",
                    type: "subtitle",
                    copy: constants.getPartialCashRefundConfirmSubtitle(
                      selectedCfar.refundPrices.fiat,
                      selectedCfar.refundPrices.rewards[
                        selectedAccountReferenceId ?? ""
                      ],
                      selectedCfar.refundBreakdown
                    ),
                    component: GenericDetailsCardComponentEnum.GenericCopy,
                  },
                  {
                    content: ItinerarySummaryRow,
                    component: GenericDetailsCardComponentEnum.Custom,
                  },
                  {
                    className: "cfar-modal-content-points-group",
                    title: constants.CANCELLATION_INFORMATION_HEADER,
                    items:
                      constants.getPartialCashRefundConfirmInformation(
                        timestamp
                      ),
                    component: GenericDetailsCardComponentEnum.PointsGroup,
                  },
                  !isPartialScrollActive ? cta : undefined,
                  !isPartialScrollActive ? getGoBack("center") : undefined,
                ].filter(
                  (content) => content !== undefined
                ) as GenericDetailsCardComponent[];

                setTopContent({
                  header: constants.CONFIRM_CANCELLATION_TITLE,
                  component:
                    GenericDetailsCardTopSectionComponentEnum.GenericHeader,
                });
                setMainContent(content as GenericDetailsCardComponent[]);
                if (isPartialScrollActive) {
                  setBottomContent([getGoBack("left"), cta]);
                }

                trackEvent({
                  eventName: VIEWED_CFAR_OFFER,
                  properties: getViewedCfarOfferProperties(
                    flight,
                    selectedValue,
                    selectedCfar.Cfar
                  ),
                });
                break;
              }
              case CfarOption.AIRLINE_CREDIT: {
                const cta = getCta(false);
                const content: GenericDetailsCardComponent[] = [
                  {
                    className: "cfar-exercise-modal-subtitle",
                    type: "subtitle",
                    copy: constants.getAirlineCreditConfirmSubtitle(
                      fiats,
                      airlines,
                      hasDifferentMarketingAirlines
                    ),
                    component: GenericDetailsCardComponentEnum.GenericCopy,
                  },
                  {
                    content: ItinerarySummaryRow,
                    component: GenericDetailsCardComponentEnum.Custom,
                  },
                  {
                    className: "cfar-modal-content-points-group",
                    title: constants.CANCELLATION_INFORMATION_HEADER,
                    items:
                      constants.getAirlineCreditConfirmInformation(timestamp),
                    component: GenericDetailsCardComponentEnum.PointsGroup,
                  },
                  !isPartialScrollActive ? cta : undefined,
                  !isPartialScrollActive ? getGoBack("center") : undefined,
                ].filter(
                  (content) => content !== undefined
                ) as GenericDetailsCardComponent[];

                setTopContent({
                  header: constants.CONFIRM_CANCELLATION_TITLE,
                  component:
                    GenericDetailsCardTopSectionComponentEnum.GenericHeader,
                });
                setMainContent(content);
                if (isPartialScrollActive) {
                  setBottomContent([getGoBack("left"), cta]);
                }

                trackEvent({
                  eventName: VIEWED_CFAR_OFFER,
                  properties: getViewedCfarOfferProperties(
                    flight,
                    selectedValue,
                    selectedCfar.Cfar
                  ),
                });
                break;
              }
              default:
                break;
            }
            break;
          }
          case CfarEnum.AirlinePartialRefundOrCfarCash:
          case CfarEnum.TooCloseToDeparture:
          default:
            // These cases should never make it here so send them back to the first step
            setCurrentStep(CfarFlightModalStep.Details);
            break;
        }
        break;
      }
      default:
        break;
    }
    // note: it needs to consume data from the response to know which workflow it should be on; it will be another dependency
    // note: if it's on a Step that's not supposed to be included in the workflow, use an useEffect to skip it
  }, [
    currentStep,
    selectedValue,
    matchesMobile,
    getTermsAndConditions,
    isPartialScrollActive,
  ]);

  useEffect(() => {
    // note: set the css rule to partial-scroll when it satisfies the medium query and it's on desktop
    if (
      isPartialScrollActive &&
      (currentStep === CfarFlightModalStep.Choice ||
        currentStep === CfarFlightModalStep.Details)
    ) {
      setUsePartialScroll(true);
    } else {
      setUsePartialScroll(false);
      setBottomContent(undefined);
    }
  }, [isPartialScrollActive, currentStep]);

  useEffect(() => {
    return () => {
      setUsePartialScroll(false);
    };
  }, []);

  if (hasError) {
    return (
      <ErrorScreen
        onRetry={() => {
          if (isLoadingPolicy) {
            setFetchFlightCfarCancellationInfoV3CallStateNotCalled();
          }
          setHasError(false);
        }}
        onClose={onClose}
        type={
          isLoadingPolicy
            ? ErrorType.FailedToLoadCancellation
            : ErrorType.FailedToCancel
        }
      />
    );
  } else if (isCancellationPending) {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={constants.CLOSE_BUTTON_COPY}
            onClick={onClose}
          />
        }
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        title={constants.PENDING_CANCELLATION_TITLE}
        subtitle={constants.PENDING_CANCELLATION_SUBTITLE}
      />
    );
  } else {
    return (
      <>
        {(isLoadingPolicy || isCancellationInProgress) && (
          <LoadingScreen
            type={
              isLoadingPolicy
                ? LoadingType.FetchingPolicy
                : LoadingType.CancellationInProgress
            }
          />
        )}
        {isOnCfarWorkflow && topContent && mainContent && (
          <GenericDetailsCard
            topContent={topContent}
            mainContent={mainContent}
            bottomContent={bottomContent}
            openModal={true}
            isMobile={matchesMobile}
            contentOnly={true}
            scrollOption={
              isPartialScrollActive ? "partial-scroll" : "default-scroll"
            }
          />
        )}
        {isComplete && (
          <CompleteScreen
            onClose={() => {
              setFetchFlightCfarCancellationInfoV3CallStateNotCalled();
              onClose();
            }}
            isFintechCsatEnabled={isFintechCsatEnabled}
          />
        )}
      </>
    );
  }
};
