import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import {
  CancelModalFlowStepEnum,
  CancelScenario,
  CancelScenarioEnum,
  Lodging,
  LodgingAddressEnum,
  LodgingData,
  Maybe,
  SelfServeEvents,
} from "redmond";
import {
  ActionButton,
  ActionLink,
  B2BSpinnerWithText,
  GenericModalContent,
  HotelSummaryPanel,
  Icon,
  IconName,
  ImportantInfoList,
} from "halifax";

import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import confirmGroundCancellation from "../../../../../../../../api/v1/itinerary/confirmGroundCancellation";
import getGroundChangeInfo from "../../../../../../../../api/v1/itinerary/getGroundChangeInfo";
import * as constants from "../../constants";
import { IChangeGroundModalContentProps } from "./container";
import { getTrackingCarProvider } from "../../../../../../../../utils/events";
import {
  PATH_HOME,
  CONTACT_SUPPORT_URL,
} from "../../../../../../../../utils/paths";

import "./styles.scss";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../../../../../api/config";

const defaultProps: Partial<IChangeGroundModalContentProps> = {
  isMobile: false,
};

const renderContactSupportButton = (supportUrl?: string) => (
  <ActionButton
    className="b2b"
    defaultStyle="h4r-primary"
    message={constants.CONTACT_SUPPORT}
    onClick={() => {
      trackEvent({
        eventName: SelfServeEvents.ClickSupport,
        properties: {
          product_funnel: "ground",
        },
      });

      window.open(supportUrl || CONTACT_SUPPORT_URL, "_blank")?.focus();
    }}
  />
);

const renderSuccessModal = (closeModal: () => void) => (
  <GenericModalContent
    className="ground-change-modal-success"
    title={constants.CANCELLATION_SUCCESS_TITLE}
    subtitle={constants.CANCELLATION_SUCCESS_COPY}
    image={<Icon className="success-icon" name={IconName.Checked} />}
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={constants.CLOSE}
        onClick={closeModal}
      />
    }
  />
);

const renderContactSupport = (
  title: string,
  subtitle: string[],
  closeModal: () => void
) => (
  <GenericModalContent
    className="ground-change-modal-contact-support"
    title={title}
    subtitle={subtitle}
    actions={
      <>
        {renderContactSupportButton()}
        <ActionButton
          defaultStyle="h4r-secondary"
          message={constants.CLOSE}
          onClick={closeModal}
        />
      </>
    }
  />
);

const renderVendorSupportButton = (closeModal: () => void, url?: string) => (
  <ActionLink
    className="vendor-support"
    content={
      url ? (
        <>
          {constants.CONTINUE_TO_PROVIDER_WEBSITE}
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </>
      ) : (
        constants.BACK_TO_MY_TRIPS
      )
    }
    onClick={() => {
      if (url) {
        trackEvent({
          eventName: isCorpTenant(config.TENANT)
            ? SelfServeEvents.ClickRedirectToCarrier
            : SelfServeEvents.ClickSupport,
          properties: {
            product_funnel: "ground",
          },
        });
        return window.open(url, "_blank")?.focus();
      }
      return closeModal();
    }}
  />
);

const renderTryAgainModal = (
  title: string,
  subtitle: string[],
  counter: number,
  tryAgain: () => void
) => (
  <GenericModalContent
    className="ground-change-modal-try-again"
    title={title}
    subtitle={subtitle}
    actions={
      <>
        {counter >= 3 && renderContactSupportButton()}
        {counter < 3 && (
          <ActionButton
            defaultStyle="h4r-secondary"
            message={constants.TRY_AGAIN}
            onClick={tryAgain}
          />
        )}
      </>
    }
  />
);

const ChangeGroundModalContent = (props: IChangeGroundModalContentProps) => {
  const { setOpenModal, groundBooking, isMobile, history } = props;
  const [cancelScenario, setCancelScenario] =
    useState<Maybe<CancelScenario>>(null);
  const { cancelCopy, cancelConfirmationCopy, customerServiceCopy } =
    cancelScenario || {};
  const { bookResult } = groundBooking;
  const { groundBookingId } = bookResult || {};
  const [modalFlowStep, setModalFlowStep] = useState<CancelModalFlowStepEnum>(
    CancelModalFlowStepEnum.loading
  );
  const [tryAgain, setTryAgain] = useState<number>(0);

  const closeModal = useCallback(() => {
    setOpenModal({ type: null, selectedItinerary: null });
  }, []);
  const goToConfirm = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ClickCancel,
      properties: {
        product_funnel: constants.GROUND_PRODUCT,
        provider_name: getTrackingCarProvider(groundBooking),
      },
    });
    setModalFlowStep(CancelModalFlowStepEnum.confirm);
  }, []);
  const getPolicyInfo = useCallback(() => {
    setModalFlowStep(CancelModalFlowStepEnum.loading);
    getGroundChangeInfo({
      reservationId: groundBookingId,
    })
      .then((groundPolicy) => {
        setCancelScenario(groundPolicy);
        switch (groundPolicy.GroundCancelScenario) {
          case CancelScenarioEnum.ContactCustomerService:
          case CancelScenarioEnum.FullyRefundableComplex:
          case CancelScenarioEnum.CancellationFailure:
          case CancelScenarioEnum.PartialRefund:
          case CancelScenarioEnum.Pending:
          case CancelScenarioEnum.PartiallyRefundableComplex:
          case CancelScenarioEnum.Canceled:
            return setModalFlowStep(CancelModalFlowStepEnum.support);

          case CancelScenarioEnum.FullRefund:
            return setModalFlowStep(CancelModalFlowStepEnum.policy);

          case CancelScenarioEnum.NonRefundable:
            return setModalFlowStep(CancelModalFlowStepEnum.nonRefundable);

          default:
            return setModalFlowStep(CancelModalFlowStepEnum.loading);
        }
      })
      .catch(() => {
        setModalFlowStep(CancelModalFlowStepEnum.tryAgain);
      });
  }, [groundBookingId]);

  useEffect(() => {
    if (groundBooking) {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: {
          product_funnel: constants.GROUND_PRODUCT,
          provider_name: getTrackingCarProvider(groundBooking),
        },
      });

      getPolicyInfo();
    }
  }, [groundBooking, getPolicyInfo]);

  const handleGroundCancel = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ConfirmCancellation,
      properties: {
        product_funnel: constants.GROUND_PRODUCT,
        provider_name: getTrackingCarProvider(groundBooking),
      },
    });

    setModalFlowStep(CancelModalFlowStepEnum.loading);
    if (cancelScenario && groundBookingId) {
      confirmGroundCancellation(
        {
          reservationId: groundBookingId,
          cancelScenario,
        },
        groundBooking
      )
        .then(() => {
          setModalFlowStep(CancelModalFlowStepEnum.success);
        })
        .catch(() => {
          setModalFlowStep(CancelModalFlowStepEnum.tryAgain);
        });
    }
  }, [cancelScenario, groundBookingId, setOpenModal]);

  switch (modalFlowStep) {
    case CancelModalFlowStepEnum.support:
      return renderContactSupport(
        customerServiceCopy!.title,
        customerServiceCopy!.body,
        () =>
          setOpenModal({
            type: null,
            selectedItinerary: null,
          })
      );
    case CancelModalFlowStepEnum.policy:
    case CancelModalFlowStepEnum.nonRefundable:
    case CancelModalFlowStepEnum.confirm:
      const isConfirm = modalFlowStep === CancelModalFlowStepEnum.confirm;
      const isNonRefundable =
        modalFlowStep === CancelModalFlowStepEnum.nonRefundable;
      const copyObj = isConfirm ? cancelConfirmationCopy : cancelCopy;
      const pickup = bookResult.pickUp.dateTime;
      const dropoff = bookResult.dropOff.dateTime;
      // @ts-ignore
      const vehicleType = bookResult.vehicle.label || bookResult.vehicle.kind;
      const vehicleName = bookResult.vehicle.name;
      const { contactSupport } = bookResult.vendor || {};

      // this is a hacked together HotelLodging object
      // in order to reuse the HotelSummaryPanel component
      const lodgingData = {
        lodging: {
          name: vehicleType,
          address: {
            LodgingAddress: LodgingAddressEnum.Obfuscated,
            city: vehicleName,
          },
          media: [],
        } as unknown as LodgingData,
      } as unknown as Lodging;

      return (
        <GenericModalContent
          className="ground-change-modal"
          title={copyObj?.title}
          subtitle={copyObj?.body}
          content={
            <Box className="ground-change-modal-content">
              <HotelSummaryPanel
                hideImage
                isMobile={isMobile}
                hideStarRating
                selectedLodging={lodgingData}
                checkIn={pickup ? dayjs(pickup).toDate() : null}
                checkOut={dropoff ? dayjs(dropoff).toDate() : null}
                checkInLabel={constants.PICK_UP}
                checkOutLabel={constants.DROP_OFF}
                dateFormat="ddd, MMM D"
              />
              {!!copyObj?.importantInfo.length ? (
                <ImportantInfoList
                  title="Cancellation Information"
                  infoItems={copyObj?.importantInfo}
                />
              ) : null}
            </Box>
          }
          actions={
            isNonRefundable ? (
              renderVendorSupportButton(closeModal, contactSupport)
            ) : (
              <ActionButton
                className={clsx("ground-change-modal-action-button", {
                  red: isConfirm,
                })}
                defaultStyle="h4r-primary"
                onClick={isConfirm ? handleGroundCancel : goToConfirm}
                message={
                  isConfirm ? constants.CONFIRM_CANCEL : constants.CANCEL_GROUND
                }
              />
            )
          }
        />
      );
    case CancelModalFlowStepEnum.success:
      return renderSuccessModal(() => {
        closeModal();
        history.push(`${PATH_HOME}?tripId=${groundBookingId}`);
        window.location.reload();
      });
    case CancelModalFlowStepEnum.tryAgain:
      return renderTryAgainModal(
        constants.SOMETHING_WENT_WRONG,
        [constants.TRY_AGAIN],
        tryAgain,
        () => {
          setTryAgain(tryAgain + 1);
          getPolicyInfo();
        }
      );
    default:
      return (
        <B2BSpinnerWithText subtitle={constants.LOADING_GROUND_CANCEL_POLICY} />
      );
  }
};

ChangeGroundModalContent.defaultProps = defaultProps;

export default ChangeGroundModalContent;
