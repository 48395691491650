import { Box, Divider, Typography } from "@material-ui/core";
import {
  FlightDetailsSummary,
  IconName,
  MixedCabinToolTip,
  Restriction,
  SelectedSeatsConfirmation,
  StatusTag,
} from "halifax";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  AirlineMap,
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  getItinerarySummaryProps,
  getMulticitySlice,
  getRestrictionInfo,
  getReturnSlice,
  MyTripsFilter,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VIEWED_SELF_TRANSFER_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
} from "redmond";

import { getIsMixedClass } from "../../../../../../constants";
import { getTripsFilter } from "../../../../../../reducer";
import { isTFBooking, isViMultiTicket } from "../../../../../../utils";
import { getStatusTag } from "../../../FlightCard/component";
import { getSeatSegments } from "../../../FlightCard/components/DesktopFlightCard/component";
import * as textConstants from "../../../FlightCard/constants";
import SeatUpdateInstructions from "../../../SeatUpdateInstructions/component";
import { ItinerarySummaryModalContentConnectorProps } from "./container";

import "./styles.scss";
import { FlightVICombinationBanner } from "../../../FlightVICombinationBanner";
import { airlinesCountFlightItinerarySegment } from "../../../FlightCard/helpers";
import {
  FlightMissedConnectionGuarantee,
  SelfTransferBanner,
} from "../../../FlightMissedConnectionGuarantee";
import {
  IVirtualInterliningVariant,
  VirtualIinterliningModal,
} from "../../../../../../../common/VirtualIinterliningModal";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { updateRestrictionWithAncillaries } from "../../../../../../utils/updateRestrictionWithAncillaries";

export interface IItinerarySummaryModalContentProps
  extends ItinerarySummaryModalContentConnectorProps {
  departure: boolean;
  flight: BookedFlightItineraryWithDepartureTime;
  multicitySliceIndex?: number;
}

export const ItinerarySummaryModalContent = (
  props: IItinerarySummaryModalContentProps
) => {
  const { flight, airportMap, airlineMap, departure, multicitySliceIndex } =
    props;
  const { bookedItinerary } = flight;
  const { passengers, scheduleChange, seats, travelItinerary } =
    bookedItinerary;
  const tripsFilter = useSelector(getTripsFilter);
  const [isMixedCabinClass, setIsMixedCabinClass] = React.useState(false);
  const [variant, setVariant] = React.useState<
    IVirtualInterliningVariant | false
  >(false);
  const isMulticity = multicitySliceIndex !== undefined;
  const multicitySlice =
    isMulticity && getMulticitySlice(bookedItinerary, multicitySliceIndex);
  const departureSlice = getDepartureSlice(bookedItinerary);
  const returnSlice = getReturnSlice(bookedItinerary);
  const isTF = isTFBooking(travelItinerary);
  const slice = multicitySlice || departure ? departureSlice : returnSlice;
  const showSeatsSection =
    tripsFilter === MyTripsFilter.UPCOMING_TRIPS ||
    Boolean(seats?.seats?.length);
  const showSeatsInstr = tripsFilter === MyTripsFilter.UPCOMING_TRIPS;
  const passengerMap = {};

  passengers.alone.reduce((map, p) => {
    if (!map[p.person.id]) {
      map[p.person.id] = p.person;
    }
    return passengerMap;
  }, passengerMap);

  passengers.withLapInfants.reduce((map, p) => {
    if (!map[p.adult.person.id]) {
      map[p.adult.person.id] = p.adult.person;
    }
    return passengerMap;
  }, passengerMap);

  useEffect(() => {
    if (multicitySlice) {
      setIsMixedCabinClass(getIsMixedClass(multicitySlice));
    } else if (departure) {
      setIsMixedCabinClass(getIsMixedClass(departureSlice));
    } else if (!departure && returnSlice) {
      setIsMixedCabinClass(getIsMixedClass(returnSlice));
    }
  }, [flight]);

  const statusTagInfo = getStatusTag(
    flight,
    isMulticity ? multicitySliceIndex : departure ? 0 : 1,
    scheduleChange,
    airportMap,
    airlineMap as AirlineMap,
    isTF
  );

  return (
    <Box className="itinerary-summary-modal">
      {!!statusTagInfo && (
        <Box className="mobile-status-tag">
          <StatusTag tagInfo={statusTagInfo} />
        </Box>
      )}
      <Typography variant="subtitle1" className="itinerary-details-header">
        <span className="direction-label">{textConstants.OUTBOUND}</span>
        {textConstants
          .getItineraryDetailsHeader(
            flight,
            departure,
            airportMap,
            multicitySliceIndex
          )
          .replace("-", "")}
      </Typography>
      <FlightDetailsSummary
        showTitle={false}
        {...getItinerarySummaryProps(
          flight,
          departure,
          airportMap,
          airlineMap,
          multicitySliceIndex
        )}
        isMixedCabinClass={isMixedCabinClass}
        flightCombinationBanner={
          airlinesCountFlightItinerarySegment(slice!.segments) > 0 && (
            <FlightVICombinationBanner isMobile />
          )
        }
        missedConnectionGuarantee={
          isViMultiTicket(bookedItinerary.multiTicketType) && (
            <>
              <FlightMissedConnectionGuarantee
                travelItinerary={travelItinerary}
                isMobile
                onClick={() => {
                  trackEvent({
                    eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                    properties: {
                      entry_point: VirtualInterlineEntryPoint.TripDetails,
                    } as VirtualInterlineModalProperties,
                  });
                  setVariant("missedConnectionGuarantee");
                }}
              />
              <SelfTransferBanner
                isMobile
                iconName={IconName.BookTravel}
                onClick={() => {
                  trackEvent({
                    eventName: VIEWED_SELF_TRANSFER_MODAL,
                    properties: {
                      entry_point: VirtualInterlineEntryPoint.TripDetails,
                    } as VirtualInterlineModalProperties,
                  });
                  setVariant("selfCheck");
                }}
              />
            </>
          )
        }
      />
      {isMixedCabinClass && <MixedCabinToolTip showDivider={false} />}

      <Divider className="divider" />
      <Typography className="fare-details-header" variant="subtitle1">
        {textConstants.FARE_DETAILS}
      </Typography>

      <Box className="trip-itinerary-restrictions">
        {getRestrictionInfo(flight, isTF, slice, slice?.fareShelf?.brandName)
          .map(
            updateRestrictionWithAncillaries({
              ...flight.ancillaries,
              chfar: flight.chfar,
            })
          )
          .map((restriction, index) => (
            <Restriction
              key={`restriction-${index}`}
              name={restriction.name}
              descriptionString={restriction.description}
              symbol={restriction.symbol}
            />
          ))}
      </Box>
      {showSeatsSection && (
        <>
          <Divider className="divider" />
          <Box className="seat-selection">
            <Typography className="seat-selection-header" variant="subtitle1">
              {textConstants.SEAT_SELECTION}
            </Typography>
            {showSeatsInstr && (
              <SeatUpdateInstructions airlineMap={airlineMap} slice={slice} />
            )}
            {seats?.seats.length ? (
              <Box className="itinerary-modal-seat-selection">
                <SelectedSeatsConfirmation
                  outboundSeatSegments={getSeatSegments(
                    seats,
                    slice!.segments,
                    passengerMap,
                    departure
                  )}
                  outboundOriginCode={
                    departureSlice.segments[0]?.origin.locationCode
                  }
                  returnOriginCode={
                    returnSlice?.segments[0]?.origin.locationCode
                  }
                  airports={airportMap}
                />
              </Box>
            ) : null}
          </Box>
        </>
      )}
      {variant && (
        <VirtualIinterliningModal
          variant={variant}
          isMobile
          isOpen
          onClose={() => setVariant(false)}
        />
      )}
    </Box>
  );
};
