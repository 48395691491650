import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionButton,
  ActionLink,
  B2BSpinnerWithText,
  BannerSeverity,
  GenericModalContent,
  HotelSummaryPanel,
  Icon,
  IconName,
  ImportantInfoList,
  NotificationBanner,
} from "halifax";
import { partition } from "lodash";
import { RouteComponentProps } from "react-router";
import {
  CancelScenario,
  CancelScenarioEnum,
  Maybe,
  SelfServeEvents,
  CancelModalFlowStepEnum,
  PaymentLineItemTravelWalletEnum,
  CancelCopy,
  PackageItinerary,
} from "redmond";

import { PENDING_CANCELLATION } from "../../../FlightCard/constants";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import confirmHotelCancellation from "../../../../../../../../api/v1/itinerary/confirmHotelCancellation";
import getHotelCancellationInfo from "../../../../../../../../api/v1/itinerary/getHotelCancellationInfo";
import * as constants from "../../constants";
import { CancelPackageHotelModalContentConnectorProps } from "./container";
import {
  PATH_HOME,
  CONTACT_SUPPORT_URL,
} from "../../../../../../../../utils/paths";

import "./styles.scss";
import { useExperimentsById } from "@capone/experiments";

enum TravelOfferPaymentSplit {
  full = "Full",
  none = "None",
  partial = "Partial",
}

interface ICancelPackageHotelModalContentProps
  extends CancelPackageHotelModalContentConnectorProps,
    RouteComponentProps {
  packageItinerary: PackageItinerary;
  isMobile?: boolean;
}

const renderContactSupportButton = () => (
  <ActionButton
    className="b2b"
    defaultStyle="h4r-primary"
    message={constants.CONTACT_SUPPORT}
    onClick={() => {
      trackEvent({
        eventName: SelfServeEvents.ClickSupport,
        properties: {
          product_funnel: "package_hotel",
          lob: "package",
        },
      });

      window.open(CONTACT_SUPPORT_URL, "_blank")?.focus();
    }}
  />
);

const renderSuccessModal = (closeModal: () => void) => (
  <GenericModalContent
    className="hotel-cancel-modal-success"
    title={constants.CANCELLATION_SUCCESS_TITLE}
    subtitle={constants.CANCELLATION_SUCCESS_COPY}
    image={<Icon className="success-icon" name={IconName.Checked} />}
    actions={
      <ActionButton
        defaultStyle="h4r-primary"
        message={constants.CLOSE}
        onClick={closeModal}
      />
    }
  />
);

const renderContactSupport = (
  closeModal: () => void,
  title?: string,
  subtitle?: string[]
) => (
  <GenericModalContent
    className="hotel-cancel-modal-contact-support"
    title={title || ""}
    subtitle={subtitle || []}
    actions={
      <>
        {renderContactSupportButton()}
        <ActionButton
          defaultStyle="h4r-secondary"
          message={constants.CLOSE}
          onClick={closeModal}
        />
      </>
    }
  />
);

const renderTryAgainModal = (
  title: string,
  subtitle: string[],
  counter: number,
  tryAgain: () => void
) => (
  <GenericModalContent
    className="hotel-cancel-modal-try-again"
    title={title}
    subtitle={subtitle}
    actions={
      <>
        {counter >= 3 && renderContactSupportButton()}
        {counter < 3 && (
          <ActionButton
            defaultStyle="h4r-secondary"
            message={constants.TRY_AGAIN}
            onClick={tryAgain}
          />
        )}
      </>
    }
  />
);

const renderPendingModal = (closeModal: () => void) => (
  <GenericModalContent
    className="hotel-cancel-modal-try-again"
    title={constants.CANCELLATION_PENDING}
    subtitle={PENDING_CANCELLATION}
    actions={
      <>
        <ActionButton
          defaultStyle="h4r-secondary"
          message={constants.CLOSE}
          onClick={closeModal}
        />
      </>
    }
  />
);

export const CancelPackageHotelModalContent = (
  props: ICancelPackageHotelModalContentProps
) => {
  const { setOpenModal, packageItinerary, isMobile = false, history } = props;
  const hotel = packageItinerary.hotel.itinerary;
  const { paymentBreakdown } = packageItinerary;
  const [cancelScenario, setCancelScenario] =
    useState<Maybe<CancelScenario>>(null);
  const reservationBookingId = hotel?.reservation?.reservationBookingId;
  const [modalFlowStep, setModalFlowStep] = useState<CancelModalFlowStepEnum>(
    CancelModalFlowStepEnum.loading
  );
  const [tryAgain, setTryAgain] = useState<number>(0);
  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const travelOfferPaymentAmt = useMemo(() => {
    const payments = paymentBreakdown?.payments;
    const [travelOffers, nonTravelOffers] = partition(
      payments,
      (payment) =>
        "TravelWallet" in payment &&
        // delete line below to support TravelWalletCredit too
        payment.TravelWallet ===
          PaymentLineItemTravelWalletEnum.TravelWalletOffer
    );

    if (travelOffers.length) {
      if (nonTravelOffers.length) return TravelOfferPaymentSplit.partial;
      else return TravelOfferPaymentSplit.full;
    }

    return TravelOfferPaymentSplit.none;
  }, [hotel]);

  const closeModal = useCallback(
    () =>
      setOpenModal({
        type: null,
        selectedItinerary: null,
      }),
    [setOpenModal]
  );

  const goToConfirm = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ClickCancel,
      properties: {
        product_funnel: "package_hotel",
        lob: "package",
        ...hotel.reservation.trackingPropertiesV2.properties,
      },
      encryptedProperties: [
        hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
      ],
    });
    setModalFlowStep(CancelModalFlowStepEnum.confirm);
  }, []);

  const getPolicyInfo = useCallback(() => {
    setModalFlowStep(CancelModalFlowStepEnum.loading);
    getHotelCancellationInfo({
      reservationId: reservationBookingId,
    })
      .then((hotelPolicy) => {
        setCancelScenario(hotelPolicy);
        switch (hotelPolicy.HotelCancelScenario) {
          case CancelScenarioEnum.Pending:
          case CancelScenarioEnum.CancellationFailure:
            trackEvent({
              eventName: "viewed_hotel_change_error",
              properties: {
                ...hotel.reservation.trackingPropertiesV2?.properties,
              },
            });
            setModalFlowStep(CancelModalFlowStepEnum.pending);
            break;
          case CancelScenarioEnum.ContactCustomerService:
          case CancelScenarioEnum.FullyRefundableComplex:
          case CancelScenarioEnum.PartiallyRefundableComplex:
            setModalFlowStep(CancelModalFlowStepEnum.support);
            break;
          case CancelScenarioEnum.Canceled:
          case CancelScenarioEnum.NonRefundable:
          case CancelScenarioEnum.PartialRefund:
          case CancelScenarioEnum.FullRefund:
            setModalFlowStep(CancelModalFlowStepEnum.policy);
            break;
          default:
            setModalFlowStep(CancelModalFlowStepEnum.loading);
            break;
        }
      })
      .catch(() => {
        setModalFlowStep(CancelModalFlowStepEnum.tryAgain);
      });
  }, [reservationBookingId]);

  useEffect(() => {
    if (hotel) {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: {
          product_funnel: "package_hotel",
          lob: "package",
          ...hotel.reservation.trackingPropertiesV2.properties,
        },
        encryptedProperties: [
          hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
        ],
      });

      getPolicyInfo();
    }
  }, [hotel, getPolicyInfo]);

  const handleHotelCancel = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ConfirmCancellation,
      properties: {
        product_funnel: "package_hotel",
        lob: "package",
        ...hotel.reservation.trackingPropertiesV2.properties,
      },
      encryptedProperties: [
        hotel.reservation.trackingPropertiesV2.encryptedProperties ?? "",
      ],
    });

    setModalFlowStep(CancelModalFlowStepEnum.loading);
    if (cancelScenario && reservationBookingId) {
      confirmHotelCancellation(
        {
          reservationId: reservationBookingId,
          cancelScenario,
        },
        hotel
      )
        .then(() => {
          setModalFlowStep(CancelModalFlowStepEnum.success);
        })
        .catch(() => {
          setModalFlowStep(CancelModalFlowStepEnum.tryAgain);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelScenario, reservationBookingId]);

  const { cancelCopy, cancelConfirmationCopy, customerServiceCopy } =
    cancelScenario || {};
  const { reservation } = hotel || {};
  const { lodgingData, checkInDate, checkOutDate, bookedRooms } =
    reservation || {};

  const isMultiroomBooking = bookedRooms[0]?.count > 1 && isMultiroomAmadeus;

  switch (modalFlowStep) {
    case CancelModalFlowStepEnum.support:
      const cpy = customerServiceCopy || cancelCopy;
      return renderContactSupport(closeModal, cpy?.title, cpy?.body);
    case CancelModalFlowStepEnum.policy:
    case CancelModalFlowStepEnum.confirm:
      const isConfirm = modalFlowStep === CancelModalFlowStepEnum.confirm;
      const isNonRefund =
        cancelScenario?.HotelCancelScenario ===
        CancelScenarioEnum.NonRefundable;
      const copyObj: Partial<CancelCopy> = {
        ...(isConfirm ? cancelConfirmationCopy : cancelCopy),
      };

      if (!isNonRefund) {
        // overwrite copy if refundable and user paid with a travel offer
        if (travelOfferPaymentAmt === TravelOfferPaymentSplit.full) {
          copyObj.body = [
            isConfirm
              ? constants.CANCEL_HOTEL_FULL_TRAVEL_OFFER_CONFIRM
              : constants.CANCEL_HOTEL_FULL_TRAVEL_OFFER,
          ];
          copyObj.importantInfo = undefined; // Hide information section
        } else if (travelOfferPaymentAmt === TravelOfferPaymentSplit.partial) {
          if (!copyObj.body) copyObj.body = [];

          if (isConfirm) {
            copyObj.body = [
              constants.CANCEL_HOTEL_PARTIAL_TRAVEL_OFFER_CONFIRM,
            ];
          } else {
            // append to existing copy since it contains refund-by date & show as multiple paragraphs
            copyObj.body.push(
              "<br/><br />",
              constants.CANCEL_HOTEL_PARTIAL_TRAVEL_OFFER
            );
          }
        } else if (isMultiroomBooking) {
          copyObj.importantInfo = [
            ...(copyObj.importantInfo ?? []),
            constants.MULTIROOM_CANCEL_INFO,
          ];
        }
      }

      const subtitle = (
        <>
          {isMultiroomBooking && !isNonRefund && (
            <NotificationBanner
              className="multiroom-cancel-banner"
              content={
                <Typography className="label" variant="body1">
                  Proceeding will cancel <strong>all rooms</strong> on this
                  reservation. To cancel individual rooms on this reservation,
                  you must contact customer support by calling{" "}
                  <strong>844-422-6922</strong>.
                </Typography>
              }
              severity={BannerSeverity.WARNING}
            />
          )}
          {copyObj?.body && (
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: copyObj.body.join(" ") }}
            />
          )}
        </>
      );

      return (
        <GenericModalContent
          className="hotel-cancel-modal"
          title={copyObj?.title}
          subtitle={subtitle}
          content={
            <Box className="hotel-cancel-modal-content">
              <HotelSummaryPanel
                hideImage
                isMobile={isMobile}
                hideStarRating
                selectedLodging={{
                  lodging: lodgingData,
                  description: lodgingData.description,
                  isPreferred: false,
                  isFreeCancel: false,
                  isLuxuryCollection: false,
                }}
                checkIn={checkInDate ? dayjs(checkInDate).toDate() : null}
                checkOut={checkOutDate ? dayjs(checkOutDate).toDate() : null}
                roomInfo={bookedRooms[0].roomInfo}
                roomsCount={bookedRooms[0].count}
              />
              {!!copyObj?.importantInfo?.length ? (
                <ImportantInfoList
                  title="Cancellation Information"
                  infoItems={copyObj?.importantInfo}
                />
              ) : null}
            </Box>
          }
          actions={
            isNonRefund ? (
              <ActionLink
                className="hotel-change-modal-action-link"
                onClick={closeModal}
                content={constants.BACK_TO_MY_TRIPS}
              />
            ) : (
              <ActionButton
                className={clsx("hotel-cancel-modal-action-button", {
                  red: isConfirm,
                })}
                defaultStyle="h4r-primary"
                onClick={isConfirm ? handleHotelCancel : goToConfirm}
                message={
                  isConfirm ? constants.CONFIRM_CANCEL : constants.CANCEL_HOTEL
                }
              />
            )
          }
        />
      );
    case CancelModalFlowStepEnum.success:
      return renderSuccessModal(() => {
        closeModal();
        history.push(`${PATH_HOME}?tripId=${hotel.reservation.reservationId}`);
        window.location.reload();
      });
    case CancelModalFlowStepEnum.tryAgain:
      return renderTryAgainModal(
        "Something went wrong",
        ["try again"],
        tryAgain,
        () => {
          setTryAgain(tryAgain + 1);
          getPolicyInfo();
        }
      );
    case CancelModalFlowStepEnum.pending:
      return renderPendingModal(closeModal);
    default:
      return <B2BSpinnerWithText subtitle={constants.LOADING_HOTEL_POLICY} />;
  }
};
