export interface UtmParams {
  userSource: string | null;
  userMedium: string | null;
}

export const UTM_SOURCE_PARAM = "utm_source";
export const UTM_MEDIUM_PARAM = "utm_medium";
export const USER_SOURCE_KEY = "user_source";
export const USER_MEDIUM_KEY = "utm_medium";

export const getUrlWithUtmParams = (
  baseUrl: string,
  utmParams: UtmParams | null
): string => {
  if (!utmParams) {
    return baseUrl;
  }

  const { userSource, userMedium } = utmParams;
  const url = new URL(baseUrl, window.location.origin); // Ensure proper URL handling

  if (userSource) {
    url.searchParams.set(UTM_SOURCE_PARAM, userSource);
  }

  if (userMedium) {
    url.searchParams.set(UTM_MEDIUM_PARAM, userMedium);
  }

  return url.toString();
};
