// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".experience-travelers-modal.desktop-popover-card-root .MuiDialog-paper{width:500px}.experience-travelers-modal.desktop-popover-card-root .desktop-popover-card-content-container{min-height:fit-content}.va64sjjoSQRzWgW1oGhtrA\\=\\={padding:46px 50px}.va64sjjoSQRzWgW1oGhtrA\\=\\= .sHcunI9HSHLEAIiQc5Wjew\\=\\={padding:16px 0px;border-top:.5px solid var(--grey-11)}.va64sjjoSQRzWgW1oGhtrA\\=\\= .sHcunI9HSHLEAIiQc5Wjew\\=\\=:first-of-type{margin-top:16px}.va64sjjoSQRzWgW1oGhtrA\\=\\= .sHcunI9HSHLEAIiQc5Wjew\\=\\=:last-of-type{padding-bottom:0px}.va64sjjoSQRzWgW1oGhtrA\\=\\= .zh\\+9sbHpV7m5a\\+xfBuiFQg\\=\\={font-size:16px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/TripsList/components/ItineraryList/components/ItinerariesModal/components/ExperienceTravelersModalContent/styles.module.scss"],"names":[],"mappings":"AAGM,uEACE,WAAA,CAGF,8FACE,sBAAA,CAMR,4BACE,iBAAA,CAEA,wDACE,gBAAA,CACA,oCAAA,CAEA,sEACE,eAAA,CAGF,qEACE,kBAAA,CAIJ,0DACE,cAAA","sourcesContent":[":global {\n  .experience-travelers-modal {\n    &.desktop-popover-card-root {\n      .MuiDialog-paper {\n        width: 500px;\n      }\n  \n      .desktop-popover-card-content-container {\n        min-height: fit-content;\n      }\n    }\n  }\n}\n\n.modal-content-container {\n  padding: 46px 50px;\n\n  .traveler-name-age-container {\n    padding: 16px 0px;\n    border-top: 0.5px solid var(--grey-11);\n\n    &:first-of-type {\n      margin-top: 16px;\n    }\n\n    &:last-of-type {\n      padding-bottom: 0px;\n    }\n  }\n\n  .fullName {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content-container": "va64sjjoSQRzWgW1oGhtrA==",
	"traveler-name-age-container": "sHcunI9HSHLEAIiQc5Wjew==",
	"fullName": "zh+9sbHpV7m5a+xfBuiFQg=="
};
export default ___CSS_LOADER_EXPORT___;
