import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionButton, ActionLink, B2BSpinnerWithText, B2BTextField, GenericModalContent, Icon,
  IconName, IOption, MobileFloatingButton, SelectInput
} from "halifax";
import _ from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BookedFlightItineraryWithDepartureTime, CancelScenarioEnum, FlightCancelInfoResponse, IEditTravelerRequestInfo, IOpenModal, IPersonWithNameNumber,
  IRequestPassengerEdit,
  IRequestTravelerEditFormEnum, ItineraryEnum, ItineraryWithType, MultiTicketTypeEnum, MyTripsModalTypes, NonCfarEnum, SelfServeEvents
} from "redmond";

import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import getFlightCancellationInfoV3 from "../../../../../../../../api/v1/itinerary/getFlightCancellationInfoV3";
import requestTravelerEdits from "../../../../../../../../api/v1/user/requestTravelerEdits";
import {
  ISetOpenModal,
  setSelectedRequestPassengerEdit
} from "../../../../../../actions/actions";
import { getRequestPassengerEdit } from "../../../../../../reducer";
import * as constants from "../../constants";

import "./styles.scss";

const defaultProps = {};

export interface IRequestTravelerEditFormProps {
  passengers: IPersonWithNameNumber[];
  flight: BookedFlightItineraryWithDepartureTime;
  isMobile?: boolean;
  setOpenModal: (openModal: IOpenModal) => ISetOpenModal;
}

const getPassengerOption = (passenger: IPersonWithNameNumber) =>
  ({
    value: passenger.nameNumber,
    label: `${passenger.givenName} ${passenger.surname}`,
  } as IOption);

const RequestTravelerEditForm = ({
  passengers,
  isMobile,
  flight,
  setOpenModal,
}: IRequestTravelerEditFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const requestPassengerEdit = useSelector(getRequestPassengerEdit);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { traveler: initialTraveler, field: initialField } =
    requestPassengerEdit || { traveler: passengers[0] };
  const passengerOptions = passengers.map(getPassengerOption);
  const initialFieldConfig = initialField
    ? constants.REQUEST_EDIT_ALLOWED_FIELDS[initialField]
    : { initialValueFunc: () => "", propKey: "" };
  const initialValue =
    initialField && initialTraveler
      ? initialTraveler[initialFieldConfig.propKey]
      : "";
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;
  const isMultiTicket =
    bookedItinerary.multiTicketType !== MultiTicketTypeEnum.Single;
  const airlineCode = _.get(
    travelItinerary,
    ["slices", "0", "segments", "0", "marketingAirline", "code"],
    ""
  );
  const pnr = travelItinerary.locators?.agent.unscopedValue || "";
  const initialInputValue = initialFieldConfig
    ? initialFieldConfig.initialValueFunc(
        initialValue,
        initialTraveler,
        airlineCode
      )
    : initialValue;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isVoidable, setIsVoidable] = useState(false);
  const [edits, setEdits] = useState<IRequestPassengerEdit[]>([
    {
      traveler: initialTraveler,
      newValue: initialInputValue || "",
      field: initialField || IRequestTravelerEditFormEnum.frequentFlyerNumber,
    },
  ]);

  useEffect((): (() => void) => {
    getFlightCancellationInfoV3({ itineraryId: bookedItinerary.id })
      .then((cancelInfo: FlightCancelInfoResponse) => {
        if (
          cancelInfo.CancelScenario === CancelScenarioEnum.Pending ||
          cancelInfo.CancelScenario === CancelScenarioEnum.BookingPending ||
          cancelInfo.NonCfar === NonCfarEnum.TicketedVoid ||
          cancelInfo.NonCfar === NonCfarEnum.TicketedVoidUnknownWindow ||
          cancelInfo.NonCfar === NonCfarEnum.DelayedTicketingCancel ||
          cancelInfo.NonCfar === NonCfarEnum.TicketlessVoid
        ) {
          setIsVoidable(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => dispatch(setSelectedRequestPassengerEdit());
  }, []);

  const getAvailableFieldOptions = useCallback(
    (nameNumber: string, field?: IRequestTravelerEditFormEnum) => {
      const currentEdits = edits
        .filter((e1) => e1.traveler.nameNumber === nameNumber)
        .map((e2) => e2.field);

      return Object.keys(constants.REQUEST_EDIT_ALLOWED_FIELDS)
        .filter(
          (ef: IRequestTravelerEditFormEnum | string) =>
            ef === field ||
            !currentEdits.includes(ef as IRequestTravelerEditFormEnum)
        )
        .map(
          (f) =>
            ({
              label: constants.REQUEST_EDIT_ALLOWED_FIELDS[f].label,
              value: f,
            } as IOption)
        );
    },
    [edits]
  );

  const onSubmitTravelerEdits = useCallback(
    (editsSubmit: IEditTravelerRequestInfo[]) => {
      setSubmitting(true);
      trackEvent({
        eventName: SelfServeEvents.SubmitEditTravelerDetailModal,
        properties: {
          request_type: _.flatMap(editsSubmit, (edit) => Object.keys(edit)),
        },
      });

      return requestTravelerEdits({
        travelersInformation: editsSubmit,
        itineraryId: bookedItinerary.id,
        pnr,
      })
        .then((res: any) => {
          setSubmitting(false);
          if (res?.TravelerInfoResponse === "Success") {
            setOpenModal({
              type: MyTripsModalTypes.RequestTravelerEditSuccess,
              selectedItinerary: {
                type: ItineraryEnum.Flight,
              } as ItineraryWithType,
            });
          } else {
            setShowErrorModal(true);
          }
        })
        .catch(() => {
          setSubmitting(false);
          setShowErrorModal(true);
        });
    },
    []
  );

  const handleSubmit = useCallback(() => {
    const editsSubmission = _.chain(edits)
      .reduce((acc, edit) => {
        const { field, traveler, newValue } = edit;
        if (traveler) {
          const { nameNumber = "" } = traveler;

          acc[nameNumber] = acc[nameNumber] || {
            passengerNumberToModify: nameNumber,
          };

          if (
            field === IRequestTravelerEditFormEnum.specialAssistance &&
            newValue === constants.SPECIAL_ASSISTANCE_OPTIONS[0].value
          ) {
            acc[nameNumber][field || ""] = undefined;
          } else {
            acc[nameNumber][field || ""] = newValue;
          }
        }

        return acc;
      }, {})
      .map((value) => value)
      .filter((edit) => _.compact(Object.values(edit)).length > 1)
      .value();

    onSubmitTravelerEdits(editsSubmission);
  }, [edits]);

  const handleFieldChange = useCallback(
    (
      e: ChangeEvent<HTMLSelectElement>,
      index: number,
      newEdits?: IRequestPassengerEdit[]
    ) => {
      const updatedEdits = [...(newEdits || edits)];
      const changedEdit = updatedEdits[index];
      const newField = IRequestTravelerEditFormEnum[e.target.value];
      changedEdit.field = newField;
      const newFieldConfig = constants.REQUEST_EDIT_ALLOWED_FIELDS[newField];
      changedEdit.newValue = newFieldConfig.initialValueFunc(
        changedEdit.traveler[
          constants.REQUEST_EDIT_ALLOWED_FIELDS[newField].propKey
        ],
        changedEdit.traveler,
        airlineCode
      );
      setEdits(updatedEdits);
    },
    [edits]
  );

  const handlePersonChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
      const updatedEdits = [...edits];
      const newTraveler =
        passengers.find((pass) => pass.nameNumber === e.target.value) ||
        passengers[0];
      updatedEdits[index].traveler = newTraveler;
      const editedField = getAvailableFieldOptions(newTraveler.nameNumber!)[0]
        .value;
      updatedEdits[index].field = editedField;
      const { propKey, initialValueFunc } =
        constants.REQUEST_EDIT_ALLOWED_FIELDS[editedField];
      updatedEdits[index].newValue = initialValueFunc(
        newTraveler[propKey],
        newTraveler,
        airlineCode
      );
      setEdits(updatedEdits);
    },
    [edits]
  );

  const handleInputChange = useCallback(
    (value: string, index: number) => {
      const updatedEdits = [...edits];
      updatedEdits[index].newValue = value;
      setEdits(updatedEdits);
    },
    [edits]
  );

  const handleAddNewRow = useCallback(() => {
    const previousEdit = edits[edits.length - 1] || {
      traveler: initialTraveler,
    };

    const updatedEdits = [
      ...edits,
      {
        newValue: "",
        traveler: previousEdit.traveler,
        field: IRequestTravelerEditFormEnum.name,
      },
    ];

    const newField = getAvailableFieldOptions(
      previousEdit.traveler.nameNumber || ""
    )[0];
    handleFieldChange(
      { target: { value: newField.value } } as ChangeEvent<HTMLSelectElement>,
      updatedEdits.length - 1,
      updatedEdits
    );
  }, [edits]);

  const nameCorrectionError =
    (isVoidable || isMultiTicket) &&
    _.some(edits, (e) => e.field === IRequestTravelerEditFormEnum.name);
  const disableSubmit = !_.some(edits, (edit) =>
    Boolean(edit.traveler && edit.field && edit.newValue)
  );
  const getErrorLabel = useCallback(() => {
    let message = "";
    if (!nameCorrectionError) {
      return <></>;
    } else if (isVoidable) {
      message = constants.REQUEST_EDIT_VOIDABLE;
    } else if (isMultiTicket) {
      message = constants.REQUEST_EDIT_MULTI_TICKET;
    }
    return (
      <Typography variant="body1" className="warning">
        {message}
      </Typography>
    );
  }, [nameCorrectionError, isVoidable, isMultiTicket]);

  if (showErrorModal) {
    return (
      <GenericModalContent
        className="request-traveler-edit-modal-try-again"
        title={constants.SOMETHING_WENT_WRONG}
        subtitle={constants.PLEASE_TRY_AGAIN}
        actions={
          <ActionButton
            defaultStyle="h4r-secondary"
            message={constants.TRY_AGAIN}
            onClick={() => setShowErrorModal(false)}
          />
        }
      />
    );
  }

  if (loading || submitting) {
    return (
      <B2BSpinnerWithText
        title={
          submitting
            ? constants.SUBMITTING_REQUEST_EDIT
            : constants.GATHERING_PASSENGER_INFO
        }
        subtitle={submitting ? constants.SUBMITTING_REQUEST_EDIT_SUBTITLE : ""}
      />
    );
  }

  return (
    <Box
      className={clsx("request-traveler-edit-form-container", {
        mobile: isMobile,
      })}
    >
      <Box className="request-traveler-edit-form-header">
        <Typography variant="h6">
          {constants.REQUEST_TRAVELER_EDIT_TITLE}
        </Typography>
        <Typography
          variant="body2"
          className="request-traveler-edit-form-header-subtitle"
        >
          {isVoidable
            ? constants.REQUEST_EDIT_VOIDABLE_SUBTITLE(
                <Typography
                  variant="body2"
                  component="span"
                  className="request-edit-voidable-cancel-link"
                  onClick={() =>
                    setOpenModal({
                      type: MyTripsModalTypes.SelfServeCancelFlight,
                      selectedItinerary: {
                        ...flight,
                        type: ItineraryEnum.Flight,
                      } as unknown as ItineraryWithType,
                    } as IOpenModal)
                  }
                >
                  {constants.CANCEL_YOUR_BOOKING}
                </Typography>
              )
            : constants.REQUEST_TRAVELER_EDIT_SUBTITLE}
        </Typography>
      </Box>
      <Box className="request-traveler-edit-form">
        {edits.map((edit, i) => {
          const editConfig = constants.REQUEST_EDIT_ALLOWED_FIELDS[edit.field!];
          const selectedPerson =
            passengerOptions.find(
              (po) => po.value === edit.traveler.nameNumber
            ) || getPassengerOption(passengers[0]);
          const availableOpts = getAvailableFieldOptions(
            edit.traveler.nameNumber || "",
            edit.field
          );
          const chosenField = availableOpts.find(
            (ao) => ao.label === editConfig?.label
          );
          return (
            <Box
              className={clsx("request-traveler-edit-form-row", {
                mobile: isMobile,
              })}
              key={`${edit.traveler.nameNumber}${edit.field || "newRow"}`}
            >
              <SelectInput
                label=""
                className="request-traveler-edit-form-pasenger-select"
                value={selectedPerson.value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handlePersonChange(e, i)
                }
                options={passengerOptions}
              />
              <SelectInput
                label=""
                className="request-traveler-edit-form-field-select"
                value={chosenField?.value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleFieldChange(e, i)
                }
                options={availableOpts}
              />
              {editConfig.options ? (
                <SelectInput
                  label=""
                  containerClassName={clsx(
                    "request-traveler-edit-form-input-select",
                    { mobile: isMobile }
                  )}
                  className="request-traveler-edit-form-input-select"
                  value={edit.newValue}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    handleInputChange(e.target.value, i)
                  }
                  options={editConfig.options}
                />
              ) : (
                <B2BTextField
                  label={
                    isVoidable &&
                    edit.field === IRequestTravelerEditFormEnum.name
                      ? constants.PLEASE_CANCEL_PLACEHOLDER
                      : editConfig?.placeholder || ""
                  }
                  shrink
                  className={clsx("request-traveler-edit-form-input", {
                    mobile: isMobile,
                  })}
                  error={
                    nameCorrectionError &&
                    edit.field === IRequestTravelerEditFormEnum.name
                  }
                  onChange={(value: string) => handleInputChange(value, i)}
                  value={edit.newValue}
                  disabled={
                    nameCorrectionError &&
                    edit.field === IRequestTravelerEditFormEnum.name
                  }
                />
              )}
            </Box>
          );
        })}
        <Box className="request-traveler-edit-add-button-container">
          <ActionLink
            onClick={handleAddNewRow}
            content={
              <Box className="request-traveler-edit-add-button">
                <Icon name={IconName.PlusIcon} />
                <Typography variant="body2">Add Another Item</Typography>
              </Box>
            }
            disabled={
              !edits[edits.length - 1].field ||
              !edits[edits.length - 1].newValue ||
              nameCorrectionError
            }
          />
          {getErrorLabel()}
        </Box>
      </Box>
      {isMobile ? (
        <Box className="request-traveler-edit-form-floating-button-container">
          <MobileFloatingButton
            onClick={handleSubmit}
            disabled={nameCorrectionError || disableSubmit}
          >
            <Typography variant="h3">{constants.SUBMIT_REQUEST}</Typography>
          </MobileFloatingButton>
        </Box>
      ) : (
        <Box className="request-traveler-edit-form-footer">
          <ActionButton
            defaultStyle="h4r-primary"
            onClick={handleSubmit}
            message={constants.SUBMIT_REQUEST}
            disabled={nameCorrectionError || disableSubmit}
          />
        </Box>
      )}
    </Box>
  );
};
RequestTravelerEditForm.defaultProps = defaultProps;

export default RequestTravelerEditForm;
