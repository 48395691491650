// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C4UhDCH3YKCMsJLiwsNiSg\\=\\={padding:46px 50px}.C4UhDCH3YKCMsJLiwsNiSg\\=\\= h4{font-size:20px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/TripsList/components/ItineraryList/components/ItinerariesModal/components/ExperienceItineraryModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,iBAAA,CAEA,+BACE,cAAA","sourcesContent":[".modal-content-container {\n  padding: 46px 50px;\n\n  h4 {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content-container": "C4UhDCH3YKCMsJLiwsNiSg=="
};
export default ___CSS_LOADER_EXPORT___;
