import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import {
  LoadingPopup,
  B2BSpinner,
  useDeviceTypes,
  TravelSalesEventBanner,
} from "halifax";
import { useDispatch } from "react-redux";
import {
  CallState,
  MyTripsFilter,
  FlightItineraryState,
  HotelItineraryState,
  VIEWED_MY_TRIPS,
  TRIPS_LOADING_COMPLETE,
  CarReservationState,
  GetExerciseEligibilitiesRequestV1ByItineraryIds,
  GetExerciseEligibilitiesRequestV1Enum,
  HomesReservationState,
  PackageItineraryState,
  ExperienceReservationState,
} from "redmond";
import clsx from "clsx";
import dayjs from "dayjs";
import { isCorpTenant } from "@capone/common";
import { TripsListConnectorProps } from "./container";

import "./styles.scss";
import * as textConstants from "./constants";
import { WatchList } from "./components/WatchList";
import { ItineraryList, TravelCreditsList } from "./components/ItineraryList";
import { NoTripResults } from "./components/NoResults";
import { MobileFlightItineraryDetails } from "./components/ItineraryList/components/MobileFlightItineraryDetails";
import { MobileHotelItineraryDetails } from "./components/ItineraryList/components/MobileHotelItineraryDetails";
import { MobileCarItineraryDetails } from "./components/ItineraryList/components/MobileCarItineraryDetails";
import { DesktopFilterTabs } from "./components/DesktopFilterTabs";
import { MobileFilterDropdown } from "./components/MobileFilterDropdown";
import { ItinerariesModal } from "./components/ItineraryList/components/ItinerariesModal";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { PriceFreezeList } from "./components/PriceFreezeList";
import { MyTripsHeader } from "../common";
import { usePrevious } from "../../hooks/usePrevious";
import { PORTAL_TITLE, MY_TRIPS_TITLE } from "../../lang/textConstants";
import { fetchUserPassengers } from "../../modules/passengers/reducer";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  ActiveExperiments,
  TRAVEL_SALE_VARIANTS,
  CONTROL,
  TRAVEL_SALE_ACTIVE,
  getExperimentVariant,
  AVAILABLE,
} from "../../context/experiments";
import { PATH_TRAVEL_SALE } from "../../utils/paths";
import { MobileHomeItineraryDetails } from "./components/ItineraryList/components/MobileHomeItineraryDetails";
import { config } from "../../api/config";
import { PendingTripList } from "./components/capone-corporate/PendingTripList";
import { TripsTabTitle } from "./components/ItineraryList/components/TripsTabTitle";
import { MobilePackageHotelDetails } from "./components/ItineraryList/components/MobilePackageHotelDetails";
import { MobilePackageFlightDetails } from "./components/ItineraryList/components/MobilePackageFlightDetails";
import { MobileExperienceReservationDetails } from "./components/ItineraryList/components/MobileExperienceReservationDetails";

export interface ITripsListProps
  extends TripsListConnectorProps,
    RouteComponentProps {}

export const TripsList = ({
  fetchCars,
  fetchFlights,
  fetchHotels,
  fetchFlightDisruptions,
  history,
  listWatches,
  selectedFlight,
  selectedHotel,
  selectedCar,
  tripsFilter,
  areTripsLoading,
  hasError,
  hasTripsToDisplay,
  openModal,
  activeCfarCount,
  listPriceFreeze,
  listHotelPriceFreeze,
  partialFlightsListDpExerciseFactsProperties,
  partialEligibilityDpExerciseFactsProperties,
  fetchFlightDisruptionsCallState,
  tripsCounts,
  dpAttachedFlightItineraryIds,
  fetchHomes,
  selectedHome,
  listPaymentMethods,
  setTripSearchQuery,
  fetchPackages,
  selectedPackageHotel,
  selectedPackageFlight,
  fetchExperiences,
  fetchRedeemedEarnOffers,
  selectedExperience,
}: ITripsListProps) => {
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();

  const expState = useExperiments();

  const [fetchAll, setFetchAll] = useState(true);
  const [localSearchQuery, setLocalSearchQuery] = useState("");

  const isPostBookingOfferMyTripsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        ActiveExperiments.POST_BOOKING_OFFER_MY_TRIPS
      ) === AVAILABLE,
    [expState.experiments]
  );

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isExperiencesEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        ActiveExperiments.EXPERIENCES_EXPERIMENT
      ) === AVAILABLE,
    [expState.experiments]
  );

  useEffect(() => {
    document.title = MY_TRIPS_TITLE;
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUserPassengers());
    trackEvent({
      eventName: VIEWED_MY_TRIPS,
      properties: {},
    });
  }, []);

  const fetchAllItineraries = () => {
    fetchCars(
      {
        states: [CarReservationState.Present, CarReservationState.Future],
        asOf: new Date().toISOString(),
      },
      history
    );

    fetchCars(
      {
        states: [CarReservationState.Canceled, CarReservationState.Past],
        asOf: new Date().toISOString(),
      },
      history
    );
    fetchFlights(
      {
        states: [FlightItineraryState.Present, FlightItineraryState.Future],
        referenceDateTime: dayjs().format(),
      },
      history,
      isPostBookingOfferMyTripsEnabled
    );
    fetchFlights(
      {
        states: [FlightItineraryState.Canceled, FlightItineraryState.Past],
        referenceDateTime: dayjs().format(),
      },
      history
    );
    fetchHotels(
      {
        states: [HotelItineraryState.Present, HotelItineraryState.Future],
        referenceDateTime: dayjs().format(),
      },
      history
    );

    fetchHotels(
      {
        states: [HotelItineraryState.Canceled, HotelItineraryState.Past],
        referenceDateTime: dayjs().format(),
      },
      history,
      /*
        CancellationFailure hotels come through HotelItineraryState.Future, which should be shown in past trips;
        see https://hopchat.slack.com/archives/C02LKB2MVFY/p1679514821365839?thread_ts=1679512218.419669&cid=C02LKB2MVFY
      */
      [HotelItineraryState.Canceled]
    );

    fetchHomes(
      {
        states: [
          HomesReservationState.Present,
          HomesReservationState.Future,
          HomesReservationState.Canceled,
          HomesReservationState.Past,
        ],
        referenceDateTime: dayjs().format(),
      },
      history
    );
    fetchPackages(
      {
        states: [
          PackageItineraryState.Present,
          PackageItineraryState.Future,
          PackageItineraryState.Canceled,
          PackageItineraryState.Past,
        ],
        referenceDateTime: dayjs().format(),
      },
      history
    );

    fetchRedeemedEarnOffers();

    if (isExperiencesEnabled) {
      fetchExperiences(
        {
          states: [
            ExperienceReservationState.Present,
            ExperienceReservationState.Future,
            ExperienceReservationState.Canceled,
            ExperienceReservationState.Past,
          ],
          referenceDateTime: dayjs().format(),
        },
        history
      );
    }
  };

  useEffect(() => {
    if (!fetchAll) return;
    if (![CallState.Success, CallState.Failed].includes(expState.callState))
      return;
    setFetchAll(false);
    fetchAllItineraries();
    listWatches();
    listPriceFreeze();
    listPaymentMethods();
  }, [expState.callState, fetchAll]);

  useEffect(() => {
    listHotelPriceFreeze();
  }, []);

  useEffect(() => {
    if (dpAttachedFlightItineraryIds.length > 0) {
      fetchFlightDisruptions({
        itineraryIds: dpAttachedFlightItineraryIds,
        GetExerciseEligibilitiesRequestV1:
          GetExerciseEligibilitiesRequestV1Enum.ByItineraryIds,
      } as GetExerciseEligibilitiesRequestV1ByItineraryIds);
    }
    // JSON.stringify is needed to only compare the array content, not reference
    // since the array reference is "different" with every creation.
    // The operation should be quick given array's small size.
  }, [JSON.stringify(dpAttachedFlightItineraryIds)]);

  const previousAreTripsLoading = usePrevious(areTripsLoading);

  useEffect(() => {
    // Fire an event with certain trip info ONLY WHEN all the trips loading is complete.
    if (
      previousAreTripsLoading &&
      !areTripsLoading &&
      fetchFlightDisruptionsCallState !== CallState.InProcess
    ) {
      trackEvent({
        eventName: TRIPS_LOADING_COMPLETE,
        properties: {
          ...partialFlightsListDpExerciseFactsProperties,
          ...partialEligibilityDpExerciseFactsProperties,
          cfar_active: activeCfarCount,
        },
      });
    }
  }, [areTripsLoading, fetchFlightDisruptionsCallState]);

  const renderFilteredList = () => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.TRIPS_WITH_OFFERS:
        return (
          <ItineraryList
            isMobile={matchesMobile}
            showDisruptionProtectionElements
          />
        );
      case MyTripsFilter.WATCHED_TRIPS:
        return <WatchList isMobile={matchesMobile} />;
      case MyTripsFilter.TRAVEL_CREDITS:
        return <TravelCreditsList isMobile={matchesMobile} />;
      case MyTripsFilter.PRIZE_FREEZES:
        return <PriceFreezeList isMobile={matchesMobile} />;
      case MyTripsFilter.TRIP_REQUESTS:
        return <PendingTripList isMobile={matchesMobile} />;
      default:
        return null;
    }
  };

  return matchesMobile &&
    (selectedFlight ||
      selectedHotel ||
      selectedCar ||
      selectedHome ||
      selectedPackageHotel ||
      selectedPackageFlight ||
      selectedExperience) ? (
    <>
      {selectedFlight && (
        <MobileFlightItineraryDetails showDisruptionProtectionElements />
      )}
      {selectedHotel && <MobileHotelItineraryDetails />}
      {selectedCar && <MobileCarItineraryDetails />}
      {selectedHome && <MobileHomeItineraryDetails />}
      {selectedPackageHotel && <MobilePackageHotelDetails />}
      {selectedPackageFlight && <MobilePackageFlightDetails />}
      {selectedExperience && <MobileExperienceReservationDetails />}

      <ItinerariesModal isMobile={matchesMobile} />
    </>
  ) : (
    <Box
      className={clsx({ mobile: matchesMobile }, "trips-list", config.TENANT)}
    >
      <MyTripsHeader history={history} useSecondaryHeader />
      {matchesMobile ? <MobileFilterDropdown /> : <DesktopFilterTabs />}
      {matchesMobile && travelSaleVariant !== CONTROL && (
        <TravelSalesEventBanner
          variant={
            travelSaleVariant === TRAVEL_SALE_ACTIVE ? "default" : "with-timer"
          }
          onClick={() => {
            history.push(`${PATH_TRAVEL_SALE}?entryType=my_trips`);
          }}
          subtitle={
            travelSaleVariant === TRAVEL_SALE_ACTIVE
              ? textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE
              : textConstants.TRAVEL_SALES_EVENT_LEADUP_SUBTITLE
          }
          buttonText={
            travelSaleVariant! === TRAVEL_SALE_ACTIVE
              ? textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA
              : textConstants.TRAVEL_SALES_EVENT_LEADUP_CTA
          }
          isMobile
        />
      )}
      <div
        id={`${tripsFilter}-tab`}
        role="tabpanel"
        aria-labelledby={tripsFilter}
        className="tab-content"
      >
        {areTripsLoading && (
          <LoadingPopup
            open={areTripsLoading || areTripsLoading === null}
            indicator={B2BSpinner}
            message={textConstants.LOADING_TRIPS}
            classes={[
              "trips-loading-modal",
              ...(matchesMobile ? ["mobile"] : []),
            ]}
          />
        )}
        {(hasTripsToDisplay || isCorpTenant(config.TENANT)) && (
          <TripsTabTitle
            localSearchQuery={localSearchQuery}
            setLocalSearchQuery={setLocalSearchQuery}
            setTripSearchQuery={setTripSearchQuery}
            isMobile={matchesMobile}
            {...textConstants.getTabTitles(tripsFilter, tripsCounts)}
          />
        )}
        {!areTripsLoading && (
          <NoTripResults
            isMobile={matchesMobile}
            hasFilterSearchQuery={localSearchQuery.length > 0}
            clearFilterSearchQuery={() => {
              setLocalSearchQuery("");
              setTripSearchQuery("");
            }}
          />
        )}

        {(hasTripsToDisplay || (!hasError && openModal)) &&
          renderFilteredList()}
      </div>
    </Box>
  );
};
