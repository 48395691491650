import { getConfigTenant, isCaponeTenant } from "@capone/common";
import { useMemo } from "react";
import { PolicyTier } from "redmond";

export const useShowPolicyBanner = (policies?: PolicyTier) => {
  if (isCaponeTenant(getConfigTenant())) return false;

  const isDefaultHotelPoilicy = useMemo(
    () => isDefaultHotelPolicySelected(policies),
    [policies]
  );
  const isDefaultCarPoilicy = useMemo(
    () => isDefaultCarPolicySelected(policies),
    [policies]
  );
  const isDefaultFlightPoilicy = useMemo(
    () => isDefaultFlightPolicySelected(policies),
    [policies]
  );

  const shouldHidePolicy =
    isDefaultHotelPoilicy && isDefaultCarPoilicy && isDefaultFlightPoilicy;

  return !shouldHidePolicy;
};

const isDefaultHotelPolicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.hotels.policies.every((policy) => {
      const isAllStarRatingsAllowed = policy.allowedStarRatings.length === 5;
      const noMaxPricePolicy = policy.maxPricePerNight === undefined;
      return isAllStarRatingsAllowed && noMaxPricePolicy;
    })
  );
};

const isDefaultCarPolicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.cars.policies.every((policy) => {
      const noMaxPricePolicy = policy.maxPricePerDay === undefined;
      return noMaxPricePolicy;
    })
  );
};

const isDefaultFlightPolicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.flights.enableLongFlights &&
    policyTier.flights.policies.every((policy) => {
      const noMaxPrice =
        policy.maxPriceForOneWay == undefined &&
        policy.maxPriceForRoundTrip == undefined;
      const allFareClassesIncluded = policy.allowedFareClasses.length == 5;
      return noMaxPrice && allFareClassesIncluded;
    })
  );
};
