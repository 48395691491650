import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLinks,
  BannerSeverity,
  IActionLink,
  Icon,
  IconName,
  NotificationBanner,
  StatusTag,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  FiatPrice,
  HomesReservationStatus,
  MyTripsFilter,
  MyTripsModalTypes,
  TagColors,
} from "redmond";

import { ConfirmationSummary } from "../ConfirmationSummary";

import * as textConstants from "../ExperienceCard/constants";
import { MobileActionLinks } from "../MobileActionLinks";
import { SummaryCard } from "../SummaryCard";
import { MobileExperienceReservationDetailsConnectorProps } from "./container";

import "./styles.scss";
import { TravelersSummary } from "../TravelersSummary";
import { PaymentSummary } from "../PaymentSummary";
import { getCurrencyString } from "../../../../../../utils/helpers";
import { ExperienceCardContent } from "../ExperienceCard/components/ExperienceCardContent";
import { addExperienceType } from "../ExperienceCard/components";
import { ExperienceCancellationPolicyCard } from "../ExperienceCard";

export interface IMobileExperienceReservationDetailsProps
  extends RouteComponentProps,
    MobileExperienceReservationDetailsConnectorProps {}

export const MobileExperienceReservationDetails = ({
  experienceReservation,
  history,
  setOpenModal,
  tripsFilter,
  setSelectedExperience,
}: IMobileExperienceReservationDetailsProps) => {
  if (!experienceReservation) {
    return null;
  }

  const { title, confirmationCode, subtitle, supplierName, phone, email } =
    textConstants.getExperienceInfoDetails(experienceReservation);

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addExperienceType(experienceReservation),
    });
  const { reservation, paymentBreakdown } = experienceReservation;
  const {
    experienceDetails,
    status,
    bookingDetails,
    bookingSession,
    ticketInfo,
  } = reservation;
  const { supplierInfo } = experienceDetails || {};

  const travelerNames = textConstants.getTravelerNames(bookingDetails);

  const isCancelled = status === HomesReservationStatus.Cancelled;
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // render at the top of the page

  if (!isCancelled && !isPastTrips) {
    actions.push({
      content: textConstants.CANCEL_EXPERIENCE,
      onClick: () => onOpenModal(MyTripsModalTypes.CancelExperience),
    });
  }

  actions.push({
    content: textConstants.RESEND_CONFIRMATION,
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  if (ticketInfo?.url) {
    actions.push({
      content: textConstants.SHOW_VOUCHER,
      onClick: () => {
        window.open(ticketInfo.url, "_blank");
      },
    });
  }

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedExperience(null);
          }}
        />
        <Box className="experience-header-info">
          <Typography variant="subtitle2" className="name">
            {subtitle}
          </Typography>
          <Typography variant="caption">By {supplierInfo?.name}</Typography>
        </Box>
      </Box>
      {isCancelled && (
        <NotificationBanner
          className="status-banner"
          icon={<Icon name={IconName.ErrorAlert} />}
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
        />
      )}
      {topActions.length ? <ActionLinks actions={topActions} /> : null}
      <Box
        className={clsx("mobile-trip-card", "mobile-experience-summary-card")}
      >
        {isCancelled && (
          <StatusTag
            className="title-status-tag"
            tagInfo={{
              label: textConstants.CANCELED,
              type: TagColors.RED,
            }}
          />
        )}
        <Box className="titles-and-confirmation-info">
          {subtitle && (
            <Typography variant="body1" className="trips-subtitle">
              {subtitle}
            </Typography>
          )}
          <Typography variant="body2" className="trips-title">
            {title}
          </Typography>
          <Box className="info-container">
            <Typography className="label" variant="caption">
              {textConstants.CONFIRMATION}
            </Typography>
            <Typography
              className={clsx("code", { warning: isCancelled })}
              variant="caption"
            >
              {confirmationCode}
            </Typography>
          </Box>
        </Box>
      </Box>
      <SummaryCard className="mobile-trip-card mobile-location-details">
        <ExperienceCardContent
          reservation={reservation}
          onOpenModal={(type: MyTripsModalTypes) => {
            setOpenModal({
              type,
              selectedItinerary: addExperienceType(experienceReservation),
            });
          }}
          isMobile
        />
        <Box className={clsx("details-container", "supplier-info")}>
          {supplierName && (
            <Typography variant="body1" className="name">
              {supplierName}
            </Typography>
          )}
          {phone && (
            <Typography variant="caption" className="phone">
              {phone}
            </Typography>
          )}
          {email && (
            <Typography variant="caption" className="email">
              {email}
            </Typography>
          )}
        </Box>
      </SummaryCard>

      {reservation.cancellationPolicy && (
        <SummaryCard className="mobile-trip-card">
          <ExperienceCancellationPolicyCard
            cancellationPolicy={reservation.cancellationPolicy}
            onOpenModal={onOpenModal}
          />
        </SummaryCard>
      )}
      <SummaryCard
        className="mobile-trip-card"
        action={
          travelerNames.length > 1 ? (
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
              icon={faChevronRight}
            />
          ) : undefined
        }
      >
        <TravelersSummary
          label={
            travelerNames.length > 1
              ? textConstants.TRAVELERS(travelerNames)
              : textConstants.TRAVELER
          }
          travelers={travelerNames.join(", ")}
        />
      </SummaryCard>
      <SummaryCard
        className="mobile-payment-summary mobile-trip-card"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
            icon={faChevronRight}
          />
        }
      >
        <PaymentSummary
          tripTotalAmount={getCurrencyString(
            bookingSession?.totalSellAtPriceWithFees?.fiat as FiatPrice
          )}
          cardLabel={
            textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
              .cardLabel
          }
          cardAmount={textConstants.getValues(paymentBreakdown).cardValue}
          rewardsLabel={
            textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
              .rewardLabel
          }
          rewardsAmount={textConstants.getValues(paymentBreakdown).rewardValue}
          showCardLabel={Boolean(
            textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
              .cardLabel
          )}
          showRewardsLabel={Boolean(
            textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
              .rewardLabel
          )}
        />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card confirmation-summary">
        <ConfirmationSummary confirmationCode={confirmationCode} />
      </SummaryCard>
      <MobileActionLinks actions={actions} />
    </Box>
  );
};
