import {
  B2BSpinner,
  GenericInfoPopup,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { IGenericInfoButtonProps } from "halifax/build/GenericInfoPopup";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CallState,
  MODAL_ALERT,
  DisruptionFlightRefundResult,
  SubmitDisruptionRefundRequest,
} from "redmond";
import { pushToTripsHome } from "../../../../utils/queryStringHelpers";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { DisruptionProtectionRefundStatusPopupConnectorProps } from "./container";
import * as textConstants from "./constants";
import { Typography } from "@material-ui/core";
import "./styles.scss";
import Logger from "../../../../utils/logger";

export interface IDisruptionProtectionRefundStatusPopupProps
  extends DisruptionProtectionRefundStatusPopupConnectorProps,
    RouteComponentProps {}

enum DisruptionRefundVisiblePopupState {
  Loading,
  Success,
  Failure,
  None,
}

export const DisruptionProtectionRefundStatusPopup = (
  props: IDisruptionProtectionRefundStatusPopupProps
) => {
  const { matchesMobile } = useDeviceTypes();
  const {
    submitDisruptionFlightRefundCallState,
    disruptionFlightRefundResult,
    disruptedItinerary,
    prepareRefundInfo,
    eligibilityDpExerciseProductRedeemChoice,
    disruptionOverviewEligibilityDpExerciseFactsProperties,
    submitDisruptionFlightRefund,
    history,
    isFintechCsatEnabled,
  } = props;

  const [didUserCloseFailurePopup, setDidUserCloseFailurePopup] =
    useState<boolean>(false);

  const handleTrackRefundFailedModalAlert = () => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: {
        modal_type: "RefundFailed",
        screen: "disruption_refund",
        description: "disruption_refund_error",
        active_disruption:
          disruptionOverviewEligibilityDpExerciseFactsProperties?.active_disruption,
        product_redeem_choice:
          eligibilityDpExerciseProductRedeemChoice?.product_redeem_choice,
      },
    });
  };

  const renderCsatSurvey = () => {
    if (isFintechCsatEnabled) {
      (window as any).cempDataLayer["pageName"] = "FDA_REFUND";
      const SURVEY_ID =
        window.__mclean_env__.ENV === "production"
          ? "capitalonetravel-flightdisruption"
          : "capitalonetravel-flightdisruption-test";
      return <div id={SURVEY_ID} />;
    }
    return <></>;
  };

  useEffect(() => {
    switch (submitDisruptionFlightRefundCallState) {
      case CallState.InProcess:
        setDidUserCloseFailurePopup(false);
        break;
      case CallState.Failed:
        handleTrackRefundFailedModalAlert();
        break;
      case CallState.Success:
        break;
      default:
        break;
    }
  }, [submitDisruptionFlightRefundCallState]);

  if (submitDisruptionFlightRefundCallState === CallState.NotCalled) {
    return null;
  }

  const isLoading =
    submitDisruptionFlightRefundCallState === CallState.InProcess;

  const isSuccess =
    submitDisruptionFlightRefundCallState === CallState.Success &&
    disruptionFlightRefundResult === DisruptionFlightRefundResult.Success;

  const isFailure =
    submitDisruptionFlightRefundCallState === CallState.Failed ||
    (submitDisruptionFlightRefundCallState === CallState.Success &&
      disruptionFlightRefundResult === DisruptionFlightRefundResult.Failure);

  const visiblePopupState = isLoading
    ? DisruptionRefundVisiblePopupState.Loading
    : isSuccess
    ? DisruptionRefundVisiblePopupState.Success
    : isFailure
    ? DisruptionRefundVisiblePopupState.Failure
    : DisruptionRefundVisiblePopupState.None;

  const loadingPopup = (
    <GenericInfoPopup
      open={true}
      className="disruption-refund-in-progress-popup"
      image={<B2BSpinner />}
      title={textConstants.REFUND_IN_PROGRESS_TITLE}
      subtitle={textConstants.REFUND_IN_PROGRESS_SUBTITLE}
      isMobile={matchesMobile}
    />
  );

  const successButtonAction = () => {
    pushToTripsHome({ history });
  };

  const successButtons: IGenericInfoButtonProps[] = [
    {
      buttonText: textConstants.REFUND_SUCCESS_BUTTON_DONE,
      onClick: successButtonAction,
      defaultStyle: "h4r-primary",
    },
  ];

  const SurveyContent = () => {
    useEffect(() => {
      try {
        const wdw = window as any;
        if (
          wdw.cempDigital &&
          typeof wdw.cempDigital.updatePageView === "function"
        ) {
          wdw.cempDigital.updatePageView();
        }
      } catch (e) {
        Logger.debug(e);
      }
    }, []);

    return <div>{renderCsatSurvey()}</div>;
  };

  const SuccessModal = () => {
    return (
      <GenericInfoPopup
        open={true}
        buttons={successButtons}
        className="disruption-refund-success-popup"
        image={
          <Icon
            className="disruption-refund-success-icon"
            name={IconName.CheckCircleBorder}
          />
        }
        isMobile={matchesMobile}
        title={textConstants.REFUND_SUCCESS_TITLE}
        subtitle={textConstants.REFUND_SUCCESS_SUBTITLE}
        onClose={successButtonAction}
        showCloseButton={false}
      >
        <SurveyContent />
      </GenericInfoPopup>
    );
  };

  const failureButtonAction = () => {
    submitDisruptionFlightRefund({
      itineraryId: disruptedItinerary?.bookedItinerary.id,
      preparedPayment: prepareRefundInfo?.preparedPayment,
    } as SubmitDisruptionRefundRequest);
  };

  const failureButtons: IGenericInfoButtonProps[] = [
    {
      buttonText: textConstants.REFUND_FAILURE_BUTTON_TRY_AGAIN,
      onClick: failureButtonAction,
      defaultStyle: "h4r-primary",
    },
  ];

  const failurePopup = (
    <GenericInfoPopup
      open={true}
      buttons={failureButtons}
      className="disruption-refund-failure-popup"
      image={
        <Icon
          className="disruption-refund-failure-icon"
          name={IconName.ErrorState}
        />
      }
      isMobile={matchesMobile}
      title={textConstants.REFUND_FAILURE_TITLE}
      subtitle={
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: textConstants.REFUND_FAILURE_SUBTITLE,
          }}
        />
      }
      onClose={() => {
        setDidUserCloseFailurePopup(true);
      }}
      showCloseButton={true}
    />
  );

  if (visiblePopupState === DisruptionRefundVisiblePopupState.Loading) {
    return <>{loadingPopup}</>;
  }
  if (visiblePopupState === DisruptionRefundVisiblePopupState.Success) {
    return <SuccessModal />;
  }
  if (
    visiblePopupState === DisruptionRefundVisiblePopupState.Failure &&
    !didUserCloseFailurePopup
  ) {
    return <>{failurePopup}</>;
  }

  return null;
};
