import axios from "axios";
import {
  ExperienceReservationRequest,
  ExperienceReservationResponse,
} from "redmond";

import { config } from "../../config";
import { experienceItinerariesApiPrefix } from "../paths";

export const fetchExperiences = async (
  req: ExperienceReservationRequest
): Promise<ExperienceReservationResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(`${experienceItinerariesApiPrefix}`, req, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
