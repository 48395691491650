// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9yvL0dKiXQy0SeF-NyZclw\\=\\={display:flex;flex-direction:column;gap:10px}._9yvL0dKiXQy0SeF-NyZclw\\=\\= ._6Ho\\+a6q6k1qt-fq6E\\+97fw\\=\\={font-weight:600;font-size:14px;width:fit-content}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/TripsList/components/ItineraryList/components/ExperienceCard/components/ExperienceCancellationPolicyCard/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,4DACE,eAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".experience-cancellation-policy-container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  .action-link {\n    font-weight: 600;\n    font-size: 14px;\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experience-cancellation-policy-container": "_9yvL0dKiXQy0SeF-NyZclw==",
	"action-link": "_6Ho+a6q6k1qt-fq6E+97fw=="
};
export default ___CSS_LOADER_EXPORT___;
