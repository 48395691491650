import axios from "axios";
import { PollCancelFulfillRequest, PollCancelFulfillResponse } from "redmond";
import { config } from "../../config";
import { getExperiencesCancelFulfillPollPath } from "../paths";

const getExperiencesCancelFulfillPoll = (
  req: PollCancelFulfillRequest
): Promise<PollCancelFulfillResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(getExperiencesCancelFulfillPollPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getExperiencesCancelFulfillPoll;
