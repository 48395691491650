import { all } from "redux-saga/effects";

import { sagas as passengers } from "../modules/passengers";
import { sagas as rewards } from "../modules/rewards";
import { sagas as tripsList } from "../pages/TripsList";
import { sagas as disruptionProtections } from "../pages/DisruptionProtection";
import { sagas as myTripsTravelWallet } from "../modules/travel-wallet";
import { sagas as priceFreeze } from "../pages/PriceFreeze";

export function* rootSaga() {
  yield all([
    tripsList.watchFetchCarsSaga(),
    tripsList.watchFetchFlightsSaga(),
    tripsList.watchFetchHotelsSaga(),
    tripsList.watchFetchHomesSaga(),
    tripsList.watchFetchPackagesSaga(),
    tripsList.watchFetchExperiencesSaga(),
    tripsList.watchFetchPendingRequestsSaga(),
    tripsList.watchListWatches(),
    tripsList.watchListPriceFreeze(),
    tripsList.watchListHotelPriceFreeze(),
    tripsList.watchPopulateTripQueryParams(),
    tripsList.watchUpdateWatch(),
    tripsList.watchDeleteWatch(),
    tripsList.watchAcceptScheduleChange(),
    tripsList.watchDenyScheduleChange(),
    tripsList.watchFetchFlightCfarCancellationInfoV3Saga(),
    tripsList.watchConfirmFlightCfarCancellationSaga(),
    tripsList.watchFetchCrossSellHotelAvailability(),
    tripsList.watchFetchHotelCfarCancellationPolicySaga(),
    tripsList.watchConfirmHotelCfarCancellationSaga(),
    tripsList.watchListPaymentMethodsSaga(),
    tripsList.watchFetchActiveHotelCrossSell(),
    rewards.watchFetchRewardsAccounts(),
    passengers.watchFetchUserPassengers(),
    disruptionProtections.watchFetchFlightDisruptionsSaga(),
    disruptionProtections.watchFetchDisruptionPreparedFlightRefundSaga(),
    disruptionProtections.watchSubmitDisruptionFlightRefundSaga(),
    myTripsTravelWallet.watchFetchTravelWalletDetails(),
    myTripsTravelWallet.watchFetchRedeemedEarnOffers(),
    priceFreeze.watchFetchHotelPriceFreezeDetailsSaga(),
    priceFreeze.watchRefundHotelPriceFreezeSaga(),
  ]);
}
