import axios from "axios";
import { PollCancelQuoteRequest, PollCancelQuoteResponse } from "redmond";
import { config } from "../../config";
import { getExperiencesCancelQuotePollPath } from "../paths";

const getExperiencesCancelQuotePoll = (
  req: PollCancelQuoteRequest
): Promise<PollCancelQuoteResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(getExperiencesCancelQuotePollPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getExperiencesCancelQuotePoll;
