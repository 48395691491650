import {
  BookedFlightItineraryWithDepartureTime,
  CallState,
  FlightItineraryRequest,
  MyTripsFilter,
  WatchAlertViews,
  HotelItinerary,
  HotelItineraryRequest,
  IOpenModal,
  WatchAlertView,
  CarItineraryResponse,
  CarReservation,
  DenyScheduleChangeRequest,
  CurrentPriceFreezesEnum,
  PriceFreezeView,
  TripSummaryContext,
  CancellationFareRulesSuccess,
  CancellationResponse,
  FlightItineraryState,
  HotelItineraryState,
  CarItineraryRequest,
  CarReservationState,
  Airport,
  Airline,
  IRequestPassengerEdit,
  GuestsSelection,
  IResult,
  AvailabilityResponse,
  TripCategory,
  HotelCfarCancellationPolicyRequest,
  HotelCfarCancelScenario,
  HotelCfarCancellationRequest,
  HotelPriceFreezeSummary,
  ListSummariesRequestEnum,
  HomesItinerary,
  HomesItineraryRequest,
  HomesReservationState,
  Payment,
  TripRequests,
  ActiveCrossSellOffer,
  AvailabilityRequestEnum,
  PackageItineraryState,
  PackageItinerary,
  PackageItineraryRequest,
  AncillaryPostBookingMyTripsOffer,
  ExperienceReservationRequest,
  ExperienceReservation,
  ExperienceReservationState,
} from "redmond";
import * as H from "history";
import * as actionTypes from "./constants";
import { IPriceFreezeResponse } from "../sagas/fetchPriceFreezeSaga";
import { ITripsListExperiments } from "../reducer";

export interface ISetTripsListExperiments {
  type: actionTypes.SET_TRIPS_LIST_EXPERIMENTS;
  experiments: ITripsListExperiments;
}

export const setTripsListExperiments = (
  experiments: ITripsListExperiments
): ISetTripsListExperiments => ({
  type: actionTypes.SET_TRIPS_LIST_EXPERIMENTS,
  experiments,
});

export interface IPopulateTripQueryParams {
  type: actionTypes.POPULATE_TRIP_QUERY_PARAMS;
  history: H.History;
  queryParams?: { tripId?: string };
}

export const populateTripQueryParams = (
  history: H.History,
  queryParams?: { tripId?: string }
): IPopulateTripQueryParams => ({
  type: actionTypes.POPULATE_TRIP_QUERY_PARAMS,
  history,
  queryParams,
});

export interface ISetTripsFilter {
  type: actionTypes.SET_MY_TRIPS_FILTER;
  tripsFilter: MyTripsFilter;
}

export const setTripsFilter = (
  tripsFilter: MyTripsFilter
): ISetTripsFilter => ({
  type: actionTypes.SET_MY_TRIPS_FILTER,
  tripsFilter,
});

export interface IFetchFlights {
  type: actionTypes.FETCH_FLIGHTS;
  request: FlightItineraryRequest;
  history: H.History;
  includePostBookingOffers?: boolean;
}

export const fetchFlights = (
  request: FlightItineraryRequest,
  history: H.History,
  includePostBookingOffers?: boolean
): IFetchFlights => ({
  type: actionTypes.FETCH_FLIGHTS,
  request,
  history,
  includePostBookingOffers,
});

export interface IFetchFlightsFailed {
  type: actionTypes.FETCH_FLIGHTS_FAILED;
  request: FlightItineraryRequest;
}

export const fetchFlightsFailed = (
  request: FlightItineraryRequest
): IFetchFlightsFailed => ({
  type: actionTypes.FETCH_FLIGHTS_FAILED,
  request,
});

export interface ISetFlights {
  type: actionTypes.SET_FLIGHTS;
  flights: {
    [key: string]: BookedFlightItineraryWithDepartureTime[];
  };
  itineraryTypes: FlightItineraryState[];
  airports: { [key: string]: Airport };
  airlines: { [key: string]: Airline };
}

export const setFlights = (
  flights: {
    [key: string]: BookedFlightItineraryWithDepartureTime[];
  },
  itineraryTypes: FlightItineraryState[],
  airports: { [key: string]: Airport },
  airlines: { [key: string]: Airline }
): ISetFlights => ({
  type: actionTypes.SET_FLIGHTS,
  flights,
  itineraryTypes,
  airports,
  airlines,
});

export interface ISetMyTripsPostBookingOffers {
  type: actionTypes.SET_MY_TRIPS_POSTBOOKING_OFFERS;
  response: AncillaryPostBookingMyTripsOffer[];
}

export const setMyTripsPostBookingOffers = (
  response: AncillaryPostBookingMyTripsOffer[]
): ISetMyTripsPostBookingOffers => ({
  type: actionTypes.SET_MY_TRIPS_POSTBOOKING_OFFERS,
  response,
});

export interface IFetchHotels {
  type: actionTypes.FETCH_HOTELS;
  request: HotelItineraryRequest;
  history: H.History;
  persist?: HotelItineraryState[];
}

export const fetchHotels = (
  request: HotelItineraryRequest,
  history: H.History,
  persist?: HotelItineraryState[]
): IFetchHotels => ({
  type: actionTypes.FETCH_HOTELS,
  request,
  history,
  persist,
});

export interface IFetchHotelsFailed {
  type: actionTypes.FETCH_HOTELS_FAILED;
  request: HotelItineraryRequest;
}

export const fetchHotelsFailed = (
  request: HotelItineraryRequest
): IFetchHotelsFailed => ({
  type: actionTypes.FETCH_HOTELS_FAILED,
  request,
});

export interface ISetHotels {
  type: actionTypes.SET_HOTELS;
  hotels: {
    [key: string]: HotelItinerary[];
  };
  itineraryTypes: HotelItineraryState[];
}

export const setHotels = (
  hotels: {
    [key: string]: HotelItinerary[];
  },
  itineraryTypes: HotelItineraryState[]
): ISetHotels => ({
  type: actionTypes.SET_HOTELS,
  hotels,
  itineraryTypes,
});

export interface ISetSelectedHotel {
  type: actionTypes.SET_SELECTED_HOTEL;
  selectedHotel: HotelItinerary | null;
}

export const setSelectedHotel = (
  selectedHotel: HotelItinerary | null
): ISetSelectedHotel => ({
  type: actionTypes.SET_SELECTED_HOTEL,
  selectedHotel,
});

export interface IFetchHotelCfarCancellationPolicy {
  type: actionTypes.FETCH_HOTEL_CFAR_CANCELLATION_POLICY;
  request: HotelCfarCancellationPolicyRequest;
}

export const fetchHotelCfarCancellationPolicy = (
  request: HotelCfarCancellationPolicyRequest
): IFetchHotelCfarCancellationPolicy => ({
  type: actionTypes.FETCH_HOTEL_CFAR_CANCELLATION_POLICY,
  request,
});

export interface ISetHotelCfarCancellationPolicyScenario {
  type: actionTypes.SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO;
  hotelCfarCancellationPolicyScenario: HotelCfarCancelScenario | null;
}

export const setHotelCfarCancellationPolicyScenario = (
  hotelCfarCancellationPolicyScenario: HotelCfarCancelScenario | null
): ISetHotelCfarCancellationPolicyScenario => ({
  type: actionTypes.SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO,
  hotelCfarCancellationPolicyScenario: hotelCfarCancellationPolicyScenario,
});

export interface IResetHotelCfarCancellation {
  type: actionTypes.RESET_HOTEL_CFAR_CANCELLATION;
}
export const resetHotelCfarCancellation = (): IResetHotelCfarCancellation => ({
  type: actionTypes.RESET_HOTEL_CFAR_CANCELLATION,
});

export interface ISetHotelCfarCancellationPolicyCallState {
  type: actionTypes.SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE;
  callState: CallState;
}

export const setHotelCfarCancellationPolicyCallState = (
  callState: CallState
): ISetHotelCfarCancellationPolicyCallState => ({
  type: actionTypes.SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE,
  callState,
});

export interface IConfirmHotelCfarCancellation {
  type: actionTypes.CONFIRM_HOTEL_CFAR_CANCELLATION;
  request: HotelCfarCancellationRequest;
}

export const confirmHotelCfarCancellation = (
  request: HotelCfarCancellationRequest
): IConfirmHotelCfarCancellation => ({
  type: actionTypes.CONFIRM_HOTEL_CFAR_CANCELLATION,
  request,
});

export interface ISetConfirmHotelCfarCancellationCallState {
  type: actionTypes.SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE;
  callState: CallState;
}

export const setConfirmHotelCfarCancellationCallState = (
  callState: CallState
): ISetConfirmHotelCfarCancellationCallState => ({
  type: actionTypes.SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE,
  callState,
});

export interface ISetHotelCfarCancelled {
  type: actionTypes.SET_HOTEL_CFAR_CANCELLED;
}

export const setHotelCfarCancelled = (): ISetHotelCfarCancelled => ({
  type: actionTypes.SET_HOTEL_CFAR_CANCELLED,
});

export interface IFetchHomes {
  type: actionTypes.FETCH_HOMES;
  request: HomesItineraryRequest;
  history: H.History;
  persist?: HomesReservationState[];
}

export const fetchHomes = (
  request: HomesItineraryRequest,
  history: H.History,
  persist?: HomesReservationState[]
): IFetchHomes => ({
  type: actionTypes.FETCH_HOMES,
  request,
  history,
  persist,
});

export interface IFetchHomesFailed {
  type: actionTypes.FETCH_HOMES_FAILED;
  request: HomesItineraryRequest;
}

export const fetchHomesFailed = (
  request: HomesItineraryRequest
): IFetchHomesFailed => ({
  type: actionTypes.FETCH_HOMES_FAILED,
  request,
});

export interface ISetHomes {
  type: actionTypes.SET_HOMES;
  homes: {
    [key: string]: HomesItinerary[];
  };
  itineraryTypes: HomesReservationState[];
}

export const setHomes = (
  homes: {
    [key: string]: HomesItinerary[];
  },
  itineraryTypes: HomesReservationState[]
): ISetHomes => ({
  type: actionTypes.SET_HOMES,
  homes,
  itineraryTypes,
});

export interface ISetSelectedHome {
  type: actionTypes.SET_SELECTED_HOME;
  selectedHome: HomesItinerary | null;
}

export const setSelectedHome = (
  selectedHome: HomesItinerary | null
): ISetSelectedHome => ({
  type: actionTypes.SET_SELECTED_HOME,
  selectedHome,
});

export interface IFetchCars {
  type: actionTypes.FETCH_CARS;
  request: CarItineraryRequest;
  history: H.History;
}

export const fetchCars = (
  request: CarItineraryRequest,
  history: H.History
): IFetchCars => ({
  type: actionTypes.FETCH_CARS,
  request,
  history,
});

export interface IFetchCarsFailed {
  type: actionTypes.FETCH_CARS_FAILED;
  request: CarItineraryRequest;
}

export const fetchCarsFailed = (
  request: CarItineraryRequest
): IFetchCarsFailed => ({
  type: actionTypes.FETCH_CARS_FAILED,
  request,
});

export interface ISetCars {
  type: actionTypes.SET_CARS;
  response: CarItineraryResponse;
  reservationTypes?: CarReservationState[];
}

export const setCars = (
  response: CarItineraryResponse,
  reservationTypes: CarReservationState[]
): ISetCars => ({
  type: actionTypes.SET_CARS,
  response,
  reservationTypes,
});
export interface ISetSelectedCar {
  type: actionTypes.SET_SELECTED_CAR;
  selectedCar: CarReservation | null;
}

export const setSelectedCar = (
  selectedCar: CarReservation | null
): ISetSelectedCar => ({
  type: actionTypes.SET_SELECTED_CAR,
  selectedCar,
});

export interface IUpdateWatch {
  type: actionTypes.UPDATE_WATCH;
  watch: WatchAlertView;
  email: string;
}

export const updateWatch = (
  watch: WatchAlertView,
  email: string
): IUpdateWatch => ({
  type: actionTypes.UPDATE_WATCH,
  watch,
  email,
});

export interface ISetUpdateWatchCallState {
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setUpdateWatchCallState = (
  callState: CallState
): ISetUpdateWatchCallState => ({
  type: actionTypes.SET_UPDATE_WATCH_CALL_STATE,
  callState,
});

export interface IListWatches {
  type: actionTypes.LIST_WATCHES;
}

export const listWatches = (): IListWatches => ({
  type: actionTypes.LIST_WATCHES,
});

export interface IListPriceFreeze {
  type: actionTypes.LIST_PRICE_FREEZE;
  requestType: CurrentPriceFreezesEnum;
}

export const listPriceFreeze = (): IListPriceFreeze => ({
  type: actionTypes.LIST_PRICE_FREEZE,
  requestType: CurrentPriceFreezesEnum.CurrentPriceFreezes,
});

export interface ISetIListPriceFreezeCallState {
  type: actionTypes.SET_LIST_PRICE_FREEZE_CALL_STATE;
  callState: CallState;
}

export const setListPriceFreezeCallState = (
  callState: CallState
): ISetIListPriceFreezeCallState => ({
  type: actionTypes.SET_LIST_PRICE_FREEZE_CALL_STATE,
  callState,
});

export interface ISetPriceFreeze {
  type: actionTypes.SET_PRICE_FREEZE;
  priceFreezes: Array<PriceFreezeView>;
  tripContext: TripSummaryContext;
}

export const setPriceFreeze = ({
  priceFreezes,
  tripContext,
}: IPriceFreezeResponse): ISetPriceFreeze => ({
  type: actionTypes.SET_PRICE_FREEZE,
  priceFreezes,
  tripContext,
});

export interface ISetListWatchesCallState {
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE;
  callState: CallState;
}

export interface IListHotelPriceFreeze {
  type: actionTypes.LIST_HOTEL_PRICE_FREEZE;
  requestType: ListSummariesRequestEnum;
}

export const listHotelPriceFreeze = (): IListHotelPriceFreeze => ({
  type: actionTypes.LIST_HOTEL_PRICE_FREEZE,
  requestType: ListSummariesRequestEnum.ForAuthenticatedUser,
});

export interface ISetIListHotelPriceFreezeCallState {
  type: actionTypes.SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE;
  callState: CallState;
}

export const setListHotelPriceFreezeCallState = (
  callState: CallState
): ISetIListHotelPriceFreezeCallState => ({
  type: actionTypes.SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE,
  callState,
});

export interface ISetHotelPriceFreezes {
  type: actionTypes.SET_HOTEL_PRICE_FREEZES;
  hotelPriceFreezes: Array<HotelPriceFreezeSummary>;
}

export const setHotelPriceFreezes = (
  hotelPriceFreezes: HotelPriceFreezeSummary[]
): ISetHotelPriceFreezes => ({
  type: actionTypes.SET_HOTEL_PRICE_FREEZES,
  hotelPriceFreezes,
});

export interface ISetListWatchesCallState {
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE;
  callState: CallState;
}

export const setListWatchesCallState = (
  callState: CallState
): ISetListWatchesCallState => ({
  type: actionTypes.SET_LIST_WATCHES_CALL_STATE,
  callState,
});

export interface ISetWatches {
  type: actionTypes.SET_WATCHES;
  watches: WatchAlertViews;
}

export const setWatches = (watches: WatchAlertViews): ISetWatches => ({
  type: actionTypes.SET_WATCHES,
  watches,
});

export interface ISetSelectedFlight {
  type: actionTypes.SET_SELECTED_FLIGHT;
  selectedFlight: BookedFlightItineraryWithDepartureTime | null;
}

export const setSelectedFlight = (
  selectedFlight: BookedFlightItineraryWithDepartureTime | null
): ISetSelectedFlight => ({
  type: actionTypes.SET_SELECTED_FLIGHT,
  selectedFlight,
});

export interface ISetOpenModal {
  type: actionTypes.SET_OPEN_MODAL;
  openModal: IOpenModal;
}

export const setOpenModal = (openModal: IOpenModal): ISetOpenModal => ({
  type: actionTypes.SET_OPEN_MODAL,
  openModal,
});

export interface ISetSelectedRequestPassengerEdit {
  type: actionTypes.SET_SELECTED_REQUEST_PASSENGER_EDIT;
  requestPassengerEdit?: IRequestPassengerEdit;
}

export const setSelectedRequestPassengerEdit = (
  requestPassengerEdit?: IRequestPassengerEdit
): ISetSelectedRequestPassengerEdit => ({
  type: actionTypes.SET_SELECTED_REQUEST_PASSENGER_EDIT,
  requestPassengerEdit,
});

export interface IDeleteWatch {
  type: actionTypes.DELETE_WATCH;
  watch: WatchAlertView;
}

export const deleteWatch = (watch: WatchAlertView): IDeleteWatch => ({
  type: actionTypes.DELETE_WATCH,
  watch,
});

export interface ISetDeleteWatchCallState {
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE;
  callState: CallState;
}

export const setDeleteWatchCallState = (
  callState: CallState
): ISetDeleteWatchCallState => ({
  type: actionTypes.SET_DELETE_WATCH_CALL_STATE,
  callState,
});

export interface IAcceptScheduleChange {
  type: actionTypes.ACCEPT_SCHEDULE_CHANGE;
  itineraryId: string;
  history: H.History;
}

export const acceptScheduleChange = (
  history: H.History,
  itineraryId: string
): IAcceptScheduleChange => ({
  type: actionTypes.ACCEPT_SCHEDULE_CHANGE,
  itineraryId,
  history,
});

export interface ISetAcceptScheduleChangeCallState {
  type: actionTypes.SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE;
  callState: CallState;
}

export const setAcceptScheduleChangeCallState = (
  callState: CallState
): ISetAcceptScheduleChangeCallState => ({
  type: actionTypes.SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE,
  callState,
});

export interface IDenyScheduleChange {
  type: actionTypes.DENY_SCHEDULE_CHANGE;
  request: DenyScheduleChangeRequest;
  history: H.History;
}

export const denyScheduleChange = (
  req: DenyScheduleChangeRequest,
  history: H.History
): IDenyScheduleChange => ({
  type: actionTypes.DENY_SCHEDULE_CHANGE,
  request: req,
  history,
});

export interface ISetDenyScheduleChangeCallState {
  type: actionTypes.SET_DENY_SCHEDULE_CHANGE_CALL_STATE;
  callState: CallState;
}

export const setDenyScheduleChangeCallState = (
  callState: CallState
): ISetDenyScheduleChangeCallState => ({
  type: actionTypes.SET_DENY_SCHEDULE_CHANGE_CALL_STATE,
  callState,
});

export interface IFetchFlightCfarCancellationInfoV3 {
  type: actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3;
  itineraryId: string;
}

export const fetchFlightCfarCancellationInfoV3 = (args: {
  itineraryId: string;
}): IFetchFlightCfarCancellationInfoV3 => ({
  type: actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3,
  ...args,
});

export interface ISetFlightCfarCancellationInfoV3Response {
  type: actionTypes.SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE;
  response: CancellationFareRulesSuccess;
}

export const setFlightCfarCancellationInfoV3Response = (args: {
  response: CancellationFareRulesSuccess;
}): ISetFlightCfarCancellationInfoV3Response => ({
  type: actionTypes.SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE,
  ...args,
});

export interface ISetFetchFlightCfarCancellationInfoV3CallStateFailed {
  type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED;
}

export const setFetchFlightCfarCancellationInfoV3CallStateFailed =
  (): ISetFetchFlightCfarCancellationInfoV3CallStateFailed => ({
    type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED,
  });

export interface ISetFetchFlightCfarCancellationInfoV3CallStateNotCalled {
  type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED;
}

export const setFetchFlightCfarCancellationInfoV3CallStateNotCalled =
  (): ISetFetchFlightCfarCancellationInfoV3CallStateNotCalled => ({
    type: actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED,
  });

export interface IConfirmFlightCfarCancellation {
  type: actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION;
  useCash?: boolean;
}

export const confirmFlightCfarCancellation = (
  useCash?: boolean
): IConfirmFlightCfarCancellation => ({
  type: actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION,
  useCash,
});

export interface ISetConfirmFlightCfarCancellationResponse {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE;
  response: CancellationResponse;
}

export const setConfirmFlightCfarCancellationResponse = (args: {
  response: CancellationResponse;
}): ISetConfirmFlightCfarCancellationResponse => ({
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE,
  ...args,
});

export interface ISetConfirmFlightCfarCancellationCallStateFailed {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED;
}

export const setConfirmFlightCfarCancellationCallStateFailed =
  (): ISetConfirmFlightCfarCancellationCallStateFailed => ({
    type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED,
  });

export interface ISetConfirmFlightCfarCancellationCallStateNotCalled {
  type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED;
}

export const setConfirmFlightCfarCancellationCallStateNotCalled =
  (): ISetConfirmFlightCfarCancellationCallStateNotCalled => ({
    type: actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED,
  });

export interface IFetchUpcomingFlightCrossSell {
  type:
    | actionTypes.FETCH_INITIAL_UPCOMING_FLIGHT_CROSS_SELL
    | actionTypes.FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL;
  requestType: AvailabilityRequestEnum;
}

export const fetchInitialUpcomingFlightCrossSell =
  (): IFetchUpcomingFlightCrossSell => ({
    type: actionTypes.FETCH_INITIAL_UPCOMING_FLIGHT_CROSS_SELL,
    requestType: AvailabilityRequestEnum.InitialSearch,
  });

export const fetchMoreUpcomingFlightCrossSell =
  (): IFetchUpcomingFlightCrossSell => ({
    type: actionTypes.FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL,
    requestType: AvailabilityRequestEnum.FollowUpSearch,
  });

export interface ISetCrossSellHotelAvailabilityResults {
  type: actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS;
  payload: ISetCrossSellHotelAvailabilityResultsPayload;
  latency: number;
}

export type ISetCrossSellHotelAvailabilityResultsPayload = AvailabilityResponse;

export const setCrossSellHotelAvailabilityResults = (args: {
  payload: ISetCrossSellHotelAvailabilityResultsPayload;
  latency: number;
}): ISetCrossSellHotelAvailabilityResults => ({
  type: actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS,
  ...args,
});

export interface IFetchUpcomingFlightCrossSellFailed {
  type: actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED;
}

export const fetchUpcomingFlightCrossSellFailed =
  (): IFetchUpcomingFlightCrossSellFailed => ({
    type: actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED,
  });
export interface ISetSearchedDates {
  type: actionTypes.SET_SEARCHED_DATES;
  searchedFromDate: Date;
  searchedUntilDate: Date;
}

export const setSearchedDates = (
  searchedFromDate: Date,
  searchedUntilDate: Date
): ISetSearchedDates => ({
  type: actionTypes.SET_SEARCHED_DATES,
  searchedFromDate,
  searchedUntilDate,
});

export interface ISetSearchedLocationResult {
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT;
  searchedLocationResult: IResult | null;
}

export const setSearchedLocationResult = (
  searchedLocationResult: IResult | null
): ISetSearchedLocationResult => ({
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT,
  searchedLocationResult,
});

export interface ISetSearchedOccupancyCounts {
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setSearchedOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetSearchedOccupancyCounts => ({
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS,
  counts,
});

export interface ISetSelectedLodgingIndex {
  type: actionTypes.SET_SELECTED_LODGING_INDEX;
  index: number;
}

export const setSelectedLodgingIndex = (index: number) => ({
  type: actionTypes.SET_SELECTED_LODGING_INDEX,
  index,
});

export interface ISetFlightToHotelXSellTripCategory {
  type: actionTypes.SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY;
  category: TripCategory;
}

export const setFlightToHotelXSellTripCategory = (category: TripCategory) => ({
  type: actionTypes.SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY,
  category,
});

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export interface IFetchPendingRequests {
  type: actionTypes.FETCH_PENDING_REQUESTS;
}

export const fetchPendingRequests = (): IFetchPendingRequests => {
  return {
    type: actionTypes.FETCH_PENDING_REQUESTS,
  };
};

export interface IFetchPendingRequestsFailed {
  type: actionTypes.FETCH_PENDING_REQUESTS_FAILED;
}

export const fetchPendingRequestsFailed = (): IFetchPendingRequestsFailed => ({
  type: actionTypes.FETCH_PENDING_REQUESTS_FAILED,
});

export interface ISetPendingRequests {
  type: actionTypes.SET_PENDING_REQUESTS;
  requests: TripRequests;
}

export const setPendingRequests = (
  requests: TripRequests
): ISetPendingRequests => ({
  type: actionTypes.SET_PENDING_REQUESTS,
  requests,
});

export interface ISetTripSearchQuery {
  type: actionTypes.SET_TRIP_SEARCH_QUERY;
  searchQuery: string;
}

export const setTripSearchQuery = (
  searchQuery: string
): ISetTripSearchQuery => ({
  type: actionTypes.SET_TRIP_SEARCH_QUERY,
  searchQuery,
});

export interface IFetchActiveHotelCrossSell {
  type: actionTypes.FETCH_ACTIVE_HOTEL_CROSS_SELL;
}

export const fetchActiveHotelCrossSell = (): IFetchActiveHotelCrossSell => ({
  type: actionTypes.FETCH_ACTIVE_HOTEL_CROSS_SELL,
});

export interface ISetCrossSellOffers {
  type: actionTypes.SET_CROSS_SELL_OFFERS;
  offers: ActiveCrossSellOffer[];
}

export const setCrossSellOffers = (
  offers: ActiveCrossSellOffer[]
): ISetCrossSellOffers => ({
  type: actionTypes.SET_CROSS_SELL_OFFERS,
  offers,
});
export interface ISetFetchActiveHotelCrossSellCallState {
  type: actionTypes.SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE;
  callState: CallState;
}

export const setFetchActiveHotelCrossSellCallState = (
  callState: CallState
): ISetFetchActiveHotelCrossSellCallState => ({
  type: actionTypes.SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE,
  callState,
});

export interface IFetchPackages {
  type: actionTypes.FETCH_PACKAGES;
  request: PackageItineraryRequest;
  history: H.History;
  persist?: PackageItineraryState[];
}

export const fetchPackages = (
  request: PackageItineraryRequest,
  history: H.History,
  persist?: PackageItineraryState[]
): IFetchPackages => ({
  type: actionTypes.FETCH_PACKAGES,
  request,
  history,
  persist,
});

export interface IFetchPackagesFailed {
  type: actionTypes.FETCH_PACKAGES_FAILED;
  request: PackageItineraryRequest;
}

export const fetchPackagesFailed = (
  request: PackageItineraryRequest
): IFetchPackagesFailed => ({
  type: actionTypes.FETCH_PACKAGES_FAILED,
  request,
});

export interface ISetPackages {
  type: actionTypes.SET_PACKAGES;
  packages: {
    [key: string]: PackageItinerary[];
  };
  itineraryTypes: PackageItineraryState[];
}

export const setPackages = (
  packages: {
    [key: string]: PackageItinerary[];
  },
  itineraryTypes: PackageItineraryState[]
): ISetPackages => ({
  type: actionTypes.SET_PACKAGES,
  packages,
  itineraryTypes,
});

export interface ISetSelectedPackage {
  type: actionTypes.SET_SELECTED_PACKAGE;
  selectedPackage: PackageItinerary | null;
}

export const setSelectedPackage = (
  selectedPackage: PackageItinerary | null
): ISetSelectedPackage => ({
  type: actionTypes.SET_SELECTED_PACKAGE,
  selectedPackage,
});

export interface ISetSelectedPackageHotel {
  type: actionTypes.SET_SELECTED_PACKAGE_HOTEL;
  selectedHotel: HotelItinerary | null;
}

export const setSelectedPackageHotel = (
  selectedHotel: HotelItinerary | null
): ISetSelectedPackageHotel => ({
  type: actionTypes.SET_SELECTED_PACKAGE_HOTEL,
  selectedHotel,
});

export interface ISetSelectedPackageFlight {
  type: actionTypes.SET_SELECTED_PACKAGE_FLIGHT;
  selectedFlight: BookedFlightItineraryWithDepartureTime | null;
}

export const setSelectedPackageFlight = (
  selectedFlight: BookedFlightItineraryWithDepartureTime | null
): ISetSelectedPackageFlight => ({
  type: actionTypes.SET_SELECTED_PACKAGE_FLIGHT,
  selectedFlight,
});

export interface IFetchExperiences {
  type: actionTypes.FETCH_EXPERIENCES;
  request: ExperienceReservationRequest;
  history: H.History;
  persist?: ExperienceReservationState[];
}

export const fetchExperiences = (
  request: ExperienceReservationRequest,
  history: H.History,
  persist?: ExperienceReservationState[]
): IFetchExperiences => ({
  type: actionTypes.FETCH_EXPERIENCES,
  request,
  history,
  persist,
});

export interface IFetchExperiencesFailed {
  type: actionTypes.FETCH_EXPERIENCES_FAILED;
  request: ExperienceReservationRequest;
}

export const fetchExperiencesFailed = (
  request: ExperienceReservationRequest
): IFetchExperiencesFailed => ({
  type: actionTypes.FETCH_EXPERIENCES_FAILED,
  request,
});

export interface ISetExperiences {
  type: actionTypes.SET_EXPERIENCES;
  experiences: {
    [key: string]: ExperienceReservation[];
  };
  itineraryTypes: ExperienceReservationState[];
}

export const setExperiences = (
  experiences: {
    [key: string]: ExperienceReservation[];
  },
  itineraryTypes: ExperienceReservationState[]
): ISetExperiences => ({
  type: actionTypes.SET_EXPERIENCES,
  experiences,
  itineraryTypes,
});

export interface ISetSelectedExperience {
  type: actionTypes.SET_SELECTED_EXPERIENCE;
  selectedExperience: ExperienceReservation | null;
}

export const setSelectedExperience = (
  selectedExperience: ExperienceReservation | null
): ISetSelectedExperience => ({
  type: actionTypes.SET_SELECTED_EXPERIENCE,
  selectedExperience,
});

export type TripsListActions =
  | ISetTripsListExperiments
  | ISetTripsFilter
  | IFetchFlights
  | IFetchFlightsFailed
  | ISetFlights
  | ISetMyTripsPostBookingOffers
  | IUpdateWatch
  | ISetUpdateWatchCallState
  | IListPriceFreeze
  | ISetIListPriceFreezeCallState
  | ISetPriceFreeze
  | IListHotelPriceFreeze
  | ISetIListHotelPriceFreezeCallState
  | ISetHotelPriceFreezes
  | IListWatches
  | ISetListWatchesCallState
  | ISetWatches
  | ISetSelectedFlight
  | IFetchHotels
  | IFetchHotelsFailed
  | ISetHotels
  | ISetSelectedHotel
  | IFetchCars
  | IFetchCarsFailed
  | ISetCars
  | ISetSelectedCar
  | IDeleteWatch
  | ISetDeleteWatchCallState
  | ISetOpenModal
  | IAcceptScheduleChange
  | ISetAcceptScheduleChangeCallState
  | IDenyScheduleChange
  | ISetDenyScheduleChangeCallState
  | IFetchFlightCfarCancellationInfoV3
  | ISetFlightCfarCancellationInfoV3Response
  | ISetFetchFlightCfarCancellationInfoV3CallStateFailed
  | ISetFetchFlightCfarCancellationInfoV3CallStateNotCalled
  | IConfirmFlightCfarCancellation
  | ISetConfirmFlightCfarCancellationResponse
  | ISetConfirmFlightCfarCancellationCallStateFailed
  | ISetConfirmFlightCfarCancellationCallStateNotCalled
  | ISetSelectedRequestPassengerEdit
  | IFetchUpcomingFlightCrossSell
  | ISetCrossSellHotelAvailabilityResults
  | IFetchUpcomingFlightCrossSellFailed
  | ISetSearchedDates
  | ISetSearchedLocationResult
  | ISetSearchedOccupancyCounts
  | ISetSelectedLodgingIndex
  | ISetFlightToHotelXSellTripCategory
  | IFetchHotelCfarCancellationPolicy
  | ISetHotelCfarCancellationPolicyScenario
  | ISetHotelCfarCancellationPolicyCallState
  | IResetHotelCfarCancellation
  | IConfirmHotelCfarCancellation
  | ISetConfirmHotelCfarCancellationCallState
  | ISetHotelCfarCancelled
  | IFetchHomes
  | IFetchHomesFailed
  | ISetHomes
  | ISetSelectedHome
  | IListPaymentMethods
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed
  | IFetchPendingRequests
  | ISetPendingRequests
  | IFetchPendingRequestsFailed
  | ISetTripSearchQuery
  | IFetchActiveHotelCrossSell
  | ISetCrossSellOffers
  | ISetFetchActiveHotelCrossSellCallState
  | IFetchPackages
  | ISetPackages
  | IFetchPackagesFailed
  | ISetSelectedPackage
  | ISetSelectedPackageHotel
  | ISetSelectedPackageFlight
  | IFetchExperiences
  | ISetExperiences
  | IFetchExperiencesFailed
  | ISetSelectedExperience;
