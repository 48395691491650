import React, { useContext } from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import { NoResults, ActionButton, Icon, IconName } from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { CustomerAccountRole, MyTripsFilter } from "redmond";
import { isCorpTenant, getCurrentCorpAccount } from "@capone/common";
import * as textConstants from "./constants";
import { NoTripResultsConnectorProps } from "./container";
import { PATH_CARS, PATH_FLIGHTS, PATH_HOTELS } from "../../../../utils/paths";
import { config } from "../../../../api/config";
import { ClientContext } from "../../../../App";
import { NoResultsTripsWithOffers } from "./components/TripsWithOffers";

export interface INoTripResultsProps
  extends NoTripResultsConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  hasFilterSearchQuery: boolean;
  clearFilterSearchQuery: () => void;
}
export const NoTripResults = ({
  hasTripsToDisplay,
  hasError,
  tripsFilter,
  history,
  isMobile,
  largestValueAccount,
  hasFilterSearchQuery,
  clearFilterSearchQuery,
  rewardsAccounts,
}: INoTripResultsProps) => {
  const { sessionInfo } = useContext(ClientContext);

  const currentAccount = getCurrentCorpAccount(rewardsAccounts, sessionInfo);

  const account = isCorpTenant(config.TENANT)
    ? currentAccount || largestValueAccount
    : largestValueAccount;

  const showEarnText =
    !!account &&
    (!isCorpTenant(config.TENANT) ||
      account.customerAccountRole === CustomerAccountRole.Primary);

  const renderNoTripsResults = () => (
    <>
      <Typography className="no-trips-title">
        {textConstants.getNoResultsTitle(tripsFilter)}
      </Typography>
      {!!textConstants.getNoResultsSubtitle(tripsFilter) && (
        <Typography className="no-trips-subtitle">
          {textConstants.getNoResultsSubtitle(tripsFilter)}
        </Typography>
      )}
      {showEarnText && (
        <Typography className="no-trips-earn">
          {textConstants.getEarnText(account)}
        </Typography>
      )}

      <Box className="no-trips-ctas">
        <Box className="no-trips-cta">
          <Box className="no-trips-cta-icon-wrapper">
            <Icon name={IconName.HotelFunnelIcon} />
          </Box>
          <ActionButton
            className="book-a-trip"
            message={textConstants.HOTELS_CTA}
            defaultStyle="h4r-secondary"
            onClick={() => {
              history.push(PATH_HOTELS);
            }}
          />
        </Box>
        <Box className="no-trips-cta">
          <Box className="no-trips-cta-icon-wrapper">
            <Icon name={IconName.FlightFunnelIcon} />
          </Box>
          <ActionButton
            className="book-a-trip"
            message={textConstants.FLIGHTS_CTA}
            defaultStyle="h4r-secondary"
            onClick={() => {
              history.push(PATH_FLIGHTS);
            }}
          />
        </Box>
        <Box className="no-trips-cta">
          <Box className="no-trips-cta-icon-wrapper">
            <Icon name={IconName.CarFunnelIcon} />
          </Box>
          <ActionButton
            className="book-a-trip"
            message={textConstants.CARS_CTA}
            defaultStyle="h4r-secondary"
            onClick={() => {
              history.push(PATH_CARS);
            }}
          />
        </Box>
      </Box>
    </>
  );

  const renderNoFilteredTripsResults = () => (
    <>
      <Typography className="no-trips-title">
        {textConstants.NO_RESULTS_FOUND}
      </Typography>
      <Typography className="no-trips-subtitle">
        {textConstants.NO_RESULTS_FOUND_MESSAGE}
      </Typography>
      {showEarnText && (
        <Typography className="no-trips-earn">
          {textConstants.getEarnText(account)}
        </Typography>
      )}

      <Box className="no-trips-ctas">
        <Box className="no-trips-cta">
          <ActionButton
            className="clear-filter"
            message={textConstants.NO_RESULTS_FOUND_BUTTON_LABEL}
            defaultStyle="h4r-secondary"
            onClick={clearFilterSearchQuery}
          />
        </Box>
      </Box>
    </>
  );

  // if there is an error and no trips show error
  const showError = hasError && !hasTripsToDisplay;

  // if there is no error, no trips to display and no filter show no results
  const showNoTripsResult =
    !hasTripsToDisplay && !hasError && !hasFilterSearchQuery;

  // if there is no error, no trips to display but user selected filter show no filtered results
  const showNoFilteredTripsResult =
    !hasTripsToDisplay && !hasError && hasFilterSearchQuery;

  if (tripsFilter === MyTripsFilter.TRIPS_WITH_OFFERS) {
    return <NoResultsTripsWithOffers />;
  }

  return (
    <Box className={clsx({ mobile: isMobile }, "no-trips-results")}>
      {showError && (
        <>
          <NoResults
            className="no-trips-container"
            title={textConstants.getErrorMessage(tripsFilter) ?? ""}
            subtitle={textConstants.ERROR_SUBTITLE}
          />
          <Box className="no-trips-buttons">
            <ActionButton
              className="retry"
              message={textConstants.TRY_AGAIN}
              defaultStyle="h4r-primary"
              onClick={() => window.location.reload()}
            />
          </Box>
        </>
      )}
      {showNoTripsResult && renderNoTripsResults()}
      {showNoFilteredTripsResult && renderNoFilteredTripsResults()}
    </Box>
  );
};
