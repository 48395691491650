import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import { NoResultsTripsWithOffers } from "./component";
import {
  getTripsFilter,
  hasFetchTripsError,
  hasTripsToDisplay,
} from "../../../../reducer";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
} from "../../../../../../modules/rewards/reducer";
import {
  populateTripQueryParams,
  setTripsFilter,
} from "../../../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  hasError: hasFetchTripsError(state),
  hasTripsToDisplay: hasTripsToDisplay(state),
  largestValueAccount: getRewardsAccountWithLargestValue(state),
  rewardsAccounts: getRewardsAccounts(state),
});

const mapDispatchToProps = {
  populateTripQueryParams,
  setTripsFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type NoTripWithOfferResultsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedNoTripWithOfferResults = withRouter(
  connector(NoResultsTripsWithOffers)
);
