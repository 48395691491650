import React, { useRef, useState, useEffect } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import {
  MyTripsMobileCard,
  NotificationBanner,
  BannerSeverity,
  ButtonWrap,
  PolicyInfoModal,
  IconName,
  Icon,
} from "halifax";
import {
  BookedFlightItineraryWithDepartureTime,
  getFlightInfoDetails,
  FlightItinerarySegmentStatusEnum,
  TagInfo,
  TagColors,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  ScheduleChange,
  Airport,
  getDepartureSlice,
  getReturnSlice,
  getLastSlice,
  isMultiCityItinerary,
  MyTripsModalTypes,
  ItineraryEnum,
  IOpenModal,
  SingleTravelItinerary,
  TravelCredit,
  AirlineMap,
  CallState,
  ExerciseEligibilityV1,
  ExerciseEligibilityV1Enum,
  ExerciseEligibilityV1IsEligible,
  EligibilitySegmentEnum,
  FlightDisruptionIsDisrupted,
  EligibilitySliceEnum,
  FlightDisruptionReasonEnum,
  getSlicesFromTravelItinerary,
  IPerson,
  PortalType,
} from "redmond";
import dayjs from "dayjs";

import { isUpdated } from "../ItinerariesModal/components/ScheduleChangeModalContent/constants";
import { DesktopFlightCard } from "./components/DesktopFlightCard";
import { FlightCardConnectorProps } from "./container";
import * as textConstants from "./constants";
import { scrollTopWithOffset } from "../../component";
import "./styles.scss";
import {
  getAirlineName,
  getOriginalItineraryId,
  isBeforeDisruptionProtectionDeadlineTime,
  isDisriptionRebook,
  isMultiTicket,
  isTFBooking,
  isViMultiTicket,
} from "../../../../utils";
import { pushToDisruptionOverview } from "../../../../../../utils/queryStringHelpers";
import {
  getIsDpExercisedViaRebook,
  getIsDpExercisedViaRefund,
  getIsDpAlreadyExercised,
  addFlightType,
} from "./helpers";
import {
  DisruptionContractStatusEnum,
  PostTicketingStatus,
  getMulticityFlightInfoDetails,
} from "redmond/trips-module/itinerary";
import {
  getItineraryOutboundSegments,
  getTravelItinerary,
} from "../ItinerariesModal/components/SelectFlightInfo/constants";
import { PersonalBusinessLabel } from "../../../capone-corporate/PersonalBusinessLabel";
import { PostBookingOfferBanner } from "./components/PostBookingOfferBanner";
import {
  useExperiments,
  ActiveExperiments,
  getExperimentVariantCustomVariants,
  SELF_SERVE_PREMIUM_DISRUPTION_ASSISTANCE_VARIANTS,
  useExperiment,
} from "../../../../../../context/experiments";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../../../api/config";

interface IFlightCardProps
  extends FlightCardConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  flight: BookedFlightItineraryWithDepartureTime;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  isMobile?: boolean;
  isPastTrips: boolean;
  showDisruptionProtectionElements?: boolean;
  isCorporate?: boolean;
}

export const FlightCard = ({
  flight,
  isMobile,
  airportMap,
  airlineMap,
  upcomingItineraries,
  history,
  expandedCard,
  onExpandCard,
  setSelectedFlight,
  setSelectedFlightDisruptionProtectionItineraryId,
  isPastTrips,
  showDisruptionProtectionElements,
  populateTripQueryParams,
  setOpenModal,
  tripsFilter,
  flightDisruptions,
  isDisruptionProtection24hRuleSatisfied,
  hasUpcomingReturnFlightInBookedItineraryAfterRebook,
  fetchFlightDisruptionsCallState,
  isCorporate,
  mcpViItineraries,
}: IFlightCardProps) => {
  const hasMajorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
    ScheduleChangeSeverity.Major;
  const hasMinorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
      ScheduleChangeSeverity.Minor ||
    (flight.status === PortalItineraryStatusEnum.Modified &&
      !flight.bookedItinerary.scheduleChange);

  const flightRef = useRef<HTMLDivElement | null>(null);

  const expState = useExperiments();
  useEffect(() => {
    if (
      !isMobile &&
      flightRef?.current &&
      expandedCard === flight.bookedItinerary.id
    ) {
      scrollTopWithOffset(flightRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  const isCanceled = flight.status === PortalItineraryStatusEnum.Canceled;
  const confirmationCodeClassName = isCanceled ? "warning" : "";

  const { bookedItinerary, ancillaries, departureTime } = flight;
  const { id: itineraryId } = bookedItinerary;

  const { delay, missedConnection } = ancillaries;

  const dpExerciseEligibility: ExerciseEligibilityV1 | undefined =
    flightDisruptions?.eligibilityByItinerary[bookedItinerary.id];

  const isBeforeDpDeadlineTime = isBeforeDisruptionProtectionDeadlineTime(
    dpExerciseEligibility
  );

  const hasDp = !!delay || !!missedConnection;
  const hasEligibleDp = hasDp && isBeforeDpDeadlineTime;

  const isDpExercisedViaRebook = getIsDpExercisedViaRebook(
    delay,
    missedConnection
  );

  const isDpExercisedViaRefund = getIsDpExercisedViaRefund(
    delay,
    missedConnection
  );

  const isDpAlreadyExercised = getIsDpAlreadyExercised(delay, missedConnection);

  const selfServePDAExperiment = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.SelfServePremiumDisruptionAssistance,
    SELF_SERVE_PREMIUM_DISRUPTION_ASSISTANCE_VARIANTS
  );

  const selfServeDisabled = React.useMemo(
    () =>
      selfServePDAExperiment ===
      SELF_SERVE_PREMIUM_DISRUPTION_ASSISTANCE_VARIANTS[1],
    [selfServePDAExperiment]
  );

  const isViFlight = isViMultiTicket(flight.bookedItinerary.multiTicketType);
  const hasAndShowDp =
    hasEligibleDp &&
    !!showDisruptionProtectionElements &&
    isDisruptionProtection24hRuleSatisfied(departureTime);

  const forceFdaErrorModalEnabled = useExperiment(
    "corp-force-fda-error-modal"
  );

  const goToDisruptionProtection = (e?: MouseEvent) => {
    if (
      forceFdaErrorModalEnabled &&
      isCorpTenant(config.TENANT) &&
      flight.portalType === PortalType.CONSUMER
    ) {
      setOpenModal({
        type: MyTripsModalTypes.FlightDisruptionAssistanceError,
        selectedItinerary: addFlightType(flight),
      });
    } else {
      const flights = upcomingItineraries.flights;
      const eligibilities = flightDisruptions.eligibilityByItinerary;
      e?.stopPropagation();
      setSelectedFlightDisruptionProtectionItineraryId(itineraryId);
      pushToDisruptionOverview({
        history,
        itineraryId,
        tracking:
          flights && eligibilities
            ? {
                flights,
                eligibilities,
              }
            : undefined,
        path: selfServeDisabled ? "contact-support" : "main",
      });
    }
  };

  const isMulticity = isMultiCityItinerary(bookedItinerary, airportMap);

  const desktopBanner = getFlightCardBanner({
    isMobile: false,
    isMobileDetailsPage: false,
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    airlineMap,
    setOpenModal: () =>
      setOpenModal({
        type: isViFlight
          ? MyTripsModalTypes.VirtualInterlineMcp
          : MyTripsModalTypes.ScheduleChange,
        selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
      }),
    hasAndShowDp,
    isDpExercisedViaRebook,
    isDpExercisedViaRefund,
    isDpAlreadyExercised,
    hasUpcomingReturnFlight:
      hasUpcomingReturnFlightInBookedItineraryAfterRebook(
        flight.bookedItinerary
      ),
    dpExerciseEligibility,
    goToDisruptionProtection,
    fetchFlightDisruptionsCallState,
    mcpViItineraries,
  });

  const mobileBanner = getFlightCardBanner({
    isMobile: true,
    isMobileDetailsPage: false,
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    airlineMap,
    setOpenModal: () =>
      setOpenModal({
        type: isViFlight
          ? MyTripsModalTypes.VirtualInterlineMcp
          : MyTripsModalTypes.ScheduleChange,
        selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
      }),
    hasAndShowDp,
    isDpExercisedViaRebook,
    isDpExercisedViaRefund,
    isDpAlreadyExercised,
    hasUpcomingReturnFlight:
      hasUpcomingReturnFlightInBookedItineraryAfterRebook(
        flight.bookedItinerary
      ),
    dpExerciseEligibility,
    goToDisruptionProtection,
    fetchFlightDisruptionsCallState,
    mcpViItineraries,
  });

  const formatDate = (date: string) =>
    dayjs(date).format(textConstants.DATE_FORMAT);

  const flightInfoDetails = isMulticity
    ? getMulticityFlightInfoDetails(
        flight.bookedItinerary,
        formatDate,
        airportMap,
        airlineMap
      )
    : getFlightInfoDetails(
        flight.bookedItinerary.travelItinerary?.locators,
        getDepartureSlice(flight.bookedItinerary),
        getReturnSlice(flight.bookedItinerary),
        formatDate,
        airportMap,
        airlineMap
      );

  const passengerMap = {} as Record<string, IPerson>;

  flight.bookedItinerary.passengers.alone.reduce((map, p) => {
    if (!map[p.person.id]) {
      map[p.person.id] = p.person;
    }
    return passengerMap;
  }, passengerMap);

  flight.bookedItinerary.passengers.withLapInfants.reduce((map, p) => {
    if (!map[p.adult.person.id]) {
      map[p.adult.person.id] = p.adult.person;
    }
    return passengerMap;
  }, passengerMap);

  const travelerNames = Object.values(passengerMap).map(
    (person) => `${person?.givenName} ${person?.surname}`
  );

  return (
    <Box className={clsx({ mobile: isMobile }, "my-trips-flight-list")}>
      {isMobile ? (
        <MyTripsMobileCard
          onClick={() => {
            setSelectedFlight(flight);
            populateTripQueryParams(history);
          }}
          banner={mobileBanner}
          confirmationCodeClassName={confirmationCodeClassName}
          confirmationLabel={textConstants.CONFIRMATION}
          label={
            isMulticity ? textConstants.MULTICITY_FLIGHT : textConstants.FLIGHT
          }
          icon={IconName.FlightFunnelIcon}
          personalBusinessLabel={
            <PersonalBusinessLabel isCorporate={isCorporate} />
          }
          travelerNames={travelerNames}
          bottomContent={<PostBookingOfferBanner itineraryId={itineraryId} />}
          {...flightInfoDetails}
        />
      ) : (
        <div ref={flightRef}>
          <Box className="flight-card-icon-title">
            <Icon name={IconName.FlightFunnelIcon} />
            <Typography variant="body1" className="summary-label">
              {textConstants.getHeaderText(
                departureTime, // first leg departure
                getLastSlice(bookedItinerary)?.segments?.[0] // last leg departure
                  ?.scheduledDeparture,
                isMulticity
              )}
              <PersonalBusinessLabel isCorporate={isCorporate} />
            </Typography>
          </Box>
          <DesktopFlightCard
            banner={desktopBanner}
            isCanceled={isCanceled}
            flight={flight}
            isPastTrips={isPastTrips}
            isMobile={isMobile}
            confirmationCodeClassName={confirmationCodeClassName}
            expandedCard={expandedCard}
            onExpandCard={onExpandCard}
            airlineMap={airlineMap}
            airportMap={airportMap}
            setOpenModal={setOpenModal}
            tripsFilter={tripsFilter}
            hasDp={hasDp}
            showDisruptionProtectionElements={showDisruptionProtectionElements}
          />
        </div>
      )}
    </Box>
  );
};

export const getFlightCardBanner = (args: {
  isMobile: boolean;
  isMobileDetailsPage: boolean;
  flight: BookedFlightItineraryWithDepartureTime;
  hasMajorScheduleChange: boolean;
  hasMinorScheduleChange: boolean;
  airlineMap: IFlightCardProps["airlineMap"];
  setOpenModal: (modal: IOpenModal) => void;
  hasAndShowDp: boolean;
  isDpExercisedViaRebook: boolean;
  isDpExercisedViaRefund: boolean;
  isDpAlreadyExercised: boolean;
  hasUpcomingReturnFlight: boolean;
  dpExerciseEligibility: ExerciseEligibilityV1 | undefined;
  goToDisruptionProtection: () => void;
  fetchFlightDisruptionsCallState: CallState;
  mcpViItineraries?: BookedFlightItineraryWithDepartureTime[];
}) => {
  const [openLuggageModal, setOpenLuggageModal] = useState<boolean>(false);
  const [openReturnFlightModal, setOpenReturnFlightModal] =
    useState<boolean>(false);
  const {
    isMobile,
    isMobileDetailsPage,
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    airlineMap,
    setOpenModal,
    hasAndShowDp,
    isDpExercisedViaRebook,
    isDpExercisedViaRefund,
    isDpAlreadyExercised,
    hasUpcomingReturnFlight,
    dpExerciseEligibility,
    goToDisruptionProtection,
    fetchFlightDisruptionsCallState,
    mcpViItineraries,
  } = args;

  const isVflight = isViMultiTicket(flight.bookedItinerary.multiTicketType);

  if (hasAndShowDp && !isVflight) {
    if (
      isDpExercisedViaRebook ||
      isDpExercisedViaRefund ||
      isDpAlreadyExercised
    ) {
      return getDpExercisedBanner({
        isMobile,
        isMobileDetailsPage,
        isDpExercisedViaRebook,
        isDpExercisedViaRefund,
        isDpAlreadyExercised,
        hasUpcomingReturnFlight,
        openLuggageModal,
        setOpenLuggageModal,
        openReturnFlightModal,
        setOpenReturnFlightModal,
      });
    }

    switch (fetchFlightDisruptionsCallState) {
      case CallState.InProcess:
      case CallState.NotCalled:
        return undefined;
      case CallState.Success:
        if (dpExerciseEligibility) {
          return getDpBanner(
            isMobile,
            dpExerciseEligibility,
            goToDisruptionProtection
          );
        } else {
          return getStatusBanner(
            flight,
            hasMajorScheduleChange,
            hasMinorScheduleChange,
            isMobile,
            airlineMap,
            setOpenModal
          );
        }
      case CallState.Failed:
      default:
        return getStatusBanner(
          flight,
          hasMajorScheduleChange,
          hasMinorScheduleChange,
          isMobile,
          airlineMap,
          setOpenModal
        );
    }
  }

  return getStatusBanner(
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    isMobile,
    airlineMap,
    setOpenModal,
    isVflight,
    mcpViItineraries
  );
};

const getDpExercisedBanner = (args: {
  isMobile: boolean;
  isMobileDetailsPage: boolean;
  isDpExercisedViaRebook: boolean;
  isDpExercisedViaRefund: boolean;
  isDpAlreadyExercised: boolean;
  hasUpcomingReturnFlight: boolean;
  openLuggageModal: boolean;
  setOpenLuggageModal: (openLuggageModal: boolean) => void;
  openReturnFlightModal: boolean;
  setOpenReturnFlightModal: (openReturnFlightModal: boolean) => void;
}) => {
  const {
    isMobile,
    isMobileDetailsPage,
    isDpExercisedViaRebook,
    isDpExercisedViaRefund,
    isDpAlreadyExercised,
    hasUpcomingReturnFlight,
    openLuggageModal,
    setOpenLuggageModal,
    openReturnFlightModal,
    setOpenReturnFlightModal,
  } = args;

  if (isDpExercisedViaRebook) {
    const ModalLink = ({
      message,
      onClick,
      className,
    }: {
      message: string;
      onClick: () => void;
      className?: string;
    }) => (
      <ButtonWrap
        className={clsx("modal-link", className)}
        aria-label={message}
        onClick={onClick}
      >
        <Typography className="modal-link-copy" variant="inherit">
          {message}
        </Typography>
      </ButtonWrap>
    );

    const copy = (() => {
      const LuggageAndReturnBanner = () => {
        if (hasUpcomingReturnFlight) {
          return isMobile ? (
            <Box>
              {
                textConstants.DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_FULL_TEXT
              }
            </Box>
          ) : (
            <Box>
              {
                textConstants.DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_ONE
              }
              <ModalLink
                message={textConstants.LUGGAGE}
                onClick={() => setOpenLuggageModal(true)}
              />
              {
                textConstants.DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_TWO
              }
              <ModalLink
                className="end-of-line"
                message={textConstants.RETURN_FLIGHT}
                onClick={() => setOpenReturnFlightModal(true)}
              />
              {textConstants.PERIOD}
            </Box>
          );
        } else {
          return isMobile ? (
            <Box>
              {
                textConstants.DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_ONLY_DETAILS_FULL_TEXT
              }
            </Box>
          ) : (
            <Box>
              {
                textConstants.DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_ONLY_DETAILS_ONE
              }
              <ModalLink
                className="end-of-line"
                message={textConstants.LUGGAGE}
                onClick={() => setOpenLuggageModal(true)}
              />
              {textConstants.PERIOD}
            </Box>
          );
        }
      };

      if (isMobile) {
        if (isMobileDetailsPage) {
          return <LuggageAndReturnBanner />;
        } else {
          return (
            <>
              {textConstants.DISRUPTION_EXERCISED_BANNER_REBOOK_MOBILE_OVERVIEW}
            </>
          );
        }
      } else {
        return <LuggageAndReturnBanner />;
      }
    })();
    const content = (
      <Typography className="label" variant="subtitle2">
        {copy}
      </Typography>
    );

    const LuggageAndReturnBanners = () => {
      const Banner = ({
        bannerCopy,
        onClick,
      }: {
        bannerCopy: string;
        onClick: () => void;
      }) => {
        return (
          <NotificationBanner
            className={clsx(
              isMobile ? "mobile-status-banner" : "status-banner",
              "rebooked-itinerary-policy-banner"
            )}
            content={
              <Box className="banner-content-wrapper">
                <Typography
                  className="banner-copy"
                  variant="inherit"
                  dangerouslySetInnerHTML={{ __html: bannerCopy }}
                />
                <FontAwesomeIcon
                  className="chevron-icon"
                  icon={faChevronRight}
                />
              </Box>
            }
            severity={BannerSeverity.NOTICE}
            icon={
              <Icon className="banner-info-icon" name={IconName.InfoCircle} />
            }
            onClick={onClick}
          />
        );
      };

      return (
        <>
          <Banner
            bannerCopy={textConstants.LEARN_MORE_ABOUT_LUGGAGE}
            onClick={() => setOpenLuggageModal(true)}
          />
          {hasUpcomingReturnFlight && (
            <Banner
              bannerCopy={textConstants.LEARN_MORE_ABOUT_RETURN_FLIGHT}
              onClick={() => setOpenReturnFlightModal(true)}
            />
          )}
        </>
      );
    };

    return (
      <>
        <NotificationBanner
          className={isMobile ? "mobile-status-banner" : "status-banner"}
          content={content}
          severity={BannerSeverity.IMPROVEMENT}
          icon={<Icon name={IconName.CheckCircleFilledGreen} />}
        />
        {isMobile && isMobileDetailsPage && <LuggageAndReturnBanners />}
        <PolicyInfoModal
          isMobile={isMobile}
          preset="disruption-protection-luggage"
          openModal={openLuggageModal}
          onClose={() => setOpenLuggageModal(false)}
        />
        <PolicyInfoModal
          isMobile={isMobile}
          preset="disruption-protection-return-flight"
          openModal={openReturnFlightModal}
          onClose={() => setOpenReturnFlightModal(false)}
        />
      </>
    );
  }

  if (isDpExercisedViaRefund || isDpAlreadyExercised) {
    const bannerContent = (
      <Typography className="label" variant="subtitle2">
        {isDpAlreadyExercised
          ? textConstants.DISRUPTION_BANNER_DELAY_ALREADY_EXERCISED
          : textConstants.DISRUPTION_EXERCISED_BANNER_REFUND}
      </Typography>
    );

    return (
      <NotificationBanner
        className={isMobile ? "mobile-status-banner" : "status-banner"}
        content={bannerContent}
        severity={BannerSeverity.IMPROVEMENT}
        icon={<Icon name={IconName.CheckCircleFilledGreen} />}
      />
    );
  }

  return undefined;
};

const getDpBanner = (
  isMobile: boolean,
  dpExerciseEligibility: ExerciseEligibilityV1 | undefined,
  goToDisruptionProtection: () => void
) => {
  if (!dpExerciseEligibility) {
    return undefined;
  }

  if (
    dpExerciseEligibility.ExerciseEligibilityV1 ===
    ExerciseEligibilityV1Enum.NotEligible
  ) {
    return undefined;
  }

  let label: JSX.Element;

  const dpExerciseEligibilityIsEligible =
    dpExerciseEligibility as ExerciseEligibilityV1IsEligible;
  const firstDisruption = dpExerciseEligibilityIsEligible?.slices
    .find((s) => s.EligibilitySlice === EligibilitySliceEnum.IsEligible)
    ?.segments.find(
      (segment) =>
        segment.EligibilitySegment === EligibilitySegmentEnum.IsEligible
    )?.disruption as FlightDisruptionIsDisrupted;

  const firstReason = firstDisruption?.reasons && firstDisruption.reasons[0];

  if (!firstReason) {
    return undefined;
  }

  switch (firstReason.Reason) {
    case FlightDisruptionReasonEnum.AirlineCancellation:
      label = (
        <>
          {textConstants.DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_PREFIX}
          <span className="cta" onClick={goToDisruptionProtection}>
            <strong>
              {textConstants.DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_LABEL}
            </strong>
          </span>
          {textConstants.DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_SUFFIX}
        </>
      );
      break;
    case FlightDisruptionReasonEnum.Delayed:
      label = (
        <>
          {textConstants.DISRUPTION_BANNER_DELAY_LINK_PREFIX}
          <span className="cta" onClick={goToDisruptionProtection}>
            <strong>{textConstants.DISRUPTION_BANNER_DELAY_LINK_LABEL}</strong>
          </span>
          {textConstants.DISRUPTION_BANNER_DELAY_LINK_SUFFIX}
        </>
      );
      break;
    case FlightDisruptionReasonEnum.MissedConnection:
      label = (
        <>
          {textConstants.DISRUPTION_BANNER_MISSED_CONNECTION_LINK_PREFIX}
          <span className="cta" onClick={goToDisruptionProtection}>
            <strong>
              {textConstants.DISRUPTION_BANNER_MISSED_CONNECTION_LINK_LABEL}
            </strong>
          </span>
          {textConstants.DISRUPTION_BANNER_MISSED_CONNECTION_LINK_SUFFIX}
        </>
      );
      break;
    default:
      label = (
        <>
          {textConstants.DISRUPTION_BANNER_DEFAULT_LINK_PREFIX}
          <span className="cta" onClick={goToDisruptionProtection}>
            <strong>
              {textConstants.DISRUPTION_BANNER_DEFAULT_LINK_LABEL}
            </strong>
          </span>
          {textConstants.DISRUPTION_BANNER_DEFAULT_LINK_SUFFIX}
        </>
      );
  }

  const content = (
    <Typography className="label" variant="subtitle2">
      {label}
    </Typography>
  );

  return (
    <NotificationBanner
      className={isMobile ? "mobile-status-banner" : "status-banner"}
      content={content}
      severity={BannerSeverity.ERROR}
      icon={<Icon name={IconName.DisruptionProtectionRed} />}
      onClick={isMobile ? goToDisruptionProtection : undefined}
    />
  );
};

const getCancelledNotificationLabel = (ftc?: TravelCredit) => {
  let label = textConstants.CANCELLED_ITINERARY;

  if (ftc) {
    const {
      credit: { amount, currency },
    } = ftc;

    label = `${label} for an airline travel credit valued at ${currency} ${amount.toFixed(
      2
    )}.`;
  } else {
    label = `${label}.`;
  }

  return label;
};

const getMajorScheduleChangeBanner = (
  scheduleChange: ScheduleChange,
  airlineMap: AirlineMap,
  isMobile?: boolean,
  openModal?: () => void
) => {
  const label = textConstants.MAJOR_SCHEDULE_CHANGE_LABEL(
    getAirlineName(airlineMap, scheduleChange.next[0].segments),
    // TODO   waiting on backend property. hard coding to 24 hours until then
    textConstants.TWENTY_FOUR_HOURS
  );

  return (
    <NotificationBanner
      className={!isMobile ? "status-banner" : "mobile-status-banner"}
      label={label}
      cta={textConstants.MAJOR_SCHEDULE_CHANGE_BANNER_CTA_LABEL}
      severity={BannerSeverity.ERROR}
      icon={<Icon name={IconName.ErrorAlert} />}
      onClick={openModal}
    />
  );
};

const getMinorScheduleChangeBanner = (isMobile?: boolean) => {
  return (
    <NotificationBanner
      className={!isMobile ? "status-banner" : "mobile-status-banner"}
      label={textConstants.MINOR_SCHEDULE_CHANGE_LABEL}
      severity={BannerSeverity.WARNING}
      icon={<Icon name={IconName.WarningAlert} />}
    />
  );
};

const getCancelledSegmentBanner = (
  changesNumber: number,
  isMobile?: boolean
) => {
  return (
    <NotificationBanner
      className={!isMobile ? "status-banner" : "mobile-status-banner"}
      label={textConstants.GET_CANCELLED_SEGMENT_BANNER_LABEL(changesNumber)}
      severity={BannerSeverity.ERROR}
      icon={<Icon name={IconName.WarningAlert} />}
    />
  );
};

const getMcpViflightBanner = (
  flight: BookedFlightItineraryWithDepartureTime,
  isMobile: boolean,
  setOpenModal: () => void
) => {
  const viFlightHasBeenRebooked =
    flight?.ancillaries?.missedConnectionVi?.status.DisruptionContractStatus ===
    DisruptionContractStatusEnum.Rebook;
  const isNewItineraryFromMcpVi = isDisriptionRebook(
    flight?.disruptionProtectionRebook
  );
  const textContent = isNewItineraryFromMcpVi
    ? textConstants.BOOKED_WITH_MISSED_CONNECTION_GUARANTEE
    : viFlightHasBeenRebooked
    ? textConstants.PROTECTED_BY_MCR_VI_REBOOKED
    : textConstants.PROTECTED_BY_MCR_VI;

  const handleClick = () => {
    if (isNewItineraryFromMcpVi) {
      return;
    }
    if (viFlightHasBeenRebooked) {
      return;
    }
    setOpenModal();
  };

  return (
    <NotificationBanner
      className={clsx("vi-mcp-banner", {
        "mobile-status-banner": isMobile,
        "status-banner": !isMobile,
      })}
      html={textContent}
      onClick={handleClick}
      severity={BannerSeverity.IMPROVEMENT}
      icon={<Icon name={IconName.CheckCircleFilledGreen} />}
    />
  );
};

export const getStatusTag = (
  flight: BookedFlightItineraryWithDepartureTime,
  sliceIndex: number,
  scheduleChange?: ScheduleChange,
  airportMap: { [key: string]: Airport } = {},
  airlineMap: AirlineMap = {},
  isTF: boolean = false
): TagInfo | undefined => {
  const hasUnknownScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Unknown;
  const ogSlice = getSlicesFromTravelItinerary(
    flight.bookedItinerary.travelItinerary
  )[sliceIndex];
  let airlineName;

  if (!ogSlice?.segments || isTF) return undefined;

  airlineName = getAirlineName(airlineMap, ogSlice.segments);

  for (let i = 0; i < ogSlice!.segments.length; i++) {
    const segment = ogSlice!.segments[i];
    switch (segment.status) {
      // is this a typo in the BE
      case FlightItinerarySegmentStatusEnum.Canceled:
      case FlightItinerarySegmentStatusEnum.Cancelled:
        return {
          label: textConstants.CANCELLED,
          type: TagColors.RED,
        };
      case FlightItinerarySegmentStatusEnum.UnMapped:
      case FlightItinerarySegmentStatusEnum.UnMappedPersisted:
      case FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange:
        const { next: slices = [], severity } = scheduleChange ?? {};
        const updatedSlice = slices[sliceIndex];
        const sliceModified = isUpdated(updatedSlice);
        const segmentHasMajorChange =
          severity === ScheduleChangeSeverity.Major && sliceModified;
        const segmentHasMinorChange =
          severity === ScheduleChangeSeverity.Minor && sliceModified;
        let statusTag = undefined;

        if (updatedSlice?.segments) {
          airlineName = getAirlineName(airlineMap, updatedSlice.segments);
        }

        /**
         * TODO: currently only returns status tag if there is a
         * segmentMajorChange or segmentMajorChange (meaning we are getting
         * the scheduleChange data back) but there is currently an issue with
         * minor change and the scheduleChange object returned so this may
         * change when unknown/automatic minor changes (like exchanges) are
         * sorted out
         */
        if (
          !hasUnknownScheduleChange &&
          (segmentHasMajorChange || segmentHasMinorChange)
        ) {
          statusTag = {
            label: textConstants.MODIFIED,
            type: segmentHasMajorChange ? TagColors.RED : TagColors.YELLOW,
            iconName: IconName.InfoCircle,
            tooltipCopy: segmentHasMinorChange
              ? textConstants.getMinorScheduleChangeTooltip(
                  sliceIndex,
                  airlineName,
                  undefined,
                  airportMap
                )
              : textConstants.MODIFIED_TOOLTIP(
                  airlineName,
                  textConstants.TWENTY_FOUR_HOURS
                ),
          };
        }

        return statusTag;
      case FlightItinerarySegmentStatusEnum.Pending:
        const itinerary = getTravelItinerary(
          flight.bookedItinerary.travelItinerary
        );
        if (
          itinerary &&
          isMultiTicket(flight.bookedItinerary.multiTicketType) &&
          isTFBooking(itinerary[sliceIndex])
        ) {
          /**
           * Currently there is an issue with MultiTicketType and London status return from the BE
           * Within London every response returns with segmentStatus Pending
           * this is the ideal escenario
           * LongPoll -> pending, not longpoll -> Confirmed
           * currently until is fixed by the supply team
           * LongPoll -> pending, not LongPoll -> pending
           */
          return undefined;
        }
        return {
          label: textConstants.PENDING,
          type: TagColors.GREY,
          iconName: IconName.InfoCircle,
          tooltipCopy: textConstants.PENDING_TOOLTIP,
        };
      default:
    }
  }
  return undefined;
};

export const getTitleTag = (
  flight: BookedFlightItineraryWithDepartureTime,
  airlineMap: AirlineMap,
  hasMajorScheduleChange?: boolean
): TagInfo | undefined => {
  const travelItinerary = flight?.bookedItinerary
    .travelItinerary as SingleTravelItinerary;

  switch (flight.status) {
    case PortalItineraryStatusEnum.Pending:
      return {
        label: "Pending",
        type: TagColors.GREY,
        iconName: IconName.InfoCircle,
        tooltipCopy: textConstants.PENDING_TOOLTIP,
      };
    case PortalItineraryStatusEnum.Canceled:
      return {
        label: "Canceled",
        type: TagColors.RED,
        iconName: hasMajorScheduleChange ? IconName.InfoCircle : undefined,
        tooltipCopy: hasMajorScheduleChange
          ? textConstants.CANCELED_TOOLTIP(
              getAirlineName(
                airlineMap,
                getItineraryOutboundSegments(travelItinerary)
              )
            )
          : "",
      };
    case PortalItineraryStatusEnum.Modified:
      return {
        label: "Itinerary Modified",
        type: hasMajorScheduleChange ? TagColors.RED : TagColors.YELLOW,
        iconName: hasMajorScheduleChange ? IconName.InfoCircle : undefined,
        tooltipCopy: hasMajorScheduleChange
          ? textConstants.MODIFIED_TOOLTIP(
              getAirlineName(
                airlineMap,
                getItineraryOutboundSegments(travelItinerary)
              )
            )
          : "",
      };
    default:
      return undefined;
  }
};

export const getStatusBanner = (
  flight: BookedFlightItineraryWithDepartureTime,
  hasMajorScheduleChange: boolean,
  hasMinorScheduleChange: boolean,
  isMobile: boolean,
  airlineMap: IFlightCardProps["airlineMap"],
  setOpenModal: (modal: IOpenModal) => void,
  isVflight?: boolean,
  mcpViItineraries?: BookedFlightItineraryWithDepartureTime[]
) => {
  const {
    bookedItinerary: { scheduleChange, travelItinerary },
    status,
    travelCredit,
  } = flight;
  const originalItineraryId = getOriginalItineraryId(
    flight?.disruptionProtectionRebook
  );
  const rebookedFlightFromMcpVi = Boolean(
    mcpViItineraries?.find((itinerary) => {
      return originalItineraryId === itinerary.bookedItinerary.id;
    })
  );

  const unknownScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Unknown;
  const itineraries = getTravelItinerary(travelItinerary);

  const changesNumber = itineraries?.reduce(
    (acc, val) =>
      val.postTicketingStatus === PostTicketingStatus.Canceled ? acc + 1 : acc,
    0
  );

  switch (status) {
    case PortalItineraryStatusEnum.Canceled:
      const label = getCancelledNotificationLabel(travelCredit);

      return (
        <NotificationBanner
          className="status-banner"
          label={label}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.ErrorAlert} />}
        />
      );
    default: {
      if (hasMajorScheduleChange && !unknownScheduleChange) {
        return getMajorScheduleChangeBanner(
          scheduleChange!,
          airlineMap as AirlineMap,
          isMobile,
          () =>
            setOpenModal({
              type: MyTripsModalTypes.ScheduleChange,
              selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
            })
        );
      } else if (hasMinorScheduleChange && !unknownScheduleChange) {
        return getMinorScheduleChangeBanner(isMobile);
      }

      if (isVflight || rebookedFlightFromMcpVi) {
        return getMcpViflightBanner(flight, isMobile, () =>
          setOpenModal({
            type: MyTripsModalTypes.VirtualInterlineMcp,
            selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
          })
        );
      }

      if (changesNumber) {
        return getCancelledSegmentBanner(changesNumber, isMobile);
      }

      return undefined;
    }
  }
};
