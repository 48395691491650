import {
  ExperienceReservation,
  ExperienceReservationResponse,
  MyTripsFilter,
} from "redmond";
import { put, call } from "redux-saga/effects";
import { fetchExperiences } from "../../../api/v1/itinerary/fetchExperiences";
import { actions } from "../actions";
import { setUpMyTripsParams } from "./setUpTripsParams";

export function* fetchExperiencesSaga(action: actions.IFetchExperiences) {
  try {
    const { tripId } = yield call(setUpMyTripsParams, action);
    const experiencesReservationResponse: ExperienceReservationResponse =
      yield fetchExperiences(action.request);

    const {
      future: newFuture,
      canceled: newCanceled,
      past: newPast,
      present: newPresent,
    } = experiencesReservationResponse.itineraries;

    let selectedExperience = null;
    let isSelectedExperienceInPastTrips = false;
    selectedExperience = [...newFuture].find(
      (experience: ExperienceReservation) =>
        experience.reservation.customerReservationId === tripId
    );
    if (!selectedExperience) {
      const pastSelectedExperience = [...newCanceled].find(
        (experience: ExperienceReservation) =>
          experience.reservation.customerReservationId === tripId
      );
      selectedExperience = pastSelectedExperience;
      isSelectedExperienceInPastTrips = !!pastSelectedExperience;
    }

    if (isSelectedExperienceInPastTrips) {
      yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
      yield put(actions.populateTripQueryParams(action.history, { tripId }));
    }
    if (selectedExperience) {
      yield put(
        actions.setSelectedExperience({
          ...selectedExperience,
        })
      );
    }

    yield put(
      actions.setExperiences(
        {
          present: newPresent ? newPresent.map((x) => x) : [],
          canceled: newCanceled ? newCanceled.map((x) => x) : [],
          past: newPast ? newPast.map((x) => x) : [],
          future: newFuture ? newFuture.map((x) => x) : [],
        },
        action.request.states
      )
    );
  } catch (e) {
    yield put(actions.fetchExperiencesFailed(action.request));
    console.error(e);
  }
}
