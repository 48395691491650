import { useMemo } from "react";
import { CorpExperimentsMap } from "../types";
import { useExperiments } from ".";

export const useExperimentsById = <T extends keyof CorpExperimentsMap>(
  id: T
): CorpExperimentsMap[T] | undefined => {
  const { experiments } = useExperiments();

  return useMemo(() => experiments[id], [experiments, id]);
};
