import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import clsx from "clsx";
import {
  MyTripsCard,
  TripInfoDetails,
  ActionLinks,
  IconName,
  IActionLink,
  ActionLink,
} from "halifax";
import {
  IOpenModal,
  ItineraryWithType,
  ItineraryEnum,
  MyTripsModalTypes,
  ExperienceReservation,
  FiatPrice,
} from "redmond";

import {
  MOBILE_OFFSET_SCROLL,
  DESKTOP_OFFSET_SCROLL,
} from "../../../../constants";
import * as textConstants from "../../constants";

import "./styles.scss";

import { ConfirmationSummary } from "../../../ConfirmationSummary";
import { SummaryCard } from "../../../SummaryCard";
import { TravelersSummary } from "../../../TravelersSummary";
import { PaymentSummary } from "../../../PaymentSummary";
import { getCurrencyString } from "../../../../../../../../utils/helpers";
import { ExperienceMap } from "../ExperienceMap";
import { ExperienceCardContent } from "../ExperienceCardContent";
import { ExperienceCancellationPolicyCard } from "../ExperienceCancellationPolicyCard";

interface IDesktopPackageCardProps {
  expandedCard: string;
  experienceReservation: ExperienceReservation;
  isPastTrips?: boolean;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isCancel?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  travelerNames: string[];
}

export const addExperienceType = (
  experienceReservation: ExperienceReservation
): ItineraryWithType => ({
  ...experienceReservation,
  type: ItineraryEnum.Experience,
});

export const DesktopExperienceCard = (props: IDesktopPackageCardProps) => {
  const {
    expandedCard,
    experienceReservation,
    isMobile,
    onExpandCard,
    setOpenModal,
    banner,
    isCancel,
    isPastTrips = false,
    confirmationCodeClassName,
    travelerNames,
  } = props;

  const { reservation, paymentBreakdown } = experienceReservation;
  const {
    customerReservationId,
    bookingSession,
    experienceDetails,
    ticketInfo,
    cancellationPolicy,
  } = reservation;

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addExperienceType(experienceReservation),
    });
  const showCancelButton = !isPastTrips;

  const actions = useMemo(() => {
    const actionsArr: IActionLink[] = [];

    if (!isCancel && showCancelButton) {
      actionsArr.push({
        content: textConstants.CANCEL_EXPERIENCE,
        onClick: () => onOpenModal(MyTripsModalTypes.CancelExperience),
      });
    }

    actionsArr.push({
      content: textConstants.RESEND_CONFIRMATION,
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });

    if (ticketInfo?.url) {
      actionsArr.push({
        content: textConstants.SHOW_VOUCHER,
        onClick: () => {
          window.open(ticketInfo.url, "_blank");
        },
      });
    }

    return actionsArr;
  }, [isCancel]);

  const {
    supplierName: streetAddress,
    phone: postalCode,
    email: phoneNum,
  } = textConstants.getExperienceInfoDetails(experienceReservation);
  return (
    <Box
      id={customerReservationId}
      key={customerReservationId}
      className={clsx("experience-trip-container", {
        mobile: isMobile,
        expanded: expandedCard === customerReservationId,
      })}
    >
      <MyTripsCard
        className="trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            hideTitleTag={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            titles={{
              streetAddress,
              postalCode,
              phoneNum,
              ...textConstants.getExperienceInfoDetails(experienceReservation),
            }}
          />
        }
        actions={<ActionLinks actions={actions} />}
        expandString={
          expandedCard === customerReservationId
            ? textConstants.VIEW_LESS
            : textConstants.VIEW_DETAILS
        }
        expandIcon={
          expandedCard === customerReservationId
            ? IconName.MinusBlueCircle
            : IconName.PlusBlueCircle
        }
        isExpanded={expandedCard === customerReservationId}
        topRightAction={null}
        onExpandClick={() => {
          onExpandCard(customerReservationId);
          setTimeout(() => {
            const OFFSET = isMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document
                ?.getElementById(customerReservationId)
                ?.getBoundingClientRect().top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: isMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
        travelers={travelerNames}
      />{" "}
      {customerReservationId === expandedCard && (
        <Box className="experience-desktop-expanded-container">
          <Box className="experience-summary-container">
            {experienceDetails?.logistics && (
              <ExperienceMap locationLogistics={experienceDetails.logistics} />
            )}
            <Box className="experience-details">
              <ExperienceCardContent
                reservation={reservation}
                onOpenModal={onOpenModal}
              />
              {cancellationPolicy && !isMobile && (
                <ExperienceCancellationPolicyCard
                  cancellationPolicy={cancellationPolicy}
                  onOpenModal={onOpenModal}
                />
              )}
            </Box>
          </Box>
          <Box className="summary-info-container">
            <SummaryCard
              className="travellers-summary"
              action={
                travelerNames.length > 1 && (
                  <ActionLink
                    className="details-link"
                    onClick={() =>
                      onOpenModal(MyTripsModalTypes.TravelersModal)
                    }
                    content={textConstants.DETAILS}
                  />
                )
              }
            >
              <TravelersSummary
                label={
                  travelerNames.length > 1
                    ? textConstants.TRAVELERS(travelerNames)
                    : textConstants.TRAVELER
                }
                travelers={travelerNames.join(", ")}
              />
            </SummaryCard>
            {paymentBreakdown && (
              <SummaryCard
                className="payment-summary"
                action={
                  <ActionLink
                    className="details-link"
                    onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                    content={textConstants.DETAILS}
                  />
                }
              >
                <PaymentSummary
                  tripTotalAmount={getCurrencyString(
                    bookingSession?.totalSellAtPriceWithFees?.fiat as FiatPrice
                  )}
                  cardLabel={
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).cardLabel
                  }
                  cardAmount={
                    textConstants.getValues(paymentBreakdown).cardValue
                  }
                  rewardsLabel={
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).rewardLabel
                  }
                  rewardsAmount={
                    textConstants.getValues(paymentBreakdown).rewardValue
                  }
                  showCardLabel={Boolean(
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).cardLabel
                  )}
                  showRewardsLabel={Boolean(
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).rewardLabel
                  )}
                />
              </SummaryCard>
            )}
            <SummaryCard className="confirmation-summary">
              <ConfirmationSummary confirmationCode={customerReservationId} />
            </SummaryCard>
          </Box>
        </Box>
      )}
    </Box>
  );
};
