import {
  CallState,
  CreditDetail,
  RedeemedEarnOffersResponse,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";
import { actions, actionTypes } from "../actions";

export interface IMyTripsTravelWalletState {
  offers: TravelWalletOffer[];
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
  fetchTravelWalletDetailsCallState: CallState;
  redeemedEarnOffers: RedeemedEarnOffersResponse["redeemedEarnOffers"];
  fetchRedeemedEarnOffersCallState: CallState;
}

export const initialState: IMyTripsTravelWalletState = {
  offers: [],
  credit: undefined,
  creditBreakdown: [],
  fetchTravelWalletDetailsCallState: CallState.NotCalled,
  redeemedEarnOffers: [],
  fetchRedeemedEarnOffersCallState: CallState.NotCalled,
};

export function reducer(
  state: IMyTripsTravelWalletState = initialState,
  action: actions.MyTripsTravelWalletActions
): IMyTripsTravelWalletState {
  switch (action.type) {
    case actionTypes.FETCH_TRAVEL_WALLET_DETAILS:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.Failed,
      };

    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };

    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
        creditBreakdown: action.creditBreakdown,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };

    case actionTypes.FETCH_REDEEMED_EARN_OFFERS:
      return {
        ...state,
        fetchRedeemedEarnOffersCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_REDEEMED_EARN_OFFERS_STATE_FAILED:
      return {
        ...state,
        fetchRedeemedEarnOffersCallState: CallState.Failed,
      };

    case actionTypes.SET_REDEEMED_EARN_OFFERS:
      return {
        ...state,
        redeemedEarnOffers: action.redeemedEarnOffers,
        fetchRedeemedEarnOffersCallState: CallState.Success,
      };

    default:
      return state;
  }
}

export * from "./selectors";
