import { put } from "redux-saga/effects";
import { RedeemedEarnOffersResponse } from "redmond";
import { fetchRedeemedEarnOffers } from "../../../api/v1/travel-wallet/fetchRedeemedEarnOffers";
import { actions } from "../actions";

export function* fetchRedeemedEarnOffersSaga(
  _: actions.IFetchTravelOffersDetails
) {
  try {
    const redeemedEarnOffersResponse: RedeemedEarnOffersResponse =
      yield fetchRedeemedEarnOffers();
    yield put(
      actions.setRedeemedEarnOffers(
        redeemedEarnOffersResponse.redeemedEarnOffers
      )
    );
  } catch {
    yield put(actions.setFetchRedeemedEarnOffersStateFailed());
  }
}
