import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../../../reducers/types";
import { setOpenModal } from "../../../../../../actions/actions";
import CancelExperienceModalContent from "./component";

const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CancelExperienceModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCancelExperienceModalContent = connector(
  withRouter(CancelExperienceModalContent)
);
