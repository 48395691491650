import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ExperiencesCancelScenario,
  ExperiencesCancelScenarioEnum,
  Maybe,
  PollCancelQuoteSuccess,
  ScheduleCancelQuoteRequest,
  ResponseEnum,
  PollCancelQuoteRequest,
  ExperienceReservation,
  ScheduleCancelFulfillRequest,
} from "redmond";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  ActionButton,
  B2BSpinnerWithText,
  GenericModalContent,
  Icon,
  ImportantInfoList,
  IconName,
  twoDecimalFormatter,
} from "halifax";
import { RouteComponentProps } from "react-router-dom";
import dayjs from "dayjs";
import * as constants from "./constants";
import getExperiencesCancelQuoteSchedule from "../../../../../../../../api/v1/itinerary/getExperiencesCancelQuoteSchedule";
import getExperiencesCancelQuotePoll from "../../../../../../../../api/v1/itinerary/getExperiencesCancelQuotePoll";
import "./styles.scss";
import { CancelExperienceModalContentConnectorProps } from "./container";
import getExperiencesCancelFulfillSchedule from "../../../../../../../../api/v1/itinerary/getExperiencesCancelFulfillSchedule";
import getExperiencesCancelFulfillPoll from "../../../../../../../../api/v1/itinerary/getExperiencesCancelFulfillPoll";
import * as generalConstants from "../../constants";

export interface ICancelExperienceModalContentProps
  extends CancelExperienceModalContentConnectorProps,
    RouteComponentProps {
  experience: ExperienceReservation;
}

export enum CancelStep {
  LoadingPolicy,
  CancellationInfo,
  ConfirmCancellation,
  CancellationProcessing,
  CancellationSuccess,
  CancellationFailure,
}

const CancelExperienceModalContent = (
  props: ICancelExperienceModalContentProps
) => {
  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingPolicy
  );
  const [cancelScenario, setCancelScenario] =
    useState<Maybe<ExperiencesCancelScenario>>(null);

  const [pollQuoteReq, setPollQuoteReq] =
    useState<Maybe<PollCancelQuoteRequest>>(null);

  const {
    experience: { reservation },
    setOpenModal,
    // history,
  } = props;

  const cancelQuotePoll = async (quoteId: string, sessionId: string) => {
    let pollFailed = false;
    while (!pollFailed) {
      // eslint-disable-next-line no-await-in-loop
      const pollRes = await getExperiencesCancelQuotePoll({
        quoteId,
        sessionId,
      });

      switch (pollRes.PollCancelQuoteResponse) {
        case ResponseEnum.Success:
          switch (
            (pollRes as PollCancelQuoteSuccess).cancelScenario
              .ExperiencesCancelScenario
          ) {
            case ExperiencesCancelScenarioEnum.AlreadyCanceled:
            case ExperiencesCancelScenarioEnum.BookingPending:
            case ExperiencesCancelScenarioEnum.CancellationPending:
            case ExperiencesCancelScenarioEnum.ContactCustomerService:
            case ExperiencesCancelScenarioEnum.FullyRefundable:
            case ExperiencesCancelScenarioEnum.NonCancellable:
            case ExperiencesCancelScenarioEnum.NonRefundable:
            case ExperiencesCancelScenarioEnum.PartiallyRefundable:
            case ExperiencesCancelScenarioEnum.PastExperienceStart:
              setCancelStep(CancelStep.CancellationInfo);
              break;
            default:
              setCancelStep(CancelStep.LoadingPolicy);
          }
          setCancelScenario(pollRes.cancelScenario);
          return;
        case ResponseEnum.Pending:
          break;
        case ResponseEnum.Failure:
        default:
          pollFailed = true;
      }
    }
  };

  const getCancelInfo = async (
    customerReservationId: string,
    orderedProductId: string
  ) => {
    const scheduleQuoteReq: ScheduleCancelQuoteRequest = {
      customerReservationId,
      orderedProductId,
    };
    setCancelStep(CancelStep.LoadingPolicy);
    const scheduleQuoteRes = await getExperiencesCancelQuoteSchedule(
      scheduleQuoteReq
    );

    if (scheduleQuoteRes.Response === ResponseEnum.Success) {
      const { quoteId, sessionId } = scheduleQuoteRes.value;
      setPollQuoteReq(scheduleQuoteRes.value);
      cancelQuotePoll(quoteId, sessionId);
    }
  };

  const closeModal = useCallback(
    () =>
      setOpenModal({
        type: null,
        selectedItinerary: null,
      }),
    [setOpenModal]
  );

  const prepareCancellation = useCallback(() => {
    if (reservation.bookingSession?.cartOrderedProductId)
      getCancelInfo(
        reservation.customerReservationId,
        reservation.bookingSession?.cartOrderedProductId
      );
  }, [reservation]);

  useEffect(() => {
    prepareCancellation();
  }, [prepareCancellation]);

  const cancelFulfillPoll = async (quoteId: string, sessionId: string) => {
    let pollFailed = false;
    while (!pollFailed) {
      // eslint-disable-next-line no-await-in-loop
      const pollFulfillRes = await getExperiencesCancelFulfillPoll({
        quoteId,
        sessionId,
      });

      switch (pollFulfillRes.PollCancelFulfillResponse) {
        case ResponseEnum.Success:
          setCancelStep(CancelStep.CancellationSuccess);
          return;
        case ResponseEnum.Pending:
          break;
        case ResponseEnum.Failure:
        default:
          setCancelStep(CancelStep.CancellationFailure);
          pollFailed = true;
      }
    }
  };

  const confirmCancellation = async (quoteId: string) => {
    const req: ScheduleCancelFulfillRequest = {
      quoteId,
      cancelReasonCode: "Customer_Service.I_canceled_my_entire_trip", // hardcoding this for MVP
    };
    setCancelStep(CancelStep.CancellationProcessing);
    const scheduleFulfillRes = await getExperiencesCancelFulfillSchedule(req);
    if (scheduleFulfillRes.Response === ResponseEnum.Success) {
      const { quoteId: fulfillQuoteId, sessionId } = scheduleFulfillRes.value;
      setPollQuoteReq(scheduleFulfillRes.value);
      cancelFulfillPoll(fulfillQuoteId, sessionId);
    }
  };

  const ExperienceInfo = () => {
    const {
      experienceDetails,
      experienceSelection: { travelDate, providerExperienceStartTime },
    } = reservation;
    if (!experienceDetails) return null;
    return (
      <Box className="experience-details-container">
        <Typography variant="body1" className="name">
          {experienceDetails?.name}
        </Typography>
        <Typography variant="body1" className="date-and-time">
          {dayjs(travelDate).format("ddd, MMM D")}
          {providerExperienceStartTime && (
            <>
              <span className="separator">|</span>
              {dayjs(providerExperienceStartTime, "HH:mm").format("h:mm A")}
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const renderCancellationInfo = () => {
    const { cancelCopy } = cancelScenario || {};

    return (
      <Box className="cancellation-info-container">
        <Typography variant="h2" className="modal-title">
          {cancelCopy?.title}
        </Typography>
        <Box>
          <Typography variant="body2">{cancelCopy?.body}</Typography>
        </Box>
        <Divider className="modal-divider" />
        <ExperienceInfo />
        {cancelScenario && "refundSummary" in cancelScenario && (
          <>
            <Divider className="modal-divider" />
            <Box className="refund-amount">
              <Typography variant="body2">
                {constants.TOTAL_AMOUNT_REFUNDED}
              </Typography>
              <Typography className="amount">
                {
                  cancelScenario?.refundSummary.totalCompensation.fiat
                    .currencySymbol
                }
                {twoDecimalFormatter(
                  cancelScenario?.refundSummary.totalCompensation.fiat.value
                )}
              </Typography>
            </Box>
          </>
        )}
        {(cancelCopy?.importantInfo?.length ?? 0) > 0 ? (
          <Box className="info-items-container">
            <Divider className="modal-divider" />
            <ImportantInfoList
              infoItems={cancelCopy?.importantInfo}
              title={constants.CANCEL_INFO_TITLE}
            />
          </Box>
        ) : undefined}
        <Divider className="modal-divider" />
        <ActionButton
          className="cancel-button"
          defaultStyle="h4r-primary"
          message={cancelCopy?.callToAction ?? "Cancel experience"}
          onClick={() => {
            setCancelStep(CancelStep.ConfirmCancellation);
          }}
        />
      </Box>
    );
  };

  const renderConfirmCancellation = () => {
    if (cancelScenario && "cancelConfirmationCopy" in cancelScenario) {
      return (
        <Box className="confirm-cancellation-container">
          <Typography className="modal-title">
            {cancelScenario.cancelConfirmationCopy!.title}
          </Typography>
          {cancelScenario.cancelConfirmationCopy!.body.map((cancelInfo) => (
            <Typography variant="body2">{cancelInfo}</Typography>
          ))}

          <Divider className="modal-divider" />
          <ExperienceInfo />

          <Divider className="modal-divider" />
          <ActionButton
            className="confirm-cancel-button"
            defaultStyle="h4r-primary"
            message={
              cancelScenario.cancelConfirmationCopy!.callToAction ??
              "Cancel experience"
            }
            onClick={() => {
              confirmCancellation(pollQuoteReq?.quoteId ?? "");
            }}
          />
        </Box>
      );
    }
    return null;
  };
  const renderLoading = useCallback(
    () => <B2BSpinnerWithText subtitle={constants.LOADING_POLICY} />,
    []
  );

  const renderCancellationProcessing = useCallback(
    () => <B2BSpinnerWithText subtitle={constants.PROCESSING_CANCEL} />,
    []
  );

  const renderCancellationSuccess = (closeCancellationModal: () => void) => (
    <GenericModalContent
      className="homes-cancel-modal-success"
      title={generalConstants.CANCELLATION_SUCCESS_TITLE}
      subtitle={generalConstants.CANCELLATION_SUCCESS_COPY}
      image={<Icon className="success-icon" name={IconName.Checked} />}
      actions={
        <ActionButton
          defaultStyle="h4r-primary"
          message={constants.BACK_TO_TRIPS}
          onClick={closeCancellationModal}
        />
      }
    />
  );

  const renderCancellationFailure = (closeCancellationModal: () => void) => (
    <Box className="cancellation-info-container">
      <Typography variant="h2" className="modal-title">
        {constants.SOMETHING_WENT_WRONG}
      </Typography>
      <Typography variant="body2">
        {constants.DEFAULT_CUSTOMER_SERVICE_CANCEL_BODY}
      </Typography>

      <Divider className="modal-divider" />
      <ActionButton
        className="confirm-cancel-button"
        defaultStyle="h4r-primary"
        message={constants.BACK_TO_TRIPS}
        onClick={closeCancellationModal}
      />
    </Box>
  );

  const ModalContent = useMemo(() => {
    switch (cancelStep) {
      case CancelStep.LoadingPolicy:
        return renderLoading();
      case CancelStep.CancellationInfo:
        return renderCancellationInfo();
      case CancelStep.CancellationProcessing:
        return renderCancellationProcessing();
      case CancelStep.ConfirmCancellation:
        return renderConfirmCancellation();
      case CancelStep.CancellationSuccess:
        return renderCancellationSuccess(() => {
          closeModal();
          // [TODO]: Update to go to that reservation once tripId query params for experiences is set up
          // history.push(
          //   `${PATH_HOME}?tripsFilter=Past%20Trips&tripId=${home.reservation.id.value}`
          // );
          window.location.reload();
        });
      case CancelStep.CancellationFailure:
        return renderCancellationFailure(() => {
          closeModal();
          window.location.reload();
        });
      default:
        return null;
    }
  }, [cancelStep]);

  return <Box className="cancel-experience-modal-content">{ModalContent}</Box>;
};

export default CancelExperienceModalContent;
