import React, { useEffect } from "react";
import { CarReservation, SelfServeEvents, VIEWED_CONTACT_MODAL } from "redmond";
import { ActionButton, ActionLink } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { CancelCarModalContentConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import { CONTACT_SUPPORT_URL } from "../../../../../../../../utils/paths";
import { getViewedCarContactModalProperties } from "../../../../../../reducer";

export interface ICancelCarModalContentProps
  extends CancelCarModalContentConnectorProps {
  car: CarReservation;
}

export const CancelCarModalContent = (props: ICancelCarModalContentProps) => {
  const { setOpenModal, car } = props;
  const isAfter = dayjs().isAfter(dayjs(car.bookResult.pickUp.dateTime));

  useEffect(() => {
    if (car) {
      trackEvent({
        eventName: VIEWED_CONTACT_MODAL,
        properties: { ...getViewedCarContactModalProperties(car) },
      });
    }
  }, [car]);

  return (
    <Box className="change-car-modal-content">
      <Typography className="change-car-title" variant="h2">
        {constants.HEADING}
      </Typography>
      <Typography className="change-car-subtitle" variant="h5">
        {isAfter ? constants.SUBHEADING : constants.SUBHEADING_AGENT}
      </Typography>
      <Box className="change-car-buttons">
        {isAfter ? (
          <ActionLink
            className="provider-action"
            onClick={() => {
              trackEvent({
                eventName: SelfServeEvents.ClickSupport,
                properties: {
                  product_funnel: "ground",
                },
              });

              window.open(CONTACT_SUPPORT_URL, "_blank", "noopener")?.focus();
            }}
            showTappableArea={true}
            content={
              <Typography variant="body2" className="check-in-link">
                {constants.PROVIDER_CTA}
                <FontAwesomeIcon
                  className="check-in-icon"
                  icon={faExternalLinkAlt}
                />
              </Typography>
            }
          />
        ) : (
          <ActionButton
            defaultStyle="h4r-primary"
            message={constants.CONTACT_SUPPORT}
            onClick={() => {
              trackEvent({
                eventName: SelfServeEvents.ClickSupport,
                properties: {
                  product_funnel: "ground",
                },
              });

              window.open(CONTACT_SUPPORT_URL, "_blank", "noopener")?.focus();
            }}
          />
        )}
        <ActionButton
          defaultStyle={"h4r-secondary"}
          message={constants.CANCEL}
          onClick={() => setOpenModal({ type: null, selectedItinerary: null })}
        />
      </Box>
    </Box>
  );
};
