import React from "react";
import { CarReservation, RedeemedEarnOffersResponse } from "redmond";
import { Typography, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";
import * as textConstants from "./constants";
import { ILineItem, formatPaymentBreakdown } from "../../../FlightCard/helpers";
import { twoDecimalFormatter, Icon, IconName } from "halifax";

interface ICarPaymentModalContentProps {
  car: CarReservation;
  redeemedEarnOffers: RedeemedEarnOffersResponse["redeemedEarnOffers"];
}

const formatFiatValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const CarPaymentModalContent = ({
  car,
  redeemedEarnOffers,
}: ICarPaymentModalContentProps) => {
  const { bookResult, paymentBreakdown } = car;
  const { rentalRate } = bookResult;
  const { totalCharge, feesSummary, payAtDesk, base } = rentalRate;

  // TODO: move this logic to a selector
  const getTotalPrice = () => {
    const currencyPrefix = `${totalCharge.fiat.currencyCode} ${totalCharge.fiat.currencySymbol}`;
    return `${currencyPrefix}${formatFiatValue(totalCharge.fiat.value)}`;
  };

  const lineItems = formatPaymentBreakdown(paymentBreakdown);

  const renderTripTotal = () => (
    <Box className="trip-total-row">
      <Typography variant="body1" className="trip-total-text">
        {textConstants.TRIP_TOTAL}
      </Typography>
      <Typography variant="body1" className="trip-total-text">
        {getTotalPrice()}
      </Typography>
    </Box>
  );

  const renderRewards = () => {
    const rewards = lineItems.rewards;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="rewards-container">
        <Typography variant="body2">{rewards.label}</Typography>
        <Typography variant="body1" className="rewards-points">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const userCard = lineItems.userCard;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography variant="body2" className="payment-card-details">
            {userCard.label}
          </Typography>
        </Box>
        <Typography variant="body1" className="payment-card-amount">
          {twoDecimalFormatter(userCard.amount || "")}
        </Typography>
      </Box>
    );
  };

  const renderBaseFare = () => {
    if (!Boolean(base)) {
      return null;
    }
    return (
      <Box className="fees-container">
        <Typography variant="body2" className="fees-details">
          {textConstants.BASE_FARE}
        </Typography>
        <Typography variant="body1" className="fees-amount">
          {`${base.currencyCode} ${base.currencySymbol}${twoDecimalFormatter(
            formatFiatValue(base.value)
          )}`}
        </Typography>
      </Box>
    );
  };

  const renderTaxesAndFees = () => {
    if (!Boolean(feesSummary.total)) {
      return null;
    }
    return (
      <Box className="fees-container">
        <Typography variant="body2" className="fees-details">
          {textConstants.TAXES_AND_FEES}
        </Typography>
        <Typography variant="body1" className="fees-amount">
          {`${feesSummary.total.currencyCode} ${
            feesSummary.total.currencySymbol
          }${formatFiatValue(feesSummary.total.value)}`}
        </Typography>
      </Box>
    );
  };

  const renderPayAtPickUp = () => {
    if (!Boolean(payAtDesk)) {
      return null;
    }
    return (
      <Box className="pay-at-pickup-container">
        <Typography variant="body2" className="pickup-details">
          {textConstants.DUE_AT_PICKUP}
        </Typography>
        <Typography variant="body1" className="pickup-amount">
          {`${payAtDesk.currencyCode} ${
            payAtDesk.currencySymbol
          }${formatFiatValue(payAtDesk.value)}`}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (
    travelWallet: ILineItem | null,
    icon: IconName
  ) => {
    if (!travelWallet) return null;
    return (
      <Box className="offers-container">
        <Box className="offers-icon-label">
          <Icon name={icon} />
          <Typography variant="body2">{travelWallet.label}</Typography>
        </Box>
        <Typography variant="body1" className="offer-amount">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    renderTravelWallet(lineItems.travelWalletOffer, IconName.OfferTag);

  const renderCredits = () =>
    lineItems.travelWalletCredit?.map((credit) =>
      renderTravelWallet(credit, IconName.PiggyBank)
    );

  const renderEarnOffer = () => (
    <Box className="earn-offer-container">
      {redeemedEarnOffers?.map((offer) => (
        <Box
          className="earn-offer-banner"
          key={`car-itinerary-earn-offer-${offer.redeemedDescription}`}
        >
          <Icon name={IconName.CheckCircleFilledGreen} />
          <Typography className="earn-offer-banner-text">
            {offer.redeemedDescription}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box className="car-payment-modal-content">
      <Box className="car-payment-title-container">
        <Typography variant="h4">{textConstants.TRIP_TOTAL}</Typography>
      </Box>
      {renderBaseFare()}
      {renderTaxesAndFees()}
      {renderTripTotal()}
      {renderOffers()}
      {renderCredits()}
      {renderRewards()}
      {renderPayment()}
      {renderPayAtPickUp()}
      {renderEarnOffer()}
    </Box>
  );
};
