import React from "react";
import { Box, Typography } from "@material-ui/core";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { ExperienceLocation, ExperienceLogistics, ICoordinates } from "redmond";
import { Icon, IconName, MapPin } from "halifax";

import styles from "./styles.module.scss";
import { convertICoordinatesToGoogleMapCoords } from "../../../../../../../../utils/googleMapsHelpers";
import {
  END_POINT,
  END_SAME_AS_START,
  STARTING_AND_END_POINT,
  STARTING_POINT,
} from "../../constants";

export interface IExperienceMapProps {
  locationLogistics: ExperienceLogistics;
}

export const renderAddress = (
  location: ExperienceLocation,
  sameAsStart?: boolean
) => {
  const [line1, ...rest] = location.address.split(",");
  const line2 = rest.join(",");

  return (
    <Box className={styles["experiences-map-address"]}>
      {sameAsStart ? (
        <Typography variant="body2">{END_SAME_AS_START}</Typography>
      ) : (
        <>
          {location.name && (
            <Typography variant="body2">{location.name}</Typography>
          )}
          <Typography variant="body2">{line1}</Typography>
          <Typography variant="body2">{line2}</Typography>
        </>
      )}
    </Box>
  );
};

export const ExperienceMap = ({ locationLogistics }: IExperienceMapProps) => {
  const sameAsStart =
    locationLogistics.startLocations?.[0]?.address ===
    locationLogistics.endLocations?.[0]?.address;

  const startCoordinates: ICoordinates[] = [];
  locationLogistics.startLocations.forEach((startLocation) => {
    startCoordinates.push({
      lat: startLocation.coordinates.lat - 0.00015,
      lon: startLocation.coordinates.lon - 0.003,
    });
  });

  const endCoordinates: ICoordinates[] = [];
  locationLogistics.endLocations.forEach((endLocation) => {
    endCoordinates.push({
      lat: endLocation.coordinates.lat - 0.00015,
      lon: endLocation.coordinates.lon - 0.003,
    });
  });

  let centerCoordinates = startCoordinates[0];
  if (!sameAsStart) {
    centerCoordinates = {
      lat: (startCoordinates[0].lat + endCoordinates[0].lat) / 2,
      lon: (startCoordinates[0].lon + endCoordinates[0].lon) / 2,
    };
  }
  const center = convertICoordinatesToGoogleMapCoords(centerCoordinates);

  return (
    <Box className={styles["large-map-container"]}>
      <GoogleMap
        id="large-map"
        mapContainerStyle={{
          height: "100%",
          width: "100%",
          borderRadius: "8px",
        }}
        options={{
          clickableIcons: false,
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: true,
        }}
        center={center}
        zoom={16}
      >
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={convertICoordinatesToGoogleMapCoords(startCoordinates[0])}
        >
          <Box className={styles["location-marker-wrapper"]}>
            <Box className={styles["location-tooltip"]}>
              <Typography
                variant="h6"
                className={styles["location-tooltip-title"]}
              >
                <Icon name={IconName.B2BMapPin} />
                {sameAsStart ? STARTING_AND_END_POINT : STARTING_POINT}
              </Typography>
              <Box className={styles["location-tooltip-content"]}>
                {renderAddress(locationLogistics.startLocations[0])}
              </Box>
            </Box>
            <MapPin />
          </Box>
        </OverlayView>
        {!sameAsStart && (
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={convertICoordinatesToGoogleMapCoords(endCoordinates[0])}
          >
            <Box className={styles["location-marker-wrapper"]}>
              <Box className={styles["location-tooltip"]}>
                <Typography
                  variant="h6"
                  className={styles["location-tooltip-title"]}
                >
                  <Icon
                    name={IconName.FlagIcon}
                    className={styles["end-location-icon"]}
                  />
                  {END_POINT}
                </Typography>
                <Box className={styles["location-tooltip-content"]}>
                  {renderAddress(locationLogistics.endLocations[0])}
                </Box>
              </Box>
              <MapPin />
            </Box>
          </OverlayView>
        )}
      </GoogleMap>
    </Box>
  );
};
