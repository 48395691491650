import { PATH_CORP_FAQ, isCorpTenant } from "@capone/common";
import { config } from "../api/config";

export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/trips/";
export const PATH_DISRUPTION_PROTECTION_OVERVIEW = PATH_HOME + "disruption/";
export const PATH_DISRUPTION_PROTECTION_CONTACT_SUPPORT =
  PATH_HOME + "disruption/contact-support/";
export const PATH_FROZEN_PRICES_LIST = `${PATH_HOME}?tripsFilter=Frozen%20Prices`;
export const PATH_DISRUPTION_PROTECTION_REFUND =
  PATH_DISRUPTION_PROTECTION_OVERVIEW + "refund/";
const PATH_DISRUPTION_PROTECTION_REBOOK =
  PATH_DISRUPTION_PROTECTION_OVERVIEW + "rebook/";
export const PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT = `${PATH_DISRUPTION_PROTECTION_OVERVIEW}rebook-connecting-flight/`;
export const PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT =
  PATH_DISRUPTION_PROTECTION_REBOOK + "flight/";
export const PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION =
  PATH_DISRUPTION_PROTECTION_REBOOK + "connection/";
export const PATH_FLIGHTS = BASE_PATH_HOME + "flights/";
export const PATH_FLIGHTS_SHOP = PATH_FLIGHTS + "shop/";
export const PATH_HOTELS = BASE_PATH_HOME + "hotels/";
export const TRIPS_PATH_HOTELS = PATH_HOME + "hotels/";
export const PATH_CARS = BASE_PATH_HOME + "cars/";
export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_DISRUPTION_PROTECTION_TERMS = `${PATH_TERMS}rapid-rebook/`;
export const PATH_TERMS_CFAR = `${PATH_TERMS}hotel-cancel-for-any-reason/`;
export const PATH_TERMS_DISRUPTION_PROTECTION = `${PATH_TERMS}rapid-rebook/`;
export const PATH_TERMS_PRICE_FREEZE_HOTEL = `${PATH_TERMS}frozen-prices-hotel/`;
export const PATH_PRICE_DROP_PROTECTION = `${PATH_TERMS}price-drop-protection/`;
export const PATH_MISSED_CONNECTION_GUARANTEE = `${PATH_TERMS}missed-connection-guarantee/`;
export const PATH_FLIGHT_FREEZE = PATH_FLIGHTS + "freeze/";
export const PATH_FLIGHT_FREEZE_OVERVIEW = `${PATH_FLIGHT_FREEZE}overview/`;
export const PATH_HOTEL_FREEZE = TRIPS_PATH_HOTELS + "freeze/";
export const PATH_HOTEL_FREEZE_OVERVIEW = `${PATH_HOTEL_FREEZE}overview/`;
export const PATH_CHANGE_FOR_ANY_REASON = PATH_HOME + "change/";
export const PATH_CHANGE_FOR_ANY_REASON_SEARCH = PATH_FLIGHTS + "change/";

export const PATH_EXCHANGE_FLIGHTS = "/exchange/flight";

export const CONTACT_SUPPORT_URL = isCorpTenant(config.TENANT)
  ? PATH_CORP_FAQ
  : "https://www.capitalone.com/help-center/capital-one-travel/";

export const HOTEL_PATH_SHOP = "/hotels/shop/";
export const HOTEL_PATH_AVAILABILITY = "/hotels/availability/";
export const HOTEL_PATH_BOOK = "/hotels/book/";

export const PATH_TRAVEL_SALE = `/travelsale/`;
