import React from "react";
import { Box } from "@material-ui/core";
import { ExperienceReservation } from "redmond";
import { ExperienceCancellationPolicy } from "halifax";

import styles from "./styles.module.scss";

export interface IExperienceCancellationPolicyModalContentProps {
  experienceReservation: ExperienceReservation;
}

export const ExperienceCancellationPolicyModalContent = ({
  experienceReservation,
}: IExperienceCancellationPolicyModalContentProps): JSX.Element | null => {
  const { cancellationPolicy, experienceSelection } =
    experienceReservation.reservation;

  if (!cancellationPolicy) return null;

  return (
    <Box className={styles["experience-cancellation-modal-content-container"]}>
      <ExperienceCancellationPolicy
        cancellationPolicy={cancellationPolicy}
        selectedDate={experienceSelection.travelDate}
        selectedTime={experienceSelection.providerExperienceStartTime}
      />
    </Box>
  );
};
