interface TravelAppWebInterfaceAndroid {
  sendTravelEvent: (data: string) => void;
}

interface TravelAppWebInterfaceIOS {
  postMessage: (data: string) => void;
}

declare global {
  interface Window {
    TravelAppWebInterface?: TravelAppWebInterfaceAndroid;
    webkit?: {
      messageHandlers: {
        TravelAppWebInterface?: TravelAppWebInterfaceIOS;
      };
    };
  }
}

const getIsAndroid = () => !!window.TravelAppWebInterface;

const getIsIOS = () => !!window.webkit?.messageHandlers.TravelAppWebInterface;

const getBridgeExists = () => getIsAndroid() || getIsIOS();

const sendEvent = (eventName: string, eventData: any) => {
  const eventToSend = JSON.stringify({
    eventName,
    eventData,
  });

  if (getIsAndroid()) {
    window.TravelAppWebInterface?.sendTravelEvent(eventToSend);
  } else if (getIsIOS()) {
    window.webkit?.messageHandlers.TravelAppWebInterface?.postMessage(
      eventToSend
    );
  }
};

const travelAppBridge = { sendEvent, getBridgeExists };

export { travelAppBridge };
