import { createContext, useContext } from "react";
import {
  CorpSessionInfo,
  CorporateInfo,
  ModalManagerProps,
  PolicyTier,
  RewardsAccount,
  SessionInfo,
  Tenant,
  UserCorpPermissions,
  notSeenModals,
  CorpBusinessWithoutRewardsAccount,
} from "redmond";
import { isCorpTenant } from "../utils/getTenant";

export interface UserInfoContext<T extends SessionInfo | CorpSessionInfo> {
  sessionInfo: T;
  updateSessionInfo: (userInfo: T) => void;
  isBusinessEligible: boolean;
  updateBusinessEligibility: (eligible: boolean) => void;
  policies: PolicyTier | undefined;
  setPolicies: (policies: PolicyTier) => void;
  logo: {
    src: string;
    alt: string;
  };
  isMultiAccount: boolean;
  eligibleBusinessAccounts: RewardsAccount[];
  businesses: CorpBusinessWithoutRewardsAccount[];
  setEligibleBusinessAccounts: (accounts: RewardsAccount[]) => void;
  setBusinesses: (businesses: CorpBusinessWithoutRewardsAccount[]) => void;
  modalManagerProps: ModalManagerProps;
}

type UserContextByTenant<T extends Tenant> = UserInfoContext<
  T extends "capone" ? SessionInfo : CorpSessionInfo
>;

export const userContextDefaultValues = {
  sessionInfo: {
    userInfo: { firstName: "", lastName: "", email: "" },
    isFirstSession: false,
    csrfToken: "",
    sessionExpiration: "",
    isDelegatedSession: "",
    userId: "",
    corporateInfo: {
      permissions: {} as UserCorpPermissions,
      businessIsLive: false,
      role: [] as CorporateInfo["role"],
      policyTier: "",
      businessId: "",
      businessName: "",
      accountReferenceId: "",
      businessLoyaltyPrograms: undefined,
      defaultTravelerId: "",
      hiddenTravelerIds: [],
      hasSeenModalMap: notSeenModals,
      cap1Role: "",
    },
  },
  updateSessionInfo: () => undefined,
  isBusinessEligible: false,
  isMulitAccount: false,
  eligibleBusinessAccounts: [],
  businesses: [],
  setEligibleBusinessAccounts: () => undefined,
  setBusinesses: () => undefined,
  updateBusinessEligibility: () => undefined,
  modalManagerProps: {
    activeModal: null,
    enableModal: () => undefined,
    closeActiveModal: () => undefined,
  },
  policies: undefined,
  setPolicies: () => undefined,
  isMultiAccount: false,
  logo: {
    src: "",
    alt: "",
  },
};

export const UserContext = createContext<UserContextByTenant<"capone">>(
  userContextDefaultValues
);

export const CorpUserContext = createContext<
  UserContextByTenant<"capone-corporate">
>(userContextDefaultValues);

export const useUserContext = <T extends Tenant = "capone">(
  tenant: Tenant = "capone"
): UserContextByTenant<T> => {
  const context = isCorpTenant(tenant)
    ? useContext(CorpUserContext)
    : useContext(UserContext);

  return context as UserContextByTenant<T>;
};
