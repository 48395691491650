import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  fetchExperiences,
  populateTripQueryParams,
  setOpenModal,
  setSelectedExperience,
} from "../../../../actions/actions";
import { ExperienceCard } from "./component";
import { getTripsFilter } from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
});

const mapDispatchToProps = {
  fetchExperiences,
  populateTripQueryParams,
  setOpenModal,
  setSelectedExperience,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperienceCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedExperienceCard = connector(withRouter(ExperienceCard));
