import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { MobileExperienceReservationDetails } from "./component";
import {
  getOpenModal,
  getSelectedExperience,
  getTripsFilter,
} from "../../../../reducer";
import {
  setSelectedExperience,
  setOpenModal,
} from "../../../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  experienceReservation: getSelectedExperience(state),
  tripsFilter: getTripsFilter(state),
  openModal: getOpenModal(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedExperience,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileExperienceReservationDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileExperienceReservationDetails = connector(
  withRouter(MobileExperienceReservationDetails)
);
