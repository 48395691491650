const DEFAULT_TTL = 21600; // 6 hours

export const CACHE_KEY_PREFIX = "local-cache";

export type CacheItem = {
  expiresAt: number;
  value: string;
};

const checkCacheForExpiredItems = () => {
  const keys = Object.keys(localStorage);
  keys
    .filter((key) => key.startsWith(CACHE_KEY_PREFIX))
    .forEach((key) => {
      const localStorageItem = localStorage.getItem(key);
      const parsedItem: CacheItem | undefined = localStorageItem
        ? JSON.parse(localStorageItem)
        : undefined;
      if (parsedItem?.expiresAt && parsedItem.expiresAt < Date.now()) {
        localStorage.removeItem(key);
      }
    });
};

const get = (key: string) => {
  checkCacheForExpiredItems();

  const localStorageItem = localStorage.getItem(`${CACHE_KEY_PREFIX}:${key}`);
  const parsedItem: CacheItem | undefined = localStorageItem
    ? JSON.parse(localStorageItem)
    : undefined;

  if (parsedItem?.value) {
    return parsedItem.value;
  }

  return undefined;
};

const set = (key: string, value: string, ttl: number = DEFAULT_TTL) => {
  checkCacheForExpiredItems();

  const expiresAt = Date.now() + ttl * 1000;
  const cacheItem: CacheItem = {
    expiresAt,
    value,
  };

  localStorage.setItem(`${CACHE_KEY_PREFIX}:${key}`, JSON.stringify(cacheItem));

  return { key, value };
};

export const localCache = { get, set };
