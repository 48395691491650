import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";

import {
  BannerSeverity,
  Icon,
  IconName,
  MyTripsMobileCard,
  NotificationBanner,
} from "halifax";
import {
  MyTripsFilter,
  ExperienceBookingStatusEnum,
  ExperienceReservation,
} from "redmond";

import * as textConstants from "./constants";
import { ExperienceCardConnectorProps } from "./container";
import { DesktopExperienceCard } from "./components";
import "./styles.scss";

const getStatusBanner = (bookingStatus: ExperienceBookingStatusEnum) => {
  switch (bookingStatus) {
    case ExperienceBookingStatusEnum.Cancelled:
      return (
        <NotificationBanner
          className="status-banner"
          label={textConstants.CANCELED_RES}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.ErrorAlert} />}
        />
      );
    default:
      return undefined;
  }
};

export interface IExperienceCardProps
  extends ExperienceCardConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  experienceReservation: ExperienceReservation;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
}

export const ExperienceCard = (props: IExperienceCardProps) => {
  const {
    history,
    experienceReservation,
    isMobile,
    populateTripQueryParams,
    onExpandCard,
    expandedCard,
    setOpenModal,
    tripsFilter,
    setSelectedExperience,
  } = props;

  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const {
    experienceSelection: { travelDate },
    status,
    bookingDetails,
  } = experienceReservation.reservation;

  const statusBanner = getStatusBanner(status);
  const isCancel = status === ExperienceBookingStatusEnum.Cancelled;
  const confirmationCodeClassName = isCancel ? "warning" : "";

  const travelerNames = textConstants.getTravelerNames(bookingDetails);

  return (
    <Box className={clsx({ mobile: isMobile }, "experience-list")}>
      {isMobile ? (
        <MyTripsMobileCard
          onClick={() => {
            setSelectedExperience(experienceReservation);
            populateTripQueryParams(history);
          }}
          banner={statusBanner}
          confirmationCodeClassName={confirmationCodeClassName}
          label={textConstants.EXPERIENCE_HEADER_TEXT}
          icon={IconName.ExperiencesIcon}
          {...textConstants.getExperienceInfoDetails(experienceReservation)}
        />
      ) : (
        <div>
          <Box className="experience-card-icon-title">
            <div className="experience-icon-wrapper">
              <Icon name={IconName.ExperiencesIcon} />
            </div>
            <Typography variant="body1" className="summary-label">
              {textConstants.getHeaderText(travelDate)}
            </Typography>
          </Box>
          <DesktopExperienceCard
            banner={statusBanner}
            isPastTrips={isPastTrips}
            experienceReservation={experienceReservation}
            isCancel={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            isMobile={isMobile}
            expandedCard={expandedCard}
            onExpandCard={onExpandCard}
            setOpenModal={setOpenModal}
            travelerNames={travelerNames}
          />
        </div>
      )}
    </Box>
  );
};
