import { actionTypes } from "../actions";
import { takeLatest, takeEvery, takeLeading } from "redux-saga/effects";
import { fetchCarsSaga } from "./fetchCarsSaga";
import { fetchFlightsSaga } from "./fetchFlightsSaga";
import { fetchHotelsSaga } from "./fetchHotelsSaga";
import { fetchWatchesSaga } from "./fetchWatchesSaga";
import { fetchPriceFreezeSaga } from "./fetchPriceFreezeSaga";
import { updateWatchSaga } from "./updateWatchSaga";
import { deleteWatchSaga } from "./deleteWatchSaga";
import { populateTripQueryParamsSaga } from "./populateTripQueryParamsSaga";
import { acceptScheduleChangeSaga } from "./acceptScheduleChangeSaga";
import { denyScheduleChangeSaga } from "./denyScheduleChangeSaga";
import { fetchFlightCfarCancellationInfoV3Saga } from "./fetchFlightCfarCancellationInfoV3Saga";
import { confirmFlightCfarCancellationSaga } from "./confirmFlightCfarCancellationSaga";
import { fetchUpcomingFlightCrossSellSaga } from "./fetchUpcomingFlightCrossSellSaga";
import { fetchHotelCfarCancellationPolicySaga } from "./fetchHotelCfarCancellationPolicySaga";
import { confirmHotelCfarCancellationSaga } from "./confirmHotelCfarCancellationSaga";
import { fetchHotelPriceFreezeSaga } from "./fetchHotelPriceFreezeSaga";
import { fetchHomesSaga } from "./fetchHomesSaga";
import { listPaymentMethodsSaga } from "./listPaymentMethodsSaga";
import { fetchPendingRequestsSaga } from "./fetchPendingRequestSaga";
import { fetchActiveHotelCrossSellSaga } from "./fetchActiveHotelCrossSellSaga";
import { fetchPackagesSaga } from "./fetchPackagesSaga";
import { fetchExperiencesSaga } from "./fetchExperiencesSaga";

export function* watchFetchCarsSaga() {
  yield takeEvery(actionTypes.FETCH_CARS, fetchCarsSaga);
}

export function* watchFetchFlightsSaga() {
  yield takeEvery(actionTypes.FETCH_FLIGHTS, fetchFlightsSaga);
}

export function* watchFetchHotelsSaga() {
  yield takeEvery(actionTypes.FETCH_HOTELS, fetchHotelsSaga);
}

export function* watchFetchHomesSaga() {
  yield takeEvery(actionTypes.FETCH_HOMES, fetchHomesSaga);
}

export function* watchListWatches() {
  yield takeLatest(actionTypes.LIST_WATCHES, fetchWatchesSaga);
}

export function* watchListPriceFreeze() {
  yield takeLatest(actionTypes.LIST_PRICE_FREEZE, fetchPriceFreezeSaga);
}

export function* watchListHotelPriceFreeze() {
  yield takeLatest(
    actionTypes.LIST_HOTEL_PRICE_FREEZE,
    fetchHotelPriceFreezeSaga
  );
}

export function* watchUpdateWatch() {
  yield takeLatest(actionTypes.UPDATE_WATCH, updateWatchSaga);
}

export function* watchDeleteWatch() {
  yield takeLatest(actionTypes.DELETE_WATCH, deleteWatchSaga);
}

export function* watchPopulateTripQueryParams() {
  yield takeLatest(
    actionTypes.POPULATE_TRIP_QUERY_PARAMS,
    populateTripQueryParamsSaga
  );
}

export function* watchAcceptScheduleChange() {
  yield takeLatest(
    actionTypes.ACCEPT_SCHEDULE_CHANGE,
    acceptScheduleChangeSaga
  );
}

export function* watchDenyScheduleChange() {
  yield takeLatest(actionTypes.DENY_SCHEDULE_CHANGE, denyScheduleChangeSaga);
}

export function* watchFetchFlightCfarCancellationInfoV3Saga() {
  yield takeLatest(
    actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3,
    fetchFlightCfarCancellationInfoV3Saga
  );
}

export function* watchConfirmFlightCfarCancellationSaga() {
  yield takeLatest(
    actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION,
    confirmFlightCfarCancellationSaga
  );
}

export function* watchFetchCrossSellHotelAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_UPCOMING_FLIGHT_CROSS_SELL,
    fetchUpcomingFlightCrossSellSaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_UPCOMING_FLIGHT_CROSS_SELL,
    fetchUpcomingFlightCrossSellSaga
  );
}

export function* watchFetchHotelCfarCancellationPolicySaga() {
  yield takeLatest(
    actionTypes.FETCH_HOTEL_CFAR_CANCELLATION_POLICY,
    fetchHotelCfarCancellationPolicySaga
  );
}

export function* watchConfirmHotelCfarCancellationSaga() {
  yield takeLatest(
    actionTypes.CONFIRM_HOTEL_CFAR_CANCELLATION,
    confirmHotelCfarCancellationSaga
  );
}

export function* watchListPaymentMethodsSaga() {
  yield takeLeading(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}

export function* watchFetchPendingRequestsSaga() {
  yield takeLatest(
    actionTypes.FETCH_PENDING_REQUESTS,
    fetchPendingRequestsSaga
  );
}

export function* watchFetchActiveHotelCrossSell() {
  yield takeLatest(
    actionTypes.FETCH_ACTIVE_HOTEL_CROSS_SELL,
    fetchActiveHotelCrossSellSaga
  );
}

export function* watchFetchPackagesSaga() {
  yield takeEvery(actionTypes.FETCH_PACKAGES, fetchPackagesSaga);
}

export function* watchFetchExperiencesSaga() {
  yield takeEvery(actionTypes.FETCH_EXPERIENCES, fetchExperiencesSaga);
}
