import { PaymentSessionToken, Person } from ".";
import { HomeUserRating } from "../../hotels-module/interfaces";
import {
  AirportRegion,
  Date,
  MediaAsset,
  Prices,
  TrackingProperties,
} from "../../common";
import { TravelWalletOffer } from "./wallet";

export type VacationRentalsAvailabilityRequest =
  | InitialVacationRentalsSearch
  | FollowupVacationRentalsSearch;

export enum VacationRentalsAvailabilityRequestEnum {
  FollowupSearch = "FollowupSearch",
  InitialSearch = "InitialSearch",
}

export enum AvailabilityModeEnum {
  Unspecified = "Unspecified",
  AVAILABLE_ONLY = "AVAILABLE_ONLY",
  UNAVAILABLE_ONLY = "UNAVAILABLE_ONLY",
  AVAILABLE_FIRST = "AVAILABLE_FIRST",
}

export interface InitialVacationRentalsSearch {
  listingSelection: ListingSelection;
  stayDetails: StayDetails;
  searchFilters?: SearchFilters;
  distributionChannels: DistributionChannel[];
  availabilityMode: AvailabilityModeEnum;
  AvailabilityRequest: VacationRentalsAvailabilityRequestEnum.InitialSearch;
}

export interface FollowupVacationRentalsSearch {
  nextPageToken: SearchPageToken;
  stayDetails: StayDetails;
  AvailabilityRequest: VacationRentalsAvailabilityRequestEnum.FollowupSearch;
}

export interface ListingSelection {
  selection: SelectionType;
}

interface BaseSelectionType<Type extends SelectionEnum> {
  Selection: Type;
}

export type SelectionType = CityName | Empty | PlaceId | ListingIds;

export enum SelectionEnum {
  PlaceId = "PlaceId",
  Empty = "Empty",
  CityName = "CityName",
  ListingIds = "ListingIds",
}

export interface PlaceId extends BaseSelectionType<SelectionEnum.PlaceId> {
  placeId: string;
}

export interface CityName extends BaseSelectionType<SelectionEnum.CityName> {
  city: ValidCityEnum;
}

export interface ListingIds
  extends BaseSelectionType<SelectionEnum.ListingIds> {
  listingIds: ListingId[];
}

export enum ValidCityEnum {
  PalmSpringsCalifornia = "PalmSpringsCalifornia",
  BocaRatonFlorida = "BocaRatonFlorida",
  ThirtyAFlorida = "ThirtyAFlorida",
  GonzalesTexas = "GonzalesTexas",
  NashvilleTennessee = "NashvilleTennessee",
  OahuHawaii = "OahuHawaii",
  SantaBarbaraCalifornia = "SantaBarbaraCalifornia",
  LakeHavasuArizona = "LakeHavasuArizona",
  NewportBeachCalifornia = "NewportBeachCalifornia",
  BreckenridgeColorado = "BreckenridgeColorado",
  ParkCityUtah = "ParkCityUtah",
  CentralOregonOregon = "CentralOregonOregon",
  SanDiegoCalifornia = "SanDiegoCalifornia",
  ScottsdaleArizona = "ScottsdaleArizona",
  CoachellaValleyCalifornia = "CoachellaValleyCalifornia",
  MonterreyBayCalifornia = "MonterreyBayCalifornia",
  MalibuCalifornia = "MalibuCaliforni",
  TellurideColorado = "TellurideColorado",
  KeyWestFlorida = "KeyWestFlorida",
  AngelFireNewMexico = "AngelFireNewMexico",
  TemeculaCalifornia = "TemeculaCalifornia",
  MauiHawaii = "MauiHawaii",
  NaplesFlorida = "NaplesFlorida",
  OregonCoastOregon = "OregonCoastOregon",
  LosAngelesCalifornia = "LosAngelesCalifornia",
  BrokenBowOklahoma = "BrokenBowOklahoma",
  BrianHeadUtah = "BrianHeadUtah",
  AnnaMariaIslandFlorida = "AnnaMariaIslandFlorida",
  KohalaCoastHawaii = "KohalaCoastHawaii",
  PasoRoblesCalifornia = "PasoRoblesCalifornia",
  SiestaKeyFlorida = "SiestaKeyFlorida",
  LakeArrowheadCalifornia = "LakeArrowheadCalifornia",
  BarbadosIntl = "BarbadosIntl",
  MiramarFlorida = "MiramarFlorida",
  DestinFlorida = "DestinFlorida",
  SedonaArizona = "SedonaArizona",
  BerkshiresMassachusetts = "BerkshiresMassachusetts",
  JoshuaTreeCalifornia = "JoshuaTreeCalifornia",
  VailColorado = "VailColorado",
  FortLauderdaleFlorida = "FortLauderdaleFlorida",
  CharlestonSouthCarolina = "CharlestonSouthCarolina",
  SonomaCalifornia = "SonomaCalifornia",
  BigBearCalifornia = "BigBearCalifornia",
  LakeTahoeCalifornia = "LakeTahoeCalifornia",
  FredericksburgTexas = "FredericksburgTexas",
  CaboSanLucasIntl = "CaboSanLucasIntl",
  AustinTexas = "AustinTexas",
  AshevilleNorthCarolina = "AshevilleNorthCarolina",
  SmokyMountainsTennessee = "SmokyMountainsTennessee",
  BreackenridgeColorado = "BreackenridgeColorado",
  HiltonHeadSouthCarolina = "HiltonHeadSouthCarolina",
  HudsonValleyNewYork = "HudsonValleyNewYork",
  NorthLakeTahoeCalifornia = "NorthLakeTahoeCalifornia",
}

export interface Empty extends BaseSelectionType<SelectionEnum.Empty> {}

export interface StayDetails {
  dateRange: StayDates;
  guestDetails: GuestDetails;
}

export interface StayDates {
  from: string;
  until: string;
}
export interface GuestDetails {
  adults?: number;
  children?: number;
  infants?: number;
  petsIncluded?: boolean;
}

export interface SearchFilters {
  bedrooms?: number;
  bathrooms?: number;
  beds?: number;
  allAmenities: AmenityFilter[];
  price?: number;
}

export interface AmenityFilter {
  amenityKind: AmenityKindEnum;
}

export interface DistributionChannel {
  channelId: string;
}

export interface SearchPageToken {
  token: string;
}

export interface VacationRentalsAvailabilityResponse {
  listings: ListingSearchResult[];
  nextPageToken?: SearchPageToken;
  trackingProperties?: TrackingProperties;
}

export interface ListingSearchResult {
  listingId: ListingId;
  listing: Listing;
  availability: AvailabilityResult;
  bestOffer?: TravelWalletOffer;
}

export interface AvailabilityResult {
  AvailabilityResult: AvailabilityResultEnum;
  rate?: Rate;
  reasons?: UnavailableReasons[];
}

export enum AvailabilityResultEnum {
  Available = "Available",
  Empty = "Empty",
  Error = "Error",
  Unavailable = "Unavailable",
}

export interface UnavailableReasons {
  debugDetails: string,
  reason: UnavailableReason
}

interface UnavailableReason {
  "Reason": UnavailableReasonEnum
}

export enum UnavailableReasonEnum {
  MinStayViolation = "MinStayViolation",
  MaxStayViolation = "MaxStayViolation",
  OccupancyViolation = "OccupancyViolation",
  PetPolicyViolation = "PetPolicyViolation",
  CheckInNotAllowed = "CheckInNotAllowed",
  CheckOutNotAllowed = "CheckOutNotAllowed",
  BlockedDates = "BlockedDates",
  MinBookingWindowViolation = "MinBookingWindowViolation",
}

export interface ListingId {
  id: string;
}

export interface Rate {
  price: Price;
  cancellationPolicy: CancellationPolicy;
  paymentPolicy: PaymentPolicy
}

export interface Price {
  currency: string;
  total: Prices;
  nightly: Prices;
  rentTotal: Prices;
  fees: Fee[];
  taxes: Tax[];
  discounts: Discount[];
  settlementAmounts: SettlementAmounts;
}

export interface Fee {
  kind: FeeKindEnum;
  amount: Prices;
  name?: string;
  description?: string;
}

export enum FeeKindEnum {
  TravelerServiceFee = "TravelerServiceFee",
  Unspecified = "Unspecified",
  CleaningFee = "CleaningFee",
  HopperServiceFee = "HopperServiceFee",
  PropertyFee = "PropertyFee",
  HotelFees = "HotelFees",
  PetFee = "PetFee",
  ResortFees = "ResortFees",
}

export interface Tax {
  kind: TaxKindEnum;
  amount: Prices;
  name?: string;
  description?: string;
}

export enum TaxKindEnum {
  OccupancyFee = "OccupancyFee",
  SalesTax = "SalesTax",
  Unspecified = "Unspecified",
  TaxRecoveryCharges = "TaxRecoveryCharges",
  Unknown = "Unknown",
  CityTaxes = "CityTaxes",
}
export interface Discount {
  kind: DiscountKindEnum;
  funding: Funding;
}

export enum DiscountKindEnum {
  LongStay = "LongStay",
  LastMinute = "LastMinute",
  FirstBookings = "FirstBookings",
  Monthly = "Monthly",
  Unspecified = "Unspecified",
  Weekly = "Weekly",
  EarlyBird = "EarlyBird",
}

export type Funding = EmptyFunded | HopperFunded | HostFunded | JointFunded;

export enum FundingEnum {
  Empty = "Empty",
  JointFunded = "JointFunded",
  HostFunded = "HostFunded",
  HopperFunded = "HopperFunded",
}

export interface BaseFunding {
  Funding: FundingEnum;
}

export interface EmptyFunded {
  Funding: FundingEnum.Empty;
}

export interface HopperFunded {
  hopperFunded: FundedAmounts;
  Funding: FundingEnum.HopperFunded;
}

export interface HostFunded {
  hostFunded: FundedAmounts;
  Funding: FundingEnum.HostFunded;
}

export interface JointFunded {
  hopperFunded: FundedAmounts;
  hostFunded: FundedAmounts;
  Funding: FundingEnum.JointFunded;
}

export interface FundedAmounts {
  amount: Prices;
  percentage: number;
}

export interface SettlementAmounts {
  providerBasePrice: Prices;
  commission?: Prices;
  markup?: Prices;
  guestServiceFee?: Prices;
  hostServiceFee?: Prices;
  guestServiceFeeCashBack?: Prices;
  commissionCashBack?: Prices;
  hostServiceFeeCashBack?: Prices;
}

export interface CancellationPolicy {
  policy: Policy;
}

export interface BasePolicy {
  primaryText: string;
  secondaryText?: string;
}
export type Policy =
  | FullyRefundable
  | NonRefundable
  | PartiallyRefundable
  | Unknown;

export enum PolicyEnum {
  FullyRefundable = "FullyRefundable",
  NonRefundable = "NonRefundable",
  PartiallyRefundable = "PartiallyRefundable",
  Unknown = "Unknown",
}

export interface BasePolicy {
  primaryText: string;
  secondaryText?: string;
  effectiveRange: EffectiveRange;
}

export interface FullyRefundable extends BasePolicy {
  Policy: PolicyEnum.FullyRefundable;
}

export interface NonRefundable extends BasePolicy {
  Policy: PolicyEnum.NonRefundable;
}

export interface PartiallyRefundable extends BasePolicy {
  Policy: PolicyEnum.PartiallyRefundable;
}

export interface Unknown {
  primaryText: string;
  Policy: PolicyEnum.Unknown;
}

export interface PaymentPolicy {
  schedule: PaymentPolicySchedule;
  clauses: PaymentPolicyClause[];
}

export type PaymentPolicySchedule =
  | FullPaymentDueNow
  | PaymentText
  | UnspecifiedPaymentSchedule;

export enum PaymentPolicyScheduleEnum {
  FullPaymentDueNow = "FullPaymentDueNow",
  PaymentText = "PaymentText",
  UnspecifiedPaymentSchedule = "UnspecifiedPaymentSchedule",
}
export interface FullPaymentDueNow {
  PaymentSchedule: "FullPaymentDueNow";
}

export interface PaymentText {
  PaymentSchedule: "PaymentText";
  text: string;
}

export interface UnspecifiedPaymentSchedule {
  PaymentSchedule: "UnspecifiedPaymentSchedule";
}

export type PaymentPolicyClause = Text | CardOnFile;

export interface Text {
  PaymentClause: "Text";
  description: string;
}

export interface CardOnFile {
  PaymentClause: "CardOnFile";
  description: string;
}

export type EffectiveRange =
  | AvailableEffectiveRange
  | NotAvailableEffectiveRange;

export enum EffectiveRangeEnum {
  Available = "Available",
  NotAvailable = "NotAvailable",
}

export interface AvailableEffectiveRange {
  startTime: string;
  endTime: string;
  EffectiveRange: EffectiveRangeEnum.Available;
}
export interface NotAvailableEffectiveRange {
  EffectiveRange: EffectiveRangeEnum.NotAvailable;
}

export interface MerchantOfRecord {
  kind: MerchantOfRecordKindEnum;
}

export enum MerchantOfRecordKindEnum {
  Unspecified = "Unspecified",
  Hopper = "Hopper",
  Provider = "Provider",
}

export enum ListingCollectionEnum {
  Premier = "Premier",
  Lifestyle = "Lifestyle",
  NoCollection = "NoCollection",
}

export interface Review {
  authorName: string,
  submittedDate: Date,
  comments: string,                        
  overallScore: number
}

export interface Listing {
  id: ListingId;
  content: ListingContent;
  terms: ListingTerms;
  providerData: ListingProviderData;
  hostInfo?: HostInfo;
  privateHostInfo?: PrivateHostInfo;
  listingCollection: ListingCollectionEnum;
  trackingProperties?: TrackingProperties;
  market?: Market;
  reviews: Review[]
}

export interface ListingContent {
  name: string;
  description: string;
  media: MediaAsset[];
  amenities: Amenity[];
  layout: ListingLayout;
  location: ListingLocation;
  registrationId?: string;
  userRating?: HomeUserRating;
  starRating?: number;
}

export interface Amenity {
  kind: AmenityKindEnum;
  displayName: string;
}

export enum AmenityKindEnum {
  Accessibility = "Accessibility",
  AccessibleBathroom = "AccessibleBathroom",
  AccessibleFacilities = "AccessibleFacilities",
  AirConditioning = "AirConditioning",
  AllergyFriendly = "AllergyFriendly",
  BarbecueGrill = "BarbecueGrill",
  BarInBuilding = "BarInBuilding",
  Bathtub = "Bathtub",
  BeachFront = "BeachFront",
  BeachView = "BeachView",
  BedLinens="Bed Linens",
  Bicycle = "Bicycle",
  BoardGames = "BoardGames",
  Cable = "Cable",
  CarbonMonoxideAlarm = "CarbonMonoxideAlarm",
  CeilingFan="CeilingFan",
  CityView = "CityView",
  CleaningProducts = "CleaningProducts",
  Coffee = "Coffee",
  CoffeeMaker = "CoffeeMaker",
  CoffeeTea = "CoffeeTea",
  ConvenientCheckInOut = "ConvenientCheckInOut",
  CookingBasics = "CookingBasics",
  Crib = "Crib",
  DedicatedWorkspace = "DedicatedWorkspace",
  DiningTable = "DiningTable",
  DishesAndSilverware="DishesAndSilverware",
  Dishwasher="Dishwasher",
  DoormanEntry="DoormanEntry",
  Dryer = "Dryer",
  Elevator="Elevator",
  Entertainment = "Entertainment",
  Essentials="Essentials",
  EvCharger="EvCharger",
  ExtraPillowsAndBlankets="ExtraPillowsAndBlankets",
  FireExtinguisher="FireExtinguisher",
  FirePit="FirePit",
  Fireplace="Fireplace",
  FirstAidKit="FirstAidKit",
  FitnessCenter = "FitnessCenter",
  FreeParking = "FreeParking",
  Freezer="Freezer",
  FrontDesk="FrontDesk",
  GamingConsole = "GamingConsole",
  GardenOrBackyard="GardenOrBackyard",
  Gym = "Gym",
  HairDryer = "HairDryer",
  Hangers="Hangers",
  Hdtv = "Hdtv",
  Heating = "Heating",
  HighChair="HighChair",
  HotTub = "HotTub",
  HotWater="HotWater",
  HotWaterKettle="HotWaterKettle",
  Housekeeping = "Housekeeping",
  InternetAccess = "InternetAccess",
  Iron = "Iron",
  Kayak="Kayak",
  KidFriendly = "KidFriendly",
  Kitchen = "Kitchen",
  Kitchenette="Kitchenette",
  Laundry = "Laundry",
  MealAvailable = "MealAvailable",
  Microwave = "Microwave",
  MountainView = "MountainView",
  NatureView = "NatureView",
  OnDemandHousekeeping="OnDemandHousekeeping",
  OutdoorSeating="OutdoorSeating",
  OutdoorSpace = "OutdoorSpace",
  Oven="Oven",
  PaidParking = "PaidParking",
  Parking = "Parking",
  PatioOrBalcony="PatioOrBalcony",
  PetFriendly = "PetFriendly",
  Piano="Piano",
  PingPongTable="PingPongTable",
  Pool = "Pool",
  PoolTable="PoolTable",
  PrivateEntrance="PrivateEntrance",
  PropertyView = " PropertyView",
  Refrigerator="Refrigerator",
  RestaurauntInBuilding="RestaurauntInBuilding",
  Sauna="Sauna",
  SecureEntry = "SecureEntry",
  SecureStorage = "SecureStorage",
  SecuritySystem = "SecuritySystem",
  Shampoo="Shampoo",
  SkiInSkiOut="SkiInSkiOut",
  SmokeAlarm = "SmokeAlarm",
  SmokingAllowed = "SmokingAllowed",
  SoundSystem="SoundSystem",
  Stove="Stove",
  StreetParking="StreetParking",
  Toaster="Toaster",
  Unspecified = "Unspecified",
  WardrobeOrCloset= "WardrobeOrCloset",
  Washer = "Washer",
  Waterfront = "Waterfront",
  WaterView = "WaterView",
  Wifi = "Wifi",
}

export interface ListingLayout {
  bathrooms: Bathroom[];
  bedrooms: Bedroom[];
  numberOfBeds: number;
  numberOfBedrooms: number;
  numberOfBathrooms: number;
}
export interface Bathroom {
  name: string;
  description?: string;
}

export interface Bedroom {
  name: string;
  description?: string;
  bedTypes: BedType[];
}

export interface BedType {
  name: string;
  count: number;
}
export interface ListingLocation {
  obfuscationRequired: boolean;
  exactCoordinates: Coordinates;
  address: ListingAddress;
}

export interface Coordinates {
  lat: number;
  lon: number;
}

export type ListingAddress =
  | ({ ListingAddress: ListingAddressEnum.Empty } & ListingAddressEmpty)
  | ({
      ListingAddress: ListingAddressEnum.ObfuscatedAddress;
    } & ObfuscatedAddress)
  | ({
      ListingAddress: ListingAddressEnum.FullAddress;
    } & FullAddress);

export enum ListingAddressEnum {
  FullAddress = "FullAddress",
  ObfuscatedAddress = "ObfuscatedAddress",
  Empty = "Empty",
}

export interface BaseAddress {
  city?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string;
}

export interface FullAddress extends BaseAddress {
  address1?: string;
  address2?: string;
  ListingAddress: ListingAddressEnum.FullAddress;
}

export interface ObfuscatedAddress extends BaseAddress {
  ListingAddress: ListingAddressEnum.ObfuscatedAddress;
}

export interface ListingAddressEmpty {
  ListingAddress: ListingAddressEnum.Empty;
}

export interface ListingTerms {
  checkInTimes?: TimeRange;
  checkOutTimes?: TimeRange;
  checkinInformation?: string;
  importantInformation?: string;
  petsPolicy?: string;
  maxOccupancy: number;
  petFriendly?: boolean;
}

export interface TimeRange {
  startTime?: string;
  endTime?: string;
}

export interface ListingProviderData {
  listingProviderId: ListingProviderId;
  providerCategory: ProviderCategoryEnum;
}

export interface ListingProviderId {
  provider: string;
  propertyId: string;
  unitId: string;
}

export enum ProviderCategoryEnum {
  Unspecified = "Unspecified",
  OTA = "OTA",
  B2B = "B2B",
  Hopper = "Hopper",
}

export interface HostInfo {
  hostName?: string;
  hostImageUrl?: string;
  hostLanguages: string[];
  hostDescription?: string;
}

export interface PrivateHostInfo {
  id: string;
  name: string;
}

export interface VacationRentalShopRequest {
  listingId: ListingId;
  stayDetails: StayDetails;
  distributionChannels: DistributionChannel[];
}

export interface VacationRentalShopResponse {
  listing: ListingSearchResult;
}

export interface VacationRentalCalendarRequest {
  listingId: ListingId;
  dateRange: StayDates;
}

export interface VacationRentalCalendarResponse {
  calendarDays: CalendarDay[];
}

export interface CalendarDay {
  date: string;
  isAvailable: boolean;
  isAvailableForCheckout?: boolean;
}

export interface CreateHomeProductRequest {
  market?: Market;
  listingProviderId: ListingProviderId;
  listingId: ListingId;
  stayDates: StayDates;
  guests: CreateHomeProductRequestGuests;
  seenPrice: Prices;
  airportRegion?: AirportRegion;
  listingCollection: ListingCollectionEnum;
}

export interface Market {
  id: string;
  name: string;
  partitionDate: string;
}

export interface CreateHomeProductRequestGuests {
  adults: number;
  children: number;
  infants: number;
  petsIncluded: boolean;
}

export interface HomesPriceQuote {
  rate: HomesPriceQuoteRate;
  isHopperMerchantOfRecord: boolean;
  market?: string;
  stayDates: StayDates;
  listing: HomesPriceQuoteListing;
  orderedProductId?: HomesPriceQuoteOrderedProductId;
  airportRegion?: AirportRegion;
}

export interface HomesPriceQuoteListing {
  areaName: string;
  name: string;
  importantInformation?: string;
  rentalPolicyUrl?: string;
  location?: HomesPriceQuoteListingLocation;
  market?: Market;
  checkOutTimes?: TimeRange;
  state?: string;
  listingId: ListingId;
  timezone?: string;
  registrationId?: string;
  maxGuests: number;
  checkInTimes?: TimeRange;
  petFriendly: boolean;
  checkinInformation?: string;
  apartment?: string;
  coverImage: string;
  petsPolicy?: string;
  postalCode?: string;
  starRating?: number;
  countryCode?: string;
  hostId?: ListingHostId;
  address: string;
  about?: string;
  numberOfBeds?: number;
}

export interface ListingHostId {
  value: string;
}

export interface HomesPriceQuoteRate {
  detailedPrice: HomesPriceQuoteRateDetailedPrice;
  cancellationPolicy?: any; // HomesPriceQuoteRateCancellationPolicy;
  paymentPolicy: PaymentPolicy;
  merchantPointOfSale?: string;
  policies: any; // Array<HomesPriceQuoteRatePoliciesInner>;
}

export interface HomesPriceQuoteListingLocation {
  specificity: HomesPriceQuoteListingLocationSpecificity;
  coordinates: Coordinates;
}

export type HomesPriceQuoteListingLocationSpecificity = Approximate | Exact;

interface Approximate extends HomesPriceQuoteListingLocationSpecificityBase {
  Specificity: SpecificityEnum.Approximate;
}

interface Exact extends HomesPriceQuoteListingLocationSpecificityBase {
  Specificity: SpecificityEnum.Exact;
}

interface HomesPriceQuoteListingLocationSpecificityBase {
  Specificity: SpecificityEnum;
}

export enum SpecificityEnum {
  Approximate = "Approximate",
  Exact = "Exact",
}

// TODO - VR: Use what's necessary + dedup from existing models, BE is deduping models so these will change
// export interface HomesPriceQuoteRateCancellationPolicy {
//   policy: HomesPriceQuoteRateCancellationPolicyPolicy;
//   isRefundable?: boolean;
// }
// /**
//  * @type HomesPriceQuoteRateCancellationPolicyPolicy
//  * @export
//  */
// export type HomesPriceQuoteRateCancellationPolicyPolicy =
//   | ({ Policy: "CancellationSchedule" } & CancellationSchedule)
//   | ({ Policy: "Empty" } & Empty)
//   | ({ Policy: "NonRefundable" } & NonRefundable)
//   | ({ Policy: "RefundableInfo" } & RefundableInfo);

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf {
//   Policy: Policy;
// }

// export const Policy = {
//   CancellationSchedule: "CancellationSchedule",
//   Empty: "Empty",
//   NonRefundable: "NonRefundable",
//   RefundableInfo: "RefundableInfo",
// } as const;

// export type Policy = (typeof Policy)[keyof typeof Policy];

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf1 {
//   info: string;
//   Policy: Policy;
// }

// export const Policy = {
//   CancellationSchedule: "CancellationSchedule",
//   Empty: "Empty",
//   NonRefundable: "NonRefundable",
//   RefundableInfo: "RefundableInfo",
// } as const;

// export type Policy = (typeof Policy)[keyof typeof Policy];

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2 {
//   steps: Array<HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInner>;
//   Policy: Policy;
// }

// export const Policy = {
//   CancellationSchedule: "CancellationSchedule",
//   Empty: "Empty",
//   NonRefundable: "NonRefundable",
//   RefundableInfo: "RefundableInfo",
// } as const;

// export type Policy = (typeof Policy)[keyof typeof Policy];

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInner {
//   penalty: HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenalty;
//   start?: string;
//   end?: string;
// }
// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenalty {
//   penalty: HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenalty;
// }
// /**
//  * @type HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenalty
//  * @export
//  */
// export type HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenalty =

//     | ({ Penalty: "Empty" } & Empty)
//     | ({ Penalty: "FlatFee" } & FlatFee)
//     | ({ Penalty: "NumberOfNights" } & NumberOfNights)
//     | ({ Penalty: "PercentageTotal" } & PercentageTotal);

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenaltyOneOf {
//   Penalty: Penalty;
// }

// export const Penalty = {
//   Empty: "Empty",
//   FlatFee: "FlatFee",
//   NumberOfNights: "NumberOfNights",
//   PercentageTotal: "PercentageTotal",
// } as const;

// export type Penalty = (typeof Penalty)[keyof typeof Penalty];

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenaltyOneOf1 {
//   fee: number;
//   Penalty: Penalty;
// }

// export const Penalty = {
//   Empty: "Empty",
//   FlatFee: "FlatFee",
//   NumberOfNights: "NumberOfNights",
//   PercentageTotal: "PercentageTotal",
// } as const;

// export type Penalty = (typeof Penalty)[keyof typeof Penalty];

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenaltyOneOf2 {
//   nights: number;
//   Penalty: Penalty;
// }

// export const Penalty = {
//   Empty: "Empty",
//   FlatFee: "FlatFee",
//   NumberOfNights: "NumberOfNights",
//   PercentageTotal: "PercentageTotal",
// } as const;

// export type Penalty = (typeof Penalty)[keyof typeof Penalty];

// export interface HomesPriceQuoteRateCancellationPolicyPolicyOneOf2StepsInnerPenaltyPenaltyOneOf3 {
//   percentage: number;
//   Penalty: Penalty;
// }

// export const Penalty = {
//   Empty: "Empty",
//   FlatFee: "FlatFee",
//   NumberOfNights: "NumberOfNights",
//   PercentageTotal: "PercentageTotal",
// } as const;

// export type Penalty = (typeof Penalty)[keyof typeof Penalty];
export enum GroupedHomesLineItemEnum {
  VacationRental = "VacationRental",
  Taxes = "Taxes",
  CleaningFees = "CleaningFees",
  HostAndManagementFees = "HostAndManagementFees",
}
export interface GroupedHomesLineItem {
  fees: Fee[];
  total: Prices;
  displayName: string;
  GroupedHomesLineItem: GroupedHomesLineItemEnum;
}
export interface HomesPriceQuoteRateDetailedPrice {
  sellPrice: Prices;
  settlementAmounts: any; //HomesPriceQuoteRateDetailedPriceSettlementAmounts;
  includedDiscounts: any; //Array<HomesPriceQuoteRateDetailedPriceIncludedDiscountsInner>;
  fees: HomesPriceQuoteRateDetailedPriceFeesInner[];
  payNowTotal: Prices;
  groupedItems: GroupedHomesLineItem[];
}
export interface HomesPriceQuoteRateDetailedPriceFeesInner {
  kind: HomesPriceQuoteRateDetailedPriceFeesInnerKind;
  amount: Prices;
  name?: string;
  description?: string;
}

export interface HomesPriceQuoteRateDetailedPriceFeesInnerKind {
  Kind: HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum;
}

export enum HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum {
  CityTax = "CityTax",
  CleaningFee = "CleaningFee",
  Fee = "Fee",
  HopperServiceFee = "HopperServiceFee",
  HotelFee = "HotelFee",
  OccupancyFee = "OccupancyFee",
  PetFee = "PetFee",
  PropertyFee = "PropertyFee",
  RecoveryChargesTax = "RecoveryChargesTax",
  ResortFee = "ResortFee",
  SalesTax = "SalesTax",
  Tax = "Tax",
  TravelerServiceFee = "TravelerServiceFee",
}

// export interface HomesPriceQuoteRateDetailedPriceIncludedDiscountsInner {
//   discountType: DiscountType;
//   hostFunded?: HomesPriceQuoteRateDetailedPriceIncludedDiscountsInnerHostFunded;
//   hopperFunded?: HomesPriceQuoteRateDetailedPriceIncludedDiscountsInnerHostFunded;
// }

// export const DiscountType = {
//   EarlyBird: "EarlyBird",
//   FirstBookings: "FirstBookings",
//   LastMinute: "LastMinute",
//   LongStay: "LongStay",
//   Monthly: "Monthly",
//   Unspecified: "Unspecified",
//   Weekly: "Weekly",
// } as const;

// export type DiscountType = (typeof DiscountType)[keyof typeof DiscountType];

// export interface HomesPriceQuoteRateDetailedPriceIncludedDiscountsInnerHostFunded {
//   amount: number;
//   percentage: number;
// }
// export interface HomesPriceQuoteRateDetailedPriceSettlementAmounts {
//   guestServiceFee?: Prices;
//   providerBasePrice: Prices;
//   commission?: Prices;
//   markup?: Prices;
//   commissionCashBack?: Prices;
//   guestServiceFeeCashBack?: Prices;
//   hostServiceFee?: Prices;
//   hostServiceFeeCashBack?: Prices;
// }
// export interface HomesPriceQuoteRatePaymentPolicy {
//   schedule: HomesPriceQuoteRatePaymentPolicySchedule;
//   clauses: Array<HomesPriceQuoteRatePaymentPolicyClausesInner>;
// }
// /**
//  * @type HomesPriceQuoteRatePaymentPolicyClausesInner
//  * @export
//  */
// export type HomesPriceQuoteRatePaymentPolicyClausesInner =
//   | ({ PaymentClause: "CardOnFile" } & CardOnFile)
//   | ({ PaymentClause: "Text" } & Text);

// export interface HomesPriceQuoteRatePaymentPolicyClausesInnerOneOf {
//   description?: string;
//   PaymentClause: PaymentClause;
// }

// export const PaymentClause = {
//   CardOnFile: "CardOnFile",
//   Text: "Text",
// } as const;

// export type PaymentClause = (typeof PaymentClause)[keyof typeof PaymentClause];

// /**
//  * @type HomesPriceQuoteRatePaymentPolicySchedule
//  * @export
//  */
// export type HomesPriceQuoteRatePaymentPolicySchedule =
//   | ({ PaymentSchedule: "FullPaymentDueNow" } & FullPaymentDueNow)
//   | ({ PaymentSchedule: "PaymentText" } & PaymentText)
//   | ({ PaymentSchedule: "Unspecified" } & Unspecified);

// export interface HomesPriceQuoteRatePaymentPolicyScheduleOneOf {
//   PaymentSchedule: PaymentSchedule;
// }

// export const PaymentSchedule = {
//   FullPaymentDueNow: "FullPaymentDueNow",
//   PaymentText: "PaymentText",
//   Unspecified: "Unspecified",
// } as const;

// export type PaymentSchedule =
//   (typeof PaymentSchedule)[keyof typeof PaymentSchedule];

// export interface HomesPriceQuoteRatePaymentPolicyScheduleOneOf1 {
//   text: string;
//   PaymentSchedule: PaymentSchedule;
// }

// export const PaymentSchedule = {
//   FullPaymentDueNow: "FullPaymentDueNow",
//   PaymentText: "PaymentText",
//   Unspecified: "Unspecified",
// } as const;

// export type PaymentSchedule =
//   (typeof PaymentSchedule)[keyof typeof PaymentSchedule];

// export interface HomesPriceQuoteRatePoliciesInner {
//   title: string;
//   text: string;
// }

export interface CreateHomeProductRequestListingProviderId {
  provider: string;
  propertyId: string;
  unitId: string;
}

export enum ReservationSettlementInfoEnum {
  Direct = "Direct",
  InterpolatedUserCard = "InterpolatedUserCard",
  Vcc = "Vcc",
}

export interface HomesReservationSettlementInfoBase {
  ReservationSettlementInfo: ReservationSettlementInfoEnum;
}

export type HomesReservationSettlementInfo =
  | Direct
  | InterpolatedUserCard
  | Vcc;

interface Direct extends HomesReservationSettlementInfoBase {
  ReservationSettlementInfo: ReservationSettlementInfoEnum.Direct;
}

interface InterpolatedUserCard extends HomesReservationSettlementInfoBase {
  ReservationSettlementInfo: ReservationSettlementInfoEnum.InterpolatedUserCard;
}

interface Vcc extends HomesReservationSettlementInfoBase {
  ReservationSettlementInfo: ReservationSettlementInfoEnum.Vcc;
}

export interface HomesReservationCancellationDetails {
  cancelledAt: string;
  instigator?: HomesReservationCancellationDetailsInstigator;
  reason?: string;
}

export interface HomesReservationCancellationDetailsInstigatorBase {
  Instigator: InstigatorEnum;
}

export enum InstigatorEnum {
  Guest = "Guest",
  Host = "Host",
}

interface Guest extends HomesReservationCancellationDetailsInstigatorBase {
  Instigator: InstigatorEnum.Guest;
}

interface Host extends HomesReservationCancellationDetailsInstigatorBase {
  Instigator: InstigatorEnum.Host;
}

type HomesReservationCancellationDetailsInstigator = Guest | Host;

interface HomesReservationUserPaymentsInnerBase {
  amount?: Prices;
  UserPayment: UserPaymentEnum;
}

enum UserPaymentEnum {
  CarrotCashCredit = "CarrotCashCredit",
  CreditCard = "CreditCard",
  InterpolatedUserCard = "InterpolatedUserCard",
  Other = "Other",
}

interface CarrotCashCredit extends HomesReservationUserPaymentsInnerBase {
  UserPayment: UserPaymentEnum.CarrotCashCredit;
}

interface CreditCard extends HomesReservationUserPaymentsInnerBase {
  UserPayment: UserPaymentEnum.CreditCard;
}
interface Other extends HomesReservationUserPaymentsInnerBase {
  UserPayment: UserPaymentEnum.Other;
}

export type HomesReservationUserPaymentsInner =
  | CarrotCashCredit
  | CreditCard
  | InterpolatedUserCard
  | Other;

export interface HomesReservationMainGuest {
  givenName: string;
  personId: string;
  phoneNumber?: string;
  middleName?: string;
  emailAddress?: string;
  surname: string;
  dateOfBirth: string;
}

export interface HomesReservationTransactionsInner {
  instigator: HomesReservationCancellationDetailsInstigator;
  amount: number;
  id: string;
  createdAt: string;
  currency: string;
  reasonDescription?: string;
}

interface ReservationStatus {
  ReservationStatus: HomesReservationStatus;
}

export enum HomesReservationStatus {
  Cancelled = "Cancelled",
  Confirmed = "Confirmed",
  Unknown = "Unknown",
}

export interface HomesReservation {
  rate: HomesPriceQuoteRate;
  lengthOfStay: number;
  providerBookingReferenceToken?: string;
  cancellationPolicy: CancellationPolicy;
  listingProviderId: ListingProviderId;
  earnAmountUsd?: number;
  guestsSelection: CreateHomeProductRequestGuests;
  orderId?: string;
  stayDates: StayDates;
  listingCollection: ListingCollectionEnum;
  providerAdditionalReservationInfo: { [key: string]: string };
  listing: HomesPriceQuoteListing;
  cartOrderedProductId: string;
  settlementInfo: HomesReservationSettlementInfo;
  cancellationDetails?: HomesReservationCancellationDetails;
  id: ListingHostId;
  providerConfirmationId: string;
  userPayments: HomesReservationUserPaymentsInner[];
  status: ReservationStatus;
  createdAt: string;
  mainGuest: HomesReservationMainGuest;
  transactions: HomesReservationTransactionsInner[];
  primaryGuest: Person;
  trackingProperties?: TrackingProperties;
  hostInfo?: HomesReservationHostInfo;
}

export interface HomesAddMainGuestPostRequest {
  /**
   * An email address that matches given regex pattern. The frontend should validate the emails address since the request will fail if it doesn't match the pattern
   * @type {string}
   * @memberof HomesAddMainGuestPostRequest
   */
  emailAddress: string;
  mainGuest: Person;
  orderedProductId: HomesPriceQuoteOrderedProductId;
  paymentSessionToken: PaymentSessionToken;
}

export interface HomesAddMainGuestPostResponse {
  orderedProductId: HomesPriceQuoteOrderedProductId;
  AddMainGuestResponse: AddMainGuestResponseEnum;
}

export enum AddMainGuestResponseEnum {
  Failure = "Failure",
  Success = "Success",
}

export interface HomesPriceQuoteOrderedProductId {
  productId: string;
}

export enum HomeBookErrorType {
  InvalidQuote = "Invalid Quote",
  NotAvailable = "NotAvailable",
  RateNotAvailable = "RateNotAvailable",
  CreditCardDeclined = "CreditCardDeclined",
  InsufficientFunds = "InsufficientFunds",
  CreditCardExpired = "CreditCardExpired",
  CreditCardLostStolen = "CreditCardLostStolen",
  CreditCardNotSupported = "CreditCardNotSupported",
  InvalidCreditCardNumber = "InvalidCreditCardNumber",
  InvalidCreditCardInformation = "InvalidCreditCardInformation",
  InvalidCreditCardVerificationNumber = "InvalidCreditCardVerificationNumber",
  Fraud = "Fraud",
  InvalidGuestCountry = "InvalidGuestCountry",
  Experiment = "Experiment",
  InvalidFulfillmentInput = "InvalidFulfillmentInput",
  InvalidCreditCardCountryCode = "InvalidCreditCardCountryCode",
  InvalidCreditCardZipCode = "InvalidCreditCardZipCode",
}

export interface HomesReservationHostInfo {
  hostId: ListingHostId;
  contacts: HomesReservationContact[];
  name: string;
}

export interface HomesReservationContact {
  type: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
}
