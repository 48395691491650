import React from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ExperienceReservationDetails, MyTripsModalTypes } from "redmond";
import {
  ActionLink,
  AvailabilityDuration,
  AvailabilityLanguage,
  Icon,
  IconName,
} from "halifax";
import clsx from "clsx";
import styles from "./styles.module.scss";
import {
  DATE_FORMAT,
  END_POINT,
  STARTING_POINT,
  VIEW_ITINERARY,
} from "../../constants";
import { renderAddress } from "../ExperienceMap";

export interface IExperienceCardContentProps {
  reservation: ExperienceReservationDetails;
  onOpenModal: (type: MyTripsModalTypes) => void;
  isMobile?: boolean;
}

export const ExperienceCardContent = ({
  reservation,
  onOpenModal,
  isMobile = false,
}: IExperienceCardContentProps) => {
  const {
    experienceSelection: {
      travelDate,
      providerExperienceStartTime,
      languageGuide,
    },
    experienceDetails,
  } = reservation;
  const { logistics, duration, supplierInfo, itinerary } =
    experienceDetails || {};

  const sameAsStart =
    logistics?.startLocations?.[0]?.address ===
    logistics?.endLocations?.[0]?.address;

  return (
    <Box
      className={clsx(styles["experience-card-content-container"], {
        [styles.mobile]: isMobile,
      })}
    >
      <Box className={styles["date-time-container"]}>
        {dayjs(travelDate).format(DATE_FORMAT)}
        {providerExperienceStartTime && (
          <>
            <span className={styles.divider}>|</span>
            {dayjs(providerExperienceStartTime, "HH:mm").format("h:mm A")}
          </>
        )}
      </Box>
      <Box className={styles["infos-container"]}>
        <AvailabilityDuration duration={duration} />
        <span className={styles.divider}>|</span>
        {languageGuide && (
          <>
            <AvailabilityLanguage languages={[languageGuide]} />
            {!isMobile && <span className={styles.divider}>|</span>}
          </>
        )}
        {!isMobile && (
          <Typography className={styles["experiences-shop-overview-title"]}>
            {supplierInfo?.name}
          </Typography>
        )}
      </Box>
      <Box className={styles["map-section"]}>
        {!!logistics?.startLocations?.length && (
          <Box className={styles["map-starting-point"]}>
            <Typography variant="h6" className={styles["map-titles"]}>
              <Icon name={IconName.B2BMapPin} />
              {STARTING_POINT}
            </Typography>
            {renderAddress(logistics.startLocations[0])}
          </Box>
        )}
        {!!logistics?.endLocations?.length && (
          <Box className={styles["map-end-point"]}>
            <Typography variant="h6" className={styles["map-titles"]}>
              <Icon name={IconName.FlagIcon} />
              {END_POINT}
            </Typography>
            {renderAddress(logistics.endLocations[0], sameAsStart)}
          </Box>
        )}
      </Box>

      {itinerary && (
        <ActionLink
          className={styles["action-link"]}
          onClick={() => onOpenModal(MyTripsModalTypes.ExperienceItinerary)}
          content={VIEW_ITINERARY}
        />
      )}
    </Box>
  );
};
