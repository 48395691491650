import { Box, Typography } from "@material-ui/core";
import clsx, { ClassValue } from "clsx";
import { Icon, IconName, useDeviceTypes } from "halifax";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import * as textConstants from "../../constants";
import { PostBookingOfferBannerConnectorProps } from "./container";
import { postBookingViewTakeoverPropteries } from "redmond";

import "./styles.scss";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";

interface IPostBookingOfferBannerProps
  extends PostBookingOfferBannerConnectorProps,
    RouteComponentProps {
  itineraryId: string;
  offerContainerModifier?: ClassValue;
}

const OFFER_TRIP_ID = "offerTripId";
const OFFER_DEEPLINK = "offerDeeplink";

export const PostBookingOfferBanner = (props: IPostBookingOfferBannerProps) => {
  const {
    itineraryId,
    myTripsPostBookingOffers,
    offerContainerModifier,
    history,
  } = props;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tripId = searchParams.get(OFFER_TRIP_ID);
  const offerDeeplink = searchParams.get(OFFER_DEEPLINK);

  const navigateToOffer = (id: string) => {
    history.push(
      `${textConstants.PATH_DISRUPTION_OFFER}?${textConstants.ITINERARY_ID_QUERY_PARAM}=${id}&referenceScreen=my_trips_homepage`
    );
  };

  useEffect(() => {
    if (!offerDeeplink) return;

    function navigate() {
      searchParams.delete(OFFER_TRIP_ID);
      searchParams.delete(OFFER_DEEPLINK);
      const newSearch = searchParams.toString();
      history.replace(
        `${location.pathname}${newSearch ? `?${newSearch}` : ""}`
      );
      navigateToOffer(itineraryId);
    }

    if (tripId === itineraryId) {
      navigate();
    } else if (myTripsPostBookingOffers.length === 1) {
      navigate();
    }
  }, []);

  const { matchesMobile } = useDeviceTypes();

  const [skipPostBookingOffer, _setSkipPostBookingOffer] = useState(
    sessionStorage.getItem(`skipMyTripsPostBookingOffer_${itineraryId}`) ===
      String(true)
  );
  const setSkipPostBookingOffer = (value: boolean) => {
    _setSkipPostBookingOffer(value);
    sessionStorage.setItem(
      `skipMyTripsPostBookingOffer_${itineraryId}`,
      String(value)
    );
  };

  const availablePostBookingOffer = !skipPostBookingOffer
    ? myTripsPostBookingOffers.find(
        (offer) => offer.itineraryId === itineraryId
      )
    : undefined;

  useEffect(() => {
    if (!availablePostBookingOffer) return;
    trackEvent(postBookingViewTakeoverPropteries("my_trips_homepage", 1, {}));
  }, [availablePostBookingOffer]);

  const viewAndAddClicked = () => {
    if (!availablePostBookingOffer) return;
    navigateToOffer(availablePostBookingOffer.itineraryId);
  };

  if (!availablePostBookingOffer) {
    return <> </>;
  }

  const createBanner = (props: { title: string; body: string }) => {
    return (
      <Box
        onClick={viewAndAddClicked}
        className={clsx(
          "post-booking-offer-container",
          offerContainerModifier,
          {
            mobile: matchesMobile,
          }
        )}
      >
        {availablePostBookingOffer.pricingWithRewards && (
          <>
            <Box className="rewards-merch">
              <Icon className="rewards-icon" name={IconName.CheckCircle} />
              <span>
                {" "}
                {availablePostBookingOffer.pricingWithRewards.headerTitle}
              </span>
            </Box>
            <Box className="filler"></Box>
          </>
        )}
        {!matchesMobile && (
          <Icon
            className="post-booking-offer-icon"
            name={IconName.DisruptionProtectionDoubleLineCircle}
          />
        )}
        <Box className="post-booking-offer-content">
          <Box className="post-booking-offer-content-title-container">
            <Typography
              className="post-booking-offer-title"
              variant="subtitle2"
              dangerouslySetInnerHTML={{
                __html: props.title,
              }}
            />
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setSkipPostBookingOffer(true);
              }}
            >
              <Icon
                className="post-booking-offer-close"
                name={IconName.BlueX}
              />
            </Box>
          </Box>
          <Typography
            className="post-booking-offer-body"
            variant="subtitle2"
            dangerouslySetInnerHTML={{
              __html: props.body,
            }}
          />

          <Box className="post-booking-offer-view-view-container">
            <Typography variant="subtitle2" className="post-booking-offer-view">
              {textConstants.MY_TRIPS_POSTBOOKING_VIEW}
            </Typography>
            <Icon
              className="post-booking-offer-view-icon"
              name={IconName.ArrowCircle}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return matchesMobile
    ? createBanner({
        title: textConstants.MY_TRIPS_POSTBOOKING_TITLE(
          !!availablePostBookingOffer.pricingWithRewards
        ),
        body: textConstants.MY_TRIPS_POSTBOOKING_BODY(
          !!availablePostBookingOffer.pricingWithRewards
        ),
      })
    : createBanner({
        title: textConstants.MY_TRIPS_POSTBOOKING_TITLE(
          !!availablePostBookingOffer.pricingWithRewards
        ),
        body: textConstants.MY_TRIPS_POSTBOOKING_BODY(
          !!availablePostBookingOffer.pricingWithRewards
        ),
      });
};
