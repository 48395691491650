import {
  MultiProvider,
  MyTripsSingleProvider as SingleProvider,
  FlightItinerarySlice,
  Airline,
} from "redmond";

export const CAP_ONE_LOGO_ALT = "Capital One Travel";
export const PRINT = "Print";
export const TITLE = "Itemized package receipt";
export const HOTEL_NAME = "Hotel name";
export const GUEST_NAME = "Guest name";
export const CONFIRMATION_NUMBER = "Confirmation #";
export const INVOICE_DATE = "Invoice date";
export const CHECK_IN = "Check-in";
export const CHECKOUT = "Checkout";
export const TOTAL_COST = "Total Cost";

export const MMDDYYYY_FORMAT = "MM/DD/YYYY";
export const PAYMENT_INFO = "Payment Info";
export const DEFAULT_DOC_ID = "itemized-receipt";
export const COPY1 =
  "*This total reflects your purchase through Capital One Travel. Your vacation rental may provide an itemized receipt for any charges arranged directly with them.";
export const PRINT_ON_DESKTOP =
  "Please visit the desktop version of the Capital One Travel Portal";
export const TRAVEL_CREDIT_APPLIED = "Capital One Travel Credit Applied";
export const TRAVEL_OFFER_APPLIED = "Capital One Travel Offer Applied";
export const TRAVEL_CREDITS_LINE_ITEM_APPLIED = "Travel credits applied";
export const TRAVEL_OFFER_LINE_ITEM_APPLIED = "Travel offer applied";

export const OUTBOUND_AIRLINE = "Outbound airline";
export const RETURN_AIRLINE = "Return airline";
export const TRAVELERS = "Travelers";
export const TOTAL_PER_TRAVELER = "Total cost per traveler";
export const PER_TRAVELER = "Hotel, flight, taxes and fees per traveler";
export const getPackageTotal = (numTravelers: number) =>
  `Package total cost (${numTravelers} traveler${
    numTravelers > 1 ? "s" : ""
  })*`;

export const DATE_FORMAT = "ddd, MMM D";
export const PACKAGE_SAVINGS = "Package savings";
export const ANCILLARIES = "Ancillaries";
export const ADDITIONAL_FEES = "Additional fees at check-in";

export const getFlightItineraryDetails = (
  locators: MultiProvider | SingleProvider | undefined,
  departureSlice: FlightItinerarySlice,
  returnSlice: FlightItinerarySlice | undefined,
  airlines: { [key: string]: Airline }
) => {
  const firstLegDeparture = departureSlice?.segments[0];
  const firstLegReturn = returnSlice ? returnSlice.segments[0] : null;

  const outboundAirlineCode = firstLegDeparture?.marketingAirline.code ?? "";
  const returnAirlineCode = firstLegReturn?.marketingAirline.code ?? "";
  return {
    confirmationCode: `H-${locators?.agent.unscopedValue}` ?? "",
    outboundAirlineName:
      airlines[firstLegDeparture?.marketingAirline.code ?? ""]?.displayName ||
      outboundAirlineCode,
    returnAirlineName:
      airlines[firstLegReturn?.marketingAirline.code ?? ""]?.displayName ||
      returnAirlineCode,
  };
};

export const SEAT_SELECTION = "Seat selection total";
