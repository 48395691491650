import axios from "axios";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  airBookingApi,
  type SingleFlightItineraryResponse,
} from "@b2bportal/air-booking-api";
import { airChfarApi } from "@b2bportal/air-chfar-api";

class ChfarExerciseError extends Error {
  expiryDate?: string;

  constructor(message: string, expiryDate?: string) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ChfarExerciseError);
    }

    this.name = "ChfarExerciseError";
    this.expiryDate = expiryDate;
  }
}

export const chfarItinerariesApi = createApi({
  reducerPath: "chfarItinerariesApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["ChfarItineraries", "Itineraries"],
  endpoints: (builder) => ({
    getChfarItineraryById: builder.query<
      SingleFlightItineraryResponse,
      { itineraryId: string; contractId: string }
    >({
      queryFn: async ({ itineraryId, contractId }) => {
        const singleItineraryRequest = {
          itineraryId,
          referenceDateTime: new Date().toISOString(),
        };
        try {
          const res = await airChfarApi(axios).apiV1ChfarAirExerciseQuotePost({
            itineraryId,
            contractId,
            singleItineraryRequest,
          });

          if (res.data.AirChfarExerciseQuoteResponse === "NotAvailable") {
            const expiryDate =
              res.data.NotAvailable === "ContractExpired"
                ? res.data.expiredAt
                : undefined;
            throw new ChfarExerciseError(res.data.NotAvailable, expiryDate);
          }

          const data: SingleFlightItineraryResponse = {
            itinerary: res.data.itineraryResponse.itinerary.value.itinerary,
            context: res.data.itineraryResponse.context,
          };

          return { data };
        } catch (error) {
          if (error instanceof ChfarExerciseError) {
            return {
              error: {
                message: error.message,
                data: error.expiryDate,
              },
            };
          }
          if (axios.isAxiosError(error)) {
            return {
              error: {
                status: error.response?.status,
                message: error.message,
              },
            };
          }

          return {
            error: { message: (error as Error).message ?? "Unknown error" },
          };
        }
      },
      providesTags: ["ChfarItineraries"],
    }),
    getItineraryById: builder.query<SingleFlightItineraryResponse, string>({
      queryFn: async (itineraryId) => {
        const request = {
          itineraryId,
          referenceDateTime: new Date().toISOString(),
        };
        try {
          const res = await airBookingApi(axios).apiV0ItineraryFlightSinglePut(
            request
          );

          return { data: res.data };
        } catch (error) {
          if (axios.isAxiosError(error)) {
            return {
              error: {
                status: error.response?.status,
                message: error.message,
              },
            };
          }

          return {
            error: { message: (error as Error).message ?? "Unknown error" },
          };
        }
      },
      providesTags: ["Itineraries"],
    }),
  }),
});

export const { useGetChfarItineraryByIdQuery, useGetItineraryByIdQuery } =
  chfarItinerariesApi;

export const chfarItinerariesApiReducerPath = chfarItinerariesApi.reducerPath;
export const chfarItinerariesApiReducer = chfarItinerariesApi.reducer;
export const chfarItinerariesApiMiddleware = chfarItinerariesApi.middleware;

export type ChfarItinerariesApiState = ReturnType<
  typeof chfarItinerariesApi.reducer
>;
