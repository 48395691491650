import { Box, Typography } from "@material-ui/core";
import { ActionLink } from "halifax";
import React from "react";
import { ExperiencesCancellationPolicy, MyTripsModalTypes } from "redmond";
import { CANCELLATION_POLICY, VIEW_POLICY_DETAILS } from "../../constants";
import styles from "./styles.module.scss";

export interface IExperienceCancellationPolicyCardProps {
  cancellationPolicy: ExperiencesCancellationPolicy;
  onOpenModal: (type: MyTripsModalTypes) => void;
}

export const ExperienceCancellationPolicyCard = (
  props: IExperienceCancellationPolicyCardProps
) => {
  const { cancellationPolicy, onOpenModal } = props;

  return (
    <Box className={styles["experience-cancellation-policy-container"]}>
      <Typography>
        <strong>{CANCELLATION_POLICY}</strong>
      </Typography>
      <Typography variant="body2">
        {cancellationPolicy?.cancellationPolicy}
      </Typography>
      <ActionLink
        className={styles["action-link"]}
        onClick={() =>
          onOpenModal(MyTripsModalTypes.ExperienceCancellationPolicy)
        }
        content={VIEW_POLICY_DETAILS}
      />
    </Box>
  );
};
