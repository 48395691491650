import React from "react";

export const FLIGHT_DISRUPTION_INFO = {
  title: (
    <>
      No eligible flights:
      <br /> Book a new flight to add flight disruption assistance
    </>
  ),
  conditionsTitle: "If you experience:",
  conditions: [
    <>
      <b>Cancellation</b> on the day of travel
    </>,
    <>
      <b>Delay</b> of 2 or 3 hours (depending on the flight)
    </>,
    <b>Missed connection</b>,
  ],
  actionsTitle: "You can:",
  actions: [
    <>
      <b>Rebook</b> on any airline for no additional cost through Capital One
      Travel for flights up to $5,000, or
    </>,
    <>
      <b>Get a refund</b> of your base fare and taxes and{" "}
      <b>keep your original flight</b> if you are not happy with the rebooking
      options
    </>,
  ],
  note: "You can add this option to future flight bookings at checkout—find your next flight now.",
  termsLinkText: "Terms and conditions",
  searchButtonText: "Search flights",
};
