import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import React from "react";
import { DP_SUPPORT_PHONE_NUMBER } from "redmond";
import "./styles.scss";

export const FlightDisruptionAssistanceErrorModalContent = () => (
  <Box className="flight-disruption-assistance-error-modal-content-root">
    <Box className="flight-disruption-assistance-error-modal-content-header">
      <Icon name={IconName.FoundationHelp} />
      <Typography variant="h2">Please contact us for help.</Typography>
      <Typography
        variant="h4"
        className="flight-disruption-assistance-error-modal-content-subheader"
      >
        Please contact support at{" "}
        <span style={{ fontWeight: "bold" }}>
          {DP_SUPPORT_PHONE_NUMBER.text}
        </span>{" "}
        for further assistance with your booking.
      </Typography>
    </Box>
  </Box>
);
