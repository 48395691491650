import React from "react";
import { Tenant, cardsToShowLastFourOnRewardsBanner } from "redmond";
import { isCorpTenant } from "../utils/getTenant";

export const DO_NOT_APPLY_REWARDS_KEY = "do-not-apply-rewards";

export function REWARDS_BANNER_TEXT({
  firstName,
  earnRate,
  flightEarnRate,
  carEarnRate,
  productId,
  lastFour,
  currency,
  isPrimary,
  tenant,
}: {
  firstName: string;
  earnRate: string | number;
  carEarnRate?: string | number;
  flightEarnRate: string | number;
  productId: string;
  lastFour: string;
  currency: string;
  isPrimary: boolean;
  tenant: Tenant;
}) {
  const isCorp = isCorpTenant(tenant);
  const multiplier = currency.includes("cash") ? "%" : "X";
  const showVXBCopy = productId === VXB && isCorp;

  let earnString = "";

  if (showVXBCopy) {
    return (
      <React.Fragment>
        <b>Rewards earn is different across sites:</b> You earn 2X miles on
        flights and 5X miles on hotels and cars booked through Capital One
        Business Travel.
      </React.Fragment>
    );
  }

  if (isCorp) {
    if (!isPrimary) {
      return `Hi ${firstName}, travel smarter and get 24/7 traveler support with Capital One Business Travel.`;
    }
    earnString =
      flightEarnRate && carEarnRate && flightEarnRate === carEarnRate
        ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and rental cars when you or employees book with your ${productId} account`
        : flightEarnRate
        ? `Hi ${firstName}, earn ${flightEarnRate}${multiplier} ${currency} on flights and ${earnRate}${multiplier} ${currency} on hotels and rental cars when you or employees book with your ${productId} account`
        : `Hi ${firstName}, earn ${earnRate}${multiplier} ${currency} on hotels and rental cars when you or employees book with your ${productId} account`;
  } else {
    if (productId.toLowerCase().includes("paradise")) {
      return `Capital One Travel helps cardholders book confidently, with smarter tools and thousands of trip options.`;
    }
    earnString =
      flightEarnRate && carEarnRate && flightEarnRate === carEarnRate
        ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and rental cars when you book with your ${productId} account`
        : flightEarnRate
        ? `Hi ${firstName}, earn ${flightEarnRate}${multiplier} ${currency} on flights and ${earnRate}${multiplier} ${currency} on hotels and rental cars when you book with your ${productId} account`
        : `Hi ${firstName}, earn ${earnRate}${multiplier} ${currency} on hotels and rental cars when you book with your ${productId} account`;
  }

  earnString += cardsToShowLastFourOnRewardsBanner.includes(
    productId.toLowerCase()
  )
    ? ` ending in ${lastFour}.`
    : `.`;
  return earnString;
}

// capone-corporate travel users clarity redirect param
export const VIEW_PARAM = "view";
export const USERS_CLARITY_PARAM_VALUE_ADD = "add_traveler";
export const USERS_CLARITY_PARAM_VALUE_LIST = "list_traveler";

// HOME PAGE
export const CORP_HOMEPAGE_TITLE = "Welcome to Capital One Business Travel";
export const CORP_HOMEPAGE_SUBTITLE = "The smarter way to book business travel";

export const CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT = "Welcome, DBC America";

export const OUT_OF_POLICY_REWARDS_BANNER =
  "Rewards can’t be applied to out-of-policy booking requests.";

export const AMADEUS = "Amadeus";

export const VXB = "Venture X Business";

export enum ReferrerSource {
  BookingPortal = "booking_portal",
  AdminPortal = "admin_portal",
  VxbAcknowledgement = "vxb_acknowledgement",
  Onboarding = "onboarding",
}

export const REFERRER_SOURCE = "referrer_source";

export const getReferrerSource = (source: ReferrerSource) =>
  `${new URLSearchParams({ [REFERRER_SOURCE]: source }).toString()}`;

export const BOOKING_PORTAL_REFERRER_SOURCE = getReferrerSource(
  ReferrerSource.BookingPortal
);

export const ONBOARDING_REFERRER_SOURCE = getReferrerSource(
  ReferrerSource.Onboarding
);

// Local and Session storage keys
export const HAS_CHOSEN_ACCOUNT = "cotb_has_chosen_account";

// Roles
export const CORPORATE_ROLE_ADMIN = "Admin";
