import {
  BookedFlightItineraryWithDepartureTime,
  CarReservation,
  FlightCancelInfoResponse,
  FlightProvider,
  NonCfarEnum,
} from "redmond";

import { TF_PREFIX } from "../pages/TripsList/components/ItineraryList/components/ItinerariesModal/components/SelfServeCancelFlightModalContent/constants";
import { getFirstFlightCarrier, getFlightPNR } from "./helpers";

export const eventsToListen = {
  HOST_THEME_CHANGED: "HOST_THEME_CHANGED",
  HOST_LOCALE_CHANGED: "HOST_LOCALE_CHANGED",
  HOST_ROUTE_CHANGED: "ROUTE_CHANGED",
};

export interface RouteChangeEventData {
  referrer: string;
  destination: string;
}

export const eventsToDispatch = {
  ROUTE_CHANGED: "ROUTE_CHANGED",
};

/**
 * @description Maps self serve NonCfars to more intuitive strings for event
 * tracking in amplitude.
 * @param {NonCfarEnum} nonCfar
 * @return {string}
 */
export const getTrackingNonCfar = (nonCfar: NonCfarEnum): string => {
  switch (nonCfar) {
    case NonCfarEnum.AirlineRefund:
      return "Fully Refundable";
    case NonCfarEnum.ContactAirline:
      return "TRVK";
    case NonCfarEnum.ContactCustomerService:
      return "Unsupported Eligibility";
    case NonCfarEnum.FTC:
      return "FTC";
    case NonCfarEnum.FTCWithPenalty:
      return "FTC with Penalty";
    case NonCfarEnum.NonRefundable:
      return "Non Refundable";
    case NonCfarEnum.PartialRefund:
      return "Partially Refundable";
    case NonCfarEnum.RefundableComplex:
      return "Refundable Complex";
    case NonCfarEnum.TicketedVoid:
      return "Void-Ticketed";
    case NonCfarEnum.TicketedVoidUnknownWindow:
      return "Void-TicketedUnknownWindow";
    case NonCfarEnum.DelayedTicketingCancel:
      return "Void-DelayedTicketingCancel";
    case NonCfarEnum.TicketlessVoid:
      return "Void-Ticketless";
    default:
      return nonCfar;
  }
};

export const getTrackingCarProvider = (carRes: CarReservation) => {
  const {
    bookResult: { supplier },
  } = carRes;

  return supplier?.name ?? supplier?.code ?? "";
};

export const getTrackingFlightProvider = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const pnr = getFlightPNR(flight);

  return pnr?.startsWith(TF_PREFIX)
    ? FlightProvider.travelFusion
    : FlightProvider.sabre;
};

export const getSelfServeAirCancelProperties = (
  cancelPolicy: FlightCancelInfoResponse,
  flight: BookedFlightItineraryWithDepartureTime,
  isPackageFlight?: boolean
) => {
  const { CancelScenario, NonCfar, firstPolicy, penaltyAmount, secondPolicy } =
    cancelPolicy;
  let nonCfarProperties;

  if (NonCfar === NonCfarEnum.MultiTicket) {
    const firstNonCfar = getTrackingNonCfar(firstPolicy!);
    const secondNonCfar = getTrackingNonCfar(secondPolicy!);

    nonCfarProperties = {
      is_multi_ticket: true,
      non_cfar: firstNonCfar,
      non_cfar2:
        firstNonCfar === secondNonCfar ? "same_outcome" : secondNonCfar,
    };
  } else {
    nonCfarProperties = {
      is_multi_ticket: false,
      non_cfar: getTrackingNonCfar(NonCfar),
    };
  }

  return {
    ...nonCfarProperties,
    cancel_scenario: CancelScenario,
    carrier: getFirstFlightCarrier(flight),
    penalty_amount: penaltyAmount?.amount,
    penalty_currency: penaltyAmount?.currency,
    product_funnel: isPackageFlight ? "package_flight" : "flight",
    provider_name: getTrackingFlightProvider(flight),
    lob: isPackageFlight ? "package" : "air",
  };
};

const dispatchEvent = (eventName: string, data: any) =>
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));

export default dispatchEvent;
