import axios from "axios";
import { getConfigTenant } from "@capone/common";

export const BASE_URL = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_TYSONS_URL
  : "";

export const CORPORATE_PORTAL_URL = window.__mclean_env__.CORPORATE_PORTAL_URL;

export const recentlyViewedAxiosCancelToken = axios.CancelToken.source();

export const config = {
  apiKey: "test",
  baseURL: BASE_URL!,
  corpPortalBaseUrl: CORPORATE_PORTAL_URL,
  TENANT: getConfigTenant(),
};
